import React, { useState, useEffect } from "react";
import { Box, Typography, Modal, Fade, Grid, Button } from "@mui/material";
import { CloseRounded as CloseRoundedIcon } from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import SelectionDropdown from "../dropdowns/Selection";
import TextAreaInput from "../inputs/TextArea";
import ConfirmPasswordForm from "../forms/ConfirmPassword";
import {
  CancelReason as CancelReasonModel,
  CancelReasonForm as CancelReasonFormModel,
  CancelReasonFormIsInvalid as CancelReasonFormIsInvalidModel,
} from "../../models/MasterData";
import { GetCancelReasons as GetCancelReasonService } from "../../services/MasterData";
import { CheckPinCode as CheckPinCodeService } from "../../services/User";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: CancelReasonFormModel) => void;
}

const CancelBill = (props: Props) => {
  const initForm: CancelReasonFormModel = {
    reason: {
      id: "",
      reason: "",
    },
    misc: "",
  };
  const initFormIsInvalid: CancelReasonFormIsInvalidModel = {
    reason: false,
    misc: false,
  };
  const [cancelReasons, setCancelReasons] = useState<CancelReasonModel[]>([]);
  const [form, setForm] = useState<CancelReasonFormModel>({ ...initForm });
  const [formIsInvalid, setFormIsInvalid] =
    useState<CancelReasonFormIsInvalidModel>({
      ...initFormIsInvalid,
    });
  const [isConfirmedCancelReason, setIsConfirmedCancelReason] =
    useState<boolean>(false);
  const [isShowPinCodeErrorMessage, setIsShowPinCodeErrorMessage] =
    useState<boolean>(false);

  useEffect(() => {
    props.isOpen && getCancelReasons();
    return () => {
      setForm({ ...initForm });
      setFormIsInvalid({ ...initFormIsInvalid });
      setIsConfirmedCancelReason(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const getCancelReasons = async () => {
    const response = await GetCancelReasonService();
    response.success && setCancelReasons([...response.data]);
  };

  const onInputChange = (key: string, value: any) => {
    const formObj: any = { ...form };
    const formIsInvalidObj: any = { ...formIsInvalid };
    formObj[key] = value;
    if (key === "reason") formObj.misc = "";
    Object.keys(formIsInvalidObj).forEach((key) => {
      formIsInvalidObj[key] = false;
    });
    setForm({ ...formObj });
    setFormIsInvalid({ ...formIsInvalidObj });
  };

  const onValidateForm = () => {
    const formIsInvalidObj: any = { ...formIsInvalid };
    formIsInvalidObj.reason = Boolean(!form.reason.id);
    formIsInvalidObj.misc = Boolean(
      form.reason.reason === "อื่นๆ" && !form.misc
    );
    setFormIsInvalid({ ...formIsInvalidObj });
    return Object.values(formIsInvalidObj).every((v) => v === false);
  };

  const onSubmit = () => {
    const isValid = onValidateForm();
    isValid && setIsConfirmedCancelReason(true);
  };

  const onSubmitConfirmPasswordForm = async (pinCode: string) => {
    setIsShowPinCodeErrorMessage(false);
    const response = await CheckPinCodeService({ pinCode });
    response.data?.isMatch
      ? props.onSubmit({ ...form })
      : setIsShowPinCodeErrorMessage(true);
  };

  const onClose = () => {
    setForm({ ...initForm });
    setFormIsInvalid({ ...initFormIsInvalid });
    setCancelReasons([]);
    props.onClose();
  };

  return (
    <Modal
      className="modal modal-md"
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={props.isOpen}>
        <Box className="modal-body">
          {!isConfirmedCancelReason ? (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottom={`1px solid ${StyleVariables["color-border"]}`}
                paddingBottom={2}
                onClick={props.onClose}
              >
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  color={StyleVariables["color-header"]}
                >
                  เหตุผลการยกเลิกรายการ
                </Typography>
                <CloseRoundedIcon
                  sx={{
                    color: StyleVariables["color-main-icon"],
                    fontSize: 32,
                    cursor: "pointer",
                  }}
                />
              </Box>
              <Box marginY={5}>
                <Box position={"relative"}>
                  <SelectionDropdown
                    label="เลือกเหตุผลการยกเลิก"
                    placeholder="เลือกเหตุผลการยกเลิก"
                    options={cancelReasons}
                    labelKey="reason"
                    valueKey="id"
                    selected={form?.reason}
                    isError={formIsInvalid.reason}
                    onSelected={(selected: CancelReasonModel) => {
                      onInputChange("reason", selected);
                    }}
                  />
                  <Typography
                    variant="body1"
                    fontWeight={"bold"}
                    color={StyleVariables["color-primary"]}
                    position={"absolute"}
                    top={0}
                    left={240}
                  >
                    *
                  </Typography>
                </Box>
                {form?.reason.reason === "อื่นๆ" && (
                  <Box marginTop={5} position={"relative"}>
                    <TextAreaInput
                      name="cancelReasonMisc"
                      placeholder="กรอกเหตุผล"
                      label="ระบุเหตุผล"
                      value={form.misc}
                      isError={formIsInvalid.misc}
                      handleOnChange={(value: string) => {
                        onInputChange("misc", value);
                      }}
                    />
                    <Typography
                      variant="body1"
                      fontWeight={"bold"}
                      color={StyleVariables["color-primary"]}
                      position={"absolute"}
                      top={0}
                      left={115}
                    >
                      *
                    </Typography>
                  </Box>
                )}
              </Box>
              <Grid
                container
                spacing={2}
                flexDirection={{ xs: "column-reverse", md: "row" }}
              >
                <Grid item xs={12} md={6}>
                  <Button variant="outlined" fullWidth onClick={onClose}>
                    ยกเลิก
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button variant="contained" fullWidth onClick={onSubmit}>
                    ยืนยันการยกเลิก
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottom={`1px solid ${StyleVariables["color-border"]}`}
                paddingBottom={2}
                onClick={props.onClose}
              >
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  color={StyleVariables["color-header"]}
                >
                  ยืนยันรหัสผ่านเพื่อยกเลิกรายการ
                </Typography>
                <CloseRoundedIcon
                  sx={{
                    color: StyleVariables["color-main-icon"],
                    fontSize: 32,
                    cursor: "pointer",
                  }}
                />
              </Box>
              <ConfirmPasswordForm
                isShowErrorMessage={isShowPinCodeErrorMessage}
                onSubmit={(password: string) =>
                  onSubmitConfirmPasswordForm(password)
                }
              />
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default CancelBill;
