import StyleVariables from "../assets/styles/Variable.module.scss";

const Drawer: any = {
  MuiDrawer: {
    styleOverrides: {
      paper: {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        paddingTop: Number(StyleVariables.spacing) * 5,
        paddingBottom: Number(StyleVariables.spacing) * 5,
        paddingLeft: Number(StyleVariables.spacing) * 2,
        paddingRight: Number(StyleVariables.spacing) * 2,
      },
    },
  },
};

export default Drawer;
