import { observable, makeAutoObservable, action } from "mobx";
import {
  Trade as TradeModel,
  TradeHasItem as TradeHasItemModel,
  TradeIsInvalid as TradeIsInvalidModel,
  TradeHasItemIsInvalid as TradeHasItemIsInvalidModel,
} from "../models/Trade";
import { Product as ProductModel } from "../models/Product";
import {
  CalculateGoldPriceStandard as CalculateGoldPriceStandardUtil,
  CalculateSellPriceStandard as CalculateSellPriceStandardUtil,
} from "../utils/Gold";
import { KeysType as KeysTypeConstant } from "../constants/Payment";
import { RangeOfStatusForCalculate as RangeOfStatusForCalculateSellConstant } from "../constants/Sell";
import {
  RangeOfStatusForCalculateTypeMore80 as RangeOfStatusForCalculateTypeMore80Constant,
  RangeOfStatusForCalculateTypeLess80 as RangeOfStatusForCalculateTypeLess80Constant,
} from "../constants/Purchase";
import {
  ProductWeightType as ProductWeightTypeConstant,
  RefInvoice as RefInvoiceConstant,
} from "../constants/Gold";
import {
  CalculatePurchasePriceStandard as CalculatePurchasePriceStandardUtil,
  CalculateSellNegotiation as CalculateSellNegotiationUtil,
} from "../utils/Gold";
import { PriceRoundDown as PriceRoundDownUtil } from "../utils/NumberRounding";

export default class TradeStore {
  private initForm: TradeModel = {
    productType: "",
    categoryCode: "",
    categoryName: "",
    productCode: "",
    productName: "",
    productPercentage: 0,
    goldSellPrice: 0,
    goldPurchasePrice: 0,
    goldPriceDateTime: "",
    totalQuantity: 1,
    totalWeight: 0,
    totalGoldPrice: 0,
    totalLabourStandard: 0,
    totalLabour: 0,
    totalPay: 0,
    paymentType: KeysTypeConstant.allCash,
    netPay: 0,
    totalPriceNegotiation: 0,
    tradeHasItems: [],
    currentStatus: "good",
    standardExcellent: 0,
    standardGood: 0,
    standardFair: 0,
    standardPoor: 0, // price maximum can used
  };
  private initFormIsInvalid: TradeIsInvalidModel = {
    categoryCode: false,
    productCode: false,
    totalPay: false,
    cardPay: false,
  };
  private defaultFormTradeHasItemIsInvalid: TradeHasItemIsInvalidModel = {
    weight: false,
    price: false,
  };
  @observable form: TradeModel = this.initForm;
  @observable formIsInvalid: TradeIsInvalidModel = this.initFormIsInvalid;
  @observable formHasItemIsInvalid: TradeHasItemIsInvalidModel[] = [];
  @observable isShowAll: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  onSetIsShowAll = (isShowAll: boolean) => {
    this.isShowAll = isShowAll;
  };

  @action
  onClearStore = () => {
    this.form = { ...this.initForm };
    this.formIsInvalid = { ...this.initFormIsInvalid };
    this.formHasItemIsInvalid = [];
  };

  @action
  onClearFormIsInvalid = () => {
    this.formIsInvalid = { ...this.initFormIsInvalid };
  };

  @action
  onSetForm = (form: TradeModel) => {
    this.form = { ...form };
  };

  @action
  onSetFormIsInvalid = (formIsInvalid: TradeIsInvalidModel) => {
    this.formIsInvalid = { ...formIsInvalid };
  };

  @action
  onSetTradeByProductEqualWeight = (
    product: ProductModel,
    goldSellNow: number,
    goldPurchaseNow: number
  ) => {
    const form: TradeModel = { ...this.form };
    this.formHasItemIsInvalid = [];
    form.tradeHasItems = [];
    this.onAddTradeItem();
    form.product = product;
    form.productCode = product.code;
    form.productName = product.name;
    form.productPercentage = product.percentageGold;
    form.productType = ProductWeightTypeConstant.equalWeight;
    form.totalQuantity = 1;
    const formEqualWeight =
      form.ref === RefInvoiceConstant.sell
        ? this.handleSetTradeByProductEqualWeightSell(
            product,
            goldSellNow,
            form
          )
        : this.handleSetTradeByProductEqualWeightPurchase(
            product,
            goldPurchaseNow,
            form
          );
    this.onSetForm({ ...form, ...formEqualWeight });
  };

  handleSetTradeByProductEqualWeightSell = (
    product: ProductModel,
    goldSellNow: number,
    form: TradeModel
  ) => {
    const goldPriceStandard = CalculateGoldPriceStandardUtil(
      goldSellNow,
      product.percentageGold,
      form.categoryCode === "11",
      1,
      Number(product.weight)
    );
    const sellPriceStandard = CalculateSellPriceStandardUtil(
      goldPriceStandard,
      Number(product.labour),
      1
    );
    const priceNegotiation = CalculateSellNegotiationUtil(
      Number(product.labour)
    );
    const labourAfterIncreasePriceNegotiation =
      Number(product.labour) + priceNegotiation;
    form.tradeHasItems[0] = {
      quantity: form.totalQuantity,
      weight: Number(product.weight),
      labourStandard: Number(product.labour),
      labour: labourAfterIncreasePriceNegotiation,
      type: "NEW",
      goldPrice: sellPriceStandard - product.labour,
      priceStandard: sellPriceStandard,
      price: sellPriceStandard + priceNegotiation,
      priceNegotiation: priceNegotiation,
    };
    form.totalPriceNegotiation = priceNegotiation * form.totalQuantity;
    form.totalWeight = Number(product.weight) * form.totalQuantity;
    form.totalGoldPrice =
      sellPriceStandard - product.labour * form.totalQuantity;
    form.totalLabourStandard = Number(product.labour) * form.totalQuantity;
    form.totalLabour =
      form.tradeHasItems?.reduce((n, { labour }) => n + labour, 0) ||
      form.totalLabourStandard + labourAfterIncreasePriceNegotiation;
    form.totalPay = form.totalGoldPrice + form.totalLabour;
    form.netPay = form.totalPay;
    form.standardExcellent =
      form.totalLabourStandard *
      RangeOfStatusForCalculateSellConstant.excellent;
    form.standardGood =
      form.totalLabourStandard * RangeOfStatusForCalculateSellConstant.good;
    form.standardFair =
      form.totalLabourStandard * RangeOfStatusForCalculateSellConstant.fair;
    return form;
  };

  handleSetTradeByProductEqualWeightPurchase = (
    product: ProductModel,
    goldPurchaseNow: number,
    form: TradeModel
  ) => {
    form.tradeHasItems[0] = {
      quantity: form.totalQuantity,
      weight: 0,
      labourStandard: 0,
      labour: 0,
      type: "OLD",
      goldPrice: 0,
      priceStandard: 0,
      price: 0,
      priceNegotiation: 0,
    };
    return form;
  };

  @action
  onSetTradeByProductNotEqualWeight = (
    product: ProductModel,
    goldSellNow: number,
    goldPurchaseNow: number
  ) => {
    const form: TradeModel = { ...this.form };
    form.product = product;
    form.productCode = product.code;
    form.productName = product.name;
    form.productPercentage = product.percentageGold || 0;
    form.totalQuantity = 1;
    form.totalWeight = 0;
    form.totalGoldPrice = 0;
    form.totalLabourStandard = 0;
    form.totalLabour = 0;
    form.totalPay = 0;
    form.totalPriceNegotiation = 0;
    form.productType = ProductWeightTypeConstant.notEqualWeight;
    form.tradeHasItems = [];
    form.currentStatus = "good";
    form.standardExcellent = 0;
    form.standardGood = 0;
    form.standardFair = 0;
    form.standardPoor = 0;
    this.formHasItemIsInvalid = [];
    this.onSetForm({ ...form });
    this.onSetFormIsInvalid({ ...this.initFormIsInvalid });
    this.onAddTradeItem();
    this.onClearPayment(0);
  };

  @action
  onSetTradeByProductManual = (
    product: ProductModel,
    goldSellNow: number,
    goldPurchaseNow: number
  ) => {
    const form: TradeModel = { ...this.form };
    form.tradeHasItems = [];
    form.tradeHasItems[0] = {
      quantity: form.totalQuantity,
      weight: 0,
      labourStandard: 0,
      labour: 0,
      type: "NEW",
      goldPrice: 0,
      priceStandard: 0,
      price: 0,
      priceNegotiation: 0,
    };
    form.product = product;
    form.productCode = product.code;
    form.productName = product.name;
    form.productPercentage = product.percentageGold;
    form.productType = ProductWeightTypeConstant.manual;
    form.totalQuantity = 1;
    form.totalWeight = 0;
    form.totalGoldPrice = 0;
    form.totalLabourStandard = 0;
    form.totalLabour =
      form.tradeHasItems?.reduce((n, { labour }) => n + labour, 0) ||
      form.totalLabourStandard;
    form.totalPay = form.totalGoldPrice + form.totalLabourStandard;
    form.netPay = form.totalPay;
    form.standardExcellent = 0;
    form.standardGood = 0;
    form.standardFair = 0;
    this.onAddTradeItem();
    this.onSetForm(form);
  };

  @action
  onSetFormHasItemIsInvalid = (
    formIsInvalid: TradeHasItemIsInvalidModel,
    index: number
  ) => {
    this.formHasItemIsInvalid[index] = { ...formIsInvalid };
  };

  @action
  onDeleteFormHasItemIsInvalid = (index: number) => {
    this.formHasItemIsInvalid.splice(index, 1);
  };

  @action
  onAddTradeItem = () => {
    if (
      this.form.tradeHasItems.length === 0 ||
      this.form.tradeHasItems[this.form.tradeHasItems.length - 1].weight
    ) {
      const tradeHasItemValue: TradeHasItemModel = {
        quantity: 1,
        weight: 0,
        labourStandard: 0,
        labour: 0,
        type: "NEW",
        goldPrice: 0,
        priceStandard: 0,
        price: 0,
        priceNegotiation: 0,
      };
      this.form.tradeHasItems.push({ ...tradeHasItemValue });
      this.form.totalQuantity = this.form.tradeHasItems.length;
      this.formHasItemIsInvalid.push({
        ...this.defaultFormTradeHasItemIsInvalid,
      });
    } else {
      this.formHasItemIsInvalid[this.form.tradeHasItems.length - 1].weight =
        true;
    }
  };

  @action
  onClearPayment = (newNetPay: number) => {
    const form: TradeModel = { ...this.form };
    form.cardPay = 0;
    form.paymentType = KeysTypeConstant.allCash;
    form.percentChargeCard = 0;
    form.chargeCard = 0;
    form.netPay = newNetPay;
    this.onSetForm(form);
  };

  @action
  calculatePurchaseRangeForValidate = (
    productPercentage: number,
    goldPurchasePrice: number,
    totalWeight: number
  ) => {
    const rangeOfStatus =
      productPercentage >= 80
        ? RangeOfStatusForCalculateTypeMore80Constant
        : RangeOfStatusForCalculateTypeLess80Constant;
    const purchasePriceStandardExcellent = CalculatePurchasePriceStandardUtil(
      goldPurchasePrice,
      productPercentage + rangeOfStatus.excellent,
      totalWeight
    );
    const purchasePriceStandard = CalculatePurchasePriceStandardUtil(
      goldPurchasePrice,
      productPercentage + rangeOfStatus.good,
      totalWeight
    );
    const purchasePriceStandardFair = CalculatePurchasePriceStandardUtil(
      goldPurchasePrice,
      productPercentage + rangeOfStatus.fair,
      totalWeight
    );
    const purchasePriceStandardPoor = CalculatePurchasePriceStandardUtil(
      goldPurchasePrice,
      productPercentage + rangeOfStatus.poor,
      totalWeight
    );
    const finalPurchasePriceStandardExcellent = PriceRoundDownUtil(
      purchasePriceStandardExcellent
    );
    const finalPurchasePriceStandard = PriceRoundDownUtil(
      purchasePriceStandard
    );
    const finalPurchasePriceStandardFair = PriceRoundDownUtil(
      purchasePriceStandardFair
    );
    const finalPurchasePriceStandardPoor = PriceRoundDownUtil(
      purchasePriceStandardPoor
    );
    return {
      purchasePriceStandard: finalPurchasePriceStandard,
      purchasePriceStandardExcellent: finalPurchasePriceStandardExcellent,
      purchasePriceStandardGood: finalPurchasePriceStandard,
      purchasePriceStandardFair:
        productPercentage < 100
          ? finalPurchasePriceStandardFair
          : finalPurchasePriceStandard,
      purchasePriceStandardPoor:
        productPercentage < 100
          ? finalPurchasePriceStandardPoor
          : finalPurchasePriceStandard,
    };
  };

  @action
  onValidateForm = () => {
    let isValid: boolean = true;
    const formObj: any = { ...this.form };
    const formIsInvalidObj: any = { ...this.formIsInvalid };
    const formHasItemIsInvalidArr: any[] = [...this.formHasItemIsInvalid];
    isValid = formObj.totalLabour >= 0;
    Object.keys(formIsInvalidObj).forEach((key) => {
      formIsInvalidObj[key] = Boolean(!formObj[key]);
      if (key === "cardPay")
        formIsInvalidObj[key] =
          formObj.paymentType === KeysTypeConstant.mix &&
          formObj.cardPay >= formObj.totalPay;
      if (isValid) isValid = Boolean(!formIsInvalidObj[key]);
    });
    formHasItemIsInvalidArr.forEach(
      (obj: TradeHasItemIsInvalidModel, index: number) => {
        obj.weight = Boolean(
          obj.weight || !formObj.tradeHasItems[index].weight
        );
        obj.price = Boolean(!formObj.tradeHasItems[index].price);
        if (isValid) isValid = Boolean(!obj.weight && !obj.price);
      }
    );
    this.formIsInvalid = { ...formIsInvalidObj };
    this.formHasItemIsInvalid = [...formHasItemIsInvalidArr];
    return isValid;
  };

  @action
  getBody = () => {
    const body = {
      productCode: this.form.productCode,
      totalWeight: this.form.totalWeight,
      totalQuantity: this.form.totalQuantity,
      totalLabour: this.form.totalLabour,
      totalLabourStandard: this.form.totalLabourStandard,
      totalPay: this.form.totalPay,
      dailyGoldTime: this.form.goldPriceDateTime || "",
      productImageId: this.form.productImageId || "",
      item: this.form.tradeHasItems?.map((item: TradeHasItemModel) => {
        return {
          quantity: item.quantity,
          price: item.price,
          weight: item.weight,
        };
      }),
      payment: {
        paymentType: this.form.paymentType,
        netPay: this.form.netPay,
        ...(this.form.cardPay &&
          this.form.chargeCard && {
            creditCard: {
              cardPay: this.form.cardPay,
              chargeCard: this.form.chargeCard,
            },
          }),
      },
    };
    return body;
  };
}
