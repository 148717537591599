import dayjs, { Dayjs } from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import BuddhistEra from "dayjs/plugin/buddhistEra";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/th";

dayjs.extend(RelativeTime);
dayjs.extend(BuddhistEra);
dayjs.extend(utc);

const dateBuddhistEra = (date: Dayjs | string) => {
  const dateString = dayjs.utc(date).local().locale("th").format("DD/MM/BBBB");
  return dateString;
};

const dateTimeBuddhistEra = (date: Dayjs | string) => {
  const dateString = dayjs
    .utc(date)
    .local()
    .locale("th")
    .format("DD/MM/BBBB HH:mm");
  return dateString;
};

export {
  dateBuddhistEra as DateBuddhistEra,
  dateTimeBuddhistEra as DateTimeBuddhistEra,
};
