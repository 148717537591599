import Axios from "../plugins/Axios";
import {
  Login as LoginModel,
  CheckPinCode as CheckPinCodeModel,
} from "../models/User";

const login = async (body: LoginModel) => {
  try {
    const { data } = await Axios.post("/auth/login", { ...body });
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const getMe = async () => {
  try {
    const { data } = await Axios.get("/auth/me");
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const getUsers = async () => {
  try {
    const { data } = await Axios.get("/users/list");
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: [],
    };
  }
};

const checkPinCode = async (body: CheckPinCodeModel) => {
  try {
    const { data } = await Axios.post("/auth/check-pin", body);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

export {
  login as Login,
  getMe as GetMe,
  getUsers as GetUsers,
  checkPinCode as CheckPinCode,
};
