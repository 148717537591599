import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import StyleVariables from "../../../assets/styles/Variable.module.scss";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import {
  Category as CategoryModel,
  Product as ProductModel,
} from "../../../models/Product";
import {
  GetCategories as GetCategoriesService,
  GetProductsByCategory as GetProductsByCategoryService,
} from "../../../services/MasterData";
import {
  DepositIsInvalid as DepositIsInvalidModel,
  Deposit as DepositModel,
} from "../../../models/Deposit";
import { OnMappingQuantityOptions as OnMappingQuantityOptionsUtil } from "../../../utils/Form";
import { CalculateDepositPriceStandard as CalculateDepositPriceStandardUtil } from "../../../utils/Gold";
import {
  RangeOfStatusForCalculateTypeMore80 as RangeOfStatusForCalculateTypeMore80Constant,
  RangeOfStatusForCalculateTypeLess80 as RangeOfStatusForCalculateTypeLess80Constant,
} from "../../../constants/Purchase";
import { Type as TypeGoldConstant } from "../../../constants/Gold";
import { CurrentStatusPurchasePrice as CurrentStatusPurchasePriceUtil } from "../../../utils/Gold";
import SelectionDropdown from "../../dropdowns/Selection";
import NumberInput from "../../inputs/Number";
import NumberFieldWithButtonInput from "../../inputs/NumberFieldWithButton";
import { NumericFormat } from "react-number-format";

type Props = {};

const Deposit = observer((props: Props) => {
  const { DepositStore, MainStore, UserStore } = useStores();
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [products, setProducts] = useState<ProductModel[]>([]);

  useEffect(() => {
    getCategories();
    return () => {
      DepositStore.onClearStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DepositStore]);

  const getCategories = async () => {
    const response = await GetCategoriesService(TypeGoldConstant.purchase);
    if (response.success) {
      setCategories(response.data);
      response.data?.length === 1 && onCategorySelected(response.data[0]);
    }
  };
  const getProductsByCategory = async (code: string) => {
    setProducts([]);
    const response = await GetProductsByCategoryService(
      code,
      TypeGoldConstant.purchase
    );
    setProducts(response.data);
    response.data?.length === 1 && onProductSelected(response.data[0]);
    return response.data;
  };

  const onCategorySelected = (category: CategoryModel) => {
    DepositStore.onClearStore();
    const form: DepositModel = JSON.parse(JSON.stringify(DepositStore.form));
    form.userFirstName = UserStore.user.employeeFirstName;
    form.userLastName = UserStore.user.employeeLastName;
    form.branchName = UserStore.user.companyName;
    form.branchCode = UserStore.user.companyCode;
    form.categoryCode = category.code;
    form.categoryName = category.name;
    form.category = category;
    form.goldSellPrice = MainStore.goldSellNow;
    form.goldPurchasePrice = MainStore.goldPurchaseNow;
    form.goldPriceDateTime = MainStore.goldDateTime;
    DepositStore.onSetForm({ ...form });
    getProductsByCategory(category.code);
  };
  const onProductSelected = (product: ProductModel) => {
    DepositStore.onSetDepositByProductEqualWeight(product);
  };
  const onInputWeightChange = (newWeight: number) => {
    const form: DepositModel = JSON.parse(JSON.stringify(DepositStore.form));
    const formIsInvalid: DepositIsInvalidModel = JSON.parse(
      JSON.stringify(DepositStore.formIsInvalid)
    );
    form.totalWeight = newWeight;
    form.currentStatus = "good";
    const handleDepositPriceForm: DepositModel = handleDepositPrice(
      form.totalWeight,
      form.period,
      form
    );
    formIsInvalid.totalWeight = Boolean(!newWeight);
    formIsInvalid.depositPrice = false;
    DepositStore.onSetForm({ ...form, ...handleDepositPriceForm });
    DepositStore.onSetFormIsInvalid({ ...formIsInvalid });
  };
  const onInputDepositPriceChange = (newDepositPrice: number) => {
    const form: DepositModel = JSON.parse(JSON.stringify(DepositStore.form));
    const formIsInvalid: DepositIsInvalidModel = JSON.parse(
      JSON.stringify(DepositStore.formIsInvalid)
    );
    form.depositPrice = newDepositPrice;
    form.currentStatus = form.productPercentage
      ? CurrentStatusPurchasePriceUtil(
          newDepositPrice,
          form.standardExcellent,
          form.standardGood,
          form.standardFair,
          0
        )
      : "good";
    formIsInvalid.depositPrice = Boolean(!newDepositPrice);
    DepositStore.onSetForm({ ...form });
    DepositStore.onSetFormIsInvalid({ ...formIsInvalid });
  };
  const onInputChange = (key: string, value: any) => {
    const form: any = JSON.parse(JSON.stringify(DepositStore.form));
    form[key] = value;
    DepositStore.onSetForm({ ...form });
  };
  const handleDepositPrice = (
    weight: number,
    period: number,
    form: DepositModel
  ) => {
    const rangeOfStatus =
      form.productPercentage >= 80
        ? RangeOfStatusForCalculateTypeMore80Constant
        : RangeOfStatusForCalculateTypeLess80Constant;
    const depositPriceStandardExcellent = CalculateDepositPriceStandardUtil(
      form.goldPurchasePrice,
      form.productPercentage + rangeOfStatus.excellent,
      weight,
      period
    );
    const depositPriceStandard = CalculateDepositPriceStandardUtil(
      form.goldPurchasePrice,
      form.productPercentage + rangeOfStatus.good,
      weight,
      period
    );
    const depositPriceStandardFair = CalculateDepositPriceStandardUtil(
      form.goldPurchasePrice,
      form.productPercentage + rangeOfStatus.fair,
      weight,
      period
    );
    const depositPriceStandardPoor = CalculateDepositPriceStandardUtil(
      form.goldPurchasePrice,
      form.productPercentage + rangeOfStatus.poor,
      weight,
      period
    );
    form.depositPrice = depositPriceStandard;
    form.depositPriceStandard = depositPriceStandard;
    form.standardExcellent = depositPriceStandardExcellent;
    form.standardGood = depositPriceStandard;
    form.standardFair =
      form.productPercentage < 100
        ? depositPriceStandardFair
        : depositPriceStandard;
    form.standardPoor =
      form.productPercentage < 100
        ? depositPriceStandardPoor
        : depositPriceStandard;
    return form;
  };
  const onInputPeriodChange = (newPeriod: number) => {
    const form: DepositModel = JSON.parse(JSON.stringify(DepositStore.form));
    form.period = newPeriod;
    form.currentStatus = "good";
    const handleDepositPriceForm: DepositModel = handleDepositPrice(
      form.totalWeight,
      form.period,
      form
    );
    DepositStore.onSetForm({ ...form, ...handleDepositPriceForm });
  };

  return (
    <Box>
      <Grid container spacing={{ xs: 3, md: 2 }}>
        <Grid item xs={12} md={6}>
          <SelectionDropdown
            name="category"
            label="ประเภทสินค้า"
            options={categories}
            selected={DepositStore.form.category}
            labelKey="name"
            valueKey="code"
            imageKey="img"
            placeholder="เลือกประเภทสินค้า"
            isShowImage={true}
            isError={DepositStore.formIsInvalid.categoryCode}
            onSelected={(selected: CategoryModel) => {
              onCategorySelected(selected);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectionDropdown
            name="product"
            label="ชื่อสินค้า"
            options={products}
            selected={DepositStore.form.product}
            labelKey="name"
            valueKey="code"
            placeholder="เลือกชื่อสินค้า"
            imageKey="img"
            isDisabled={!DepositStore.form.categoryCode}
            isShowImage={true}
            isError={DepositStore.formIsInvalid.productCode}
            onSelected={(selected: ProductModel) => {
              onProductSelected(selected);
            }}
          />
        </Grid>
      </Grid>
      {DepositStore.form.productCode && (
        <Box marginTop={2}>
          <Grid
            container
            spacing={{ xs: 3, md: 2 }}
            rowSpacing={2}
            alignItems={"flex-end"}
          >
            <Grid item xs={12} md={6} marginTop={{ xs: 0, md: 1 }}>
              <Grid container spacing={2}>
                {/* Total Weight  */}
                <Grid item xs={12} md={6}>
                  <NumberInput
                    name="totalWeight"
                    label="น้ำหนักรวม"
                    placeholder="กรอกน้ำหนักรวม"
                    inputMode="decimal"
                    decimalScale={3}
                    value={`${DepositStore.form.totalWeight || ""}`}
                    isError={DepositStore.formIsInvalid.totalWeight}
                    handleOnChange={(value: string) => {
                      onInputWeightChange(Number(value || 0));
                    }}
                  />
                </Grid>
                {/* /.Weight Per Unit  */}

                {/* Total Quantity */}
                <Grid item xs={12} md={6}>
                  <SelectionDropdown
                    name="totalQuantity"
                    label="จำนวนรวม"
                    options={OnMappingQuantityOptionsUtil(99)}
                    selected={DepositStore.form.totalQuantity}
                    placeholder="เลือกจำนวนรวม"
                    onSelected={(selected: number) => {
                      onInputChange("totalQuantity", selected);
                    }}
                  />
                </Grid>
                {/* /.Total Quantity */}
              </Grid>
            </Grid>
            {/* Period */}
            <Grid item xs={12} md={6} marginTop={{ xs: 0, md: 1 }}>
              <SelectionDropdown
                name="period"
                label="ระยะเวลา(เดือน)"
                options={OnMappingQuantityOptionsUtil(6)}
                selected={DepositStore.form.period}
                placeholder="เลือกระยะเวลา(เดือน)"
                onSelected={(selected: number) => {
                  onInputPeriodChange(selected);
                }}
              />
            </Grid>
            {/* /.Period */}

            {/* Deposit Price Cash */}
            {DepositStore.form.totalWeight &&
            (DepositStore.form.depositPriceStandard ||
              !DepositStore.form.productPercentage) ? (
              <Grid item xs={12} md={10} lg={6} marginTop={{ xs: 0, md: 1 }}>
                {DepositStore.form.productPercentage ? (
                  <NumberFieldWithButtonInput
                    name="depositPrice"
                    label="ราคารับฝาก"
                    subLabel={
                      <>
                        <Typography
                          variant="body1"
                          fontWeight={"bold"}
                          color={StyleVariables["color-primary"]}
                        >
                          {` (วงเงินสูงสุด `}
                          <NumericFormat
                            value={DepositStore.form.depositPriceStandard}
                            displayType="text"
                            thousandSeparator
                          />
                          {`)`}
                        </Typography>
                      </>
                    }
                    placeholder={"กรอกราคารับฝาก"}
                    value={DepositStore.form.depositPrice}
                    isDecreaseDisabled={DepositStore.form.depositPrice <= 0}
                    isIncreaseDisabled={
                      (DepositStore.form.currentStatus === "poor" &&
                        DepositStore.form.depositPrice >=
                          DepositStore.form.standardPoor) ||
                      (DepositStore.form.productPercentage >= 100 &&
                        DepositStore.form.depositPrice ===
                          DepositStore.form.depositPriceStandard)
                    }
                    rangeOfDecrease={100}
                    rangeOfIncrease={100}
                    isError={
                      DepositStore.form.currentStatus === "poor" ||
                      DepositStore.formIsInvalid.depositPrice
                    }
                    status={
                      DepositStore.form.depositPrice
                        ? DepositStore.form.currentStatus
                        : ""
                    }
                    handleOnChange={(value: number | string) => {
                      onInputDepositPriceChange(Number(value || 0));
                    }}
                    handleOnIncrease={(value: number | string) => {
                      onInputDepositPriceChange(Number(value || 0));
                    }}
                    handleOnDecrease={(value: number | string) => {
                      onInputDepositPriceChange(Number(value || 0));
                    }}
                    handleOnClear={() => onInputDepositPriceChange(0)}
                  />
                ) : (
                  <NumberInput
                    name="depositPrice"
                    label="ราคารับฝาก"
                    placeholder="กรอกราคารับฝาก"
                    value={`${DepositStore.form.depositPrice || ""}`}
                    isError={DepositStore.formIsInvalid.depositPrice}
                    handleOnChange={(value: string) => {
                      onInputDepositPriceChange(Number(value || 0));
                    }}
                  />
                )}
              </Grid>
            ) : null}
            {/* /.Deposit Price Cash */}
          </Grid>
        </Box>
      )}
    </Box>
  );
});

export default Deposit;
