import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  HighlightOff as HighlightOffIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";

type Props = {
  type: "error" | "success" | "warning";
  title: string;
  description?: string;
};

const Content = (props: Props) => {
  switch (props.type) {
    case "error":
      return (
        <Box
          bgcolor={StyleVariables["color-error-light"]}
          border={`1px solid ${StyleVariables["color-error"]}`}
          paddingY={2}
          paddingX={3}
          borderRadius={"10px"}
        >
          <Stack
            gap={1}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <HighlightOffIcon
              sx={{ color: StyleVariables["color-error-icon"], fontSize: 40 }}
            />
            <Box>
              <Typography
                variant="body2"
                fontWeight={"medium"}
                color={StyleVariables["color-error"]}
              >
                {props.title}
              </Typography>
              {props.description && (
                <Typography
                  variant="subtitle2"
                  fontWeight={"regular"}
                  color={StyleVariables["color-text"]}
                  marginTop={1}
                >
                  {props.description}
                </Typography>
              )}
            </Box>
          </Stack>
        </Box>
      );
    case "success":
      return (
        <Box
          bgcolor={StyleVariables["color-success-light"]}
          border={`1px solid ${StyleVariables["color-success"]}`}
          paddingY={2}
          paddingX={3}
          borderRadius={"10px"}
        >
          <Stack
            gap={1}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CheckCircleOutlineIcon
              sx={{ color: StyleVariables["color-success-icon"], fontSize: 40 }}
            />
            <Box>
              <Typography
                variant="body2"
                fontWeight={"medium"}
                color={StyleVariables["color-success"]}
              >
                {props.title}
              </Typography>
              {props.description && (
                <Typography
                  variant="subtitle2"
                  fontWeight={"regular"}
                  color={StyleVariables["color-text"]}
                  marginTop={1}
                >
                  {props.description}
                </Typography>
              )}
            </Box>
          </Stack>
        </Box>
      );
    case "warning":
      return (
        <Box
          bgcolor={StyleVariables["color-warning-light"]}
          border={`1px solid ${StyleVariables["color-warning"]}`}
          paddingY={2}
          paddingX={3}
          borderRadius={"10px"}
        >
          <Stack
            gap={1}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <ErrorOutlineIcon
              sx={{ color: StyleVariables["color-warning-icon"], fontSize: 40 }}
            />
            <Box>
              <Typography
                variant="body2"
                fontWeight={"medium"}
                color={StyleVariables["color-warning"]}
              >
                {props.title}
              </Typography>
              {props.description && (
                <Typography
                  variant="subtitle2"
                  fontWeight={"regular"}
                  color={StyleVariables["color-text"]}
                  marginTop={1}
                >
                  {props.description}
                </Typography>
              )}
            </Box>
          </Stack>
        </Box>
      );
    default:
      return <></>;
  }
};

export default Content;
