import React, { useEffect } from "react";
import Header from "../components/layouts/Header";
import { Box } from "@mui/material";
import Sidebar from "../components/layouts/Sidebar";
import { observer } from "mobx-react";
import { useStores } from "../stores";
import IdleTimer from "react-idle-timer";

type Props = {
  children: JSX.Element;
};

const Main = observer((props: Props) => {
  const { MainStore, UserStore } = useStores();

  useEffect(() => {
    const handleAutoLogin = async () => {
      if (localStorage.accessToken && !UserStore.isLoggedIn) {
        UserStore.getMe();
      }
    };
    handleAutoLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnIdle = () => {
    UserStore.handleLogout();
  };

  return (
    <>
      <IdleTimer timeout={1000 * 60 * 30} onIdle={handleOnIdle} />
      <Header />
      <Box display={"flex"}>
        {MainStore.isOpenSidebar && <Sidebar />}
        <Box
          paddingY={2}
          paddingLeft={MainStore.isOpenSidebar ? 4 : 2}
          paddingRight={2}
          marginLeft={MainStore.isOpenSidebar ? "120px" : 0}
          marginY={2}
          width={MainStore.isOpenSidebar ? "calc(100% - 168px)" : "100%"}
          sx={{ overflowX: "hidden" }}
        >
          {props.children}
        </Box>
      </Box>
    </>
  );
});

export default Main;
