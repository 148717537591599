import React from "react";
import { Box, Button, Grid, Divider, Hidden, Typography } from "@mui/material";
import { DeleteOutline as DeleteOutlineIcon } from "@mui/icons-material";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import StyleVariables from "../../../assets/styles/Variable.module.scss";
import {
  Trade as TradeModel,
  TradeHasItem as TradeHasItemModel,
  TradeHasItemIsInvalid as TradeHasItemIsInvalidModel,
} from "../../../models/Trade";
import { ProductLabourByWeight as ProductLabourByWeightModel } from "../../../models/Product";
import {
  CalculateGoldPriceStandard as CalculateGoldPriceStandardUtil,
  CalculateSellPriceStandard as CalculateSellPriceStandardUtil,
  CalculateSellDiscount as CalculateSellDiscountUtil,
  CurrentStatusSellPrice as CurrentStatusSellPriceUtil,
  CalculateLabourByAverageDiscount as CalculateLabourByAverageDiscountUtil,
  CalculateSellNegotiation as CalculateSellNegotiationUtil,
} from "../../../utils/Gold";
import NumberInput from "../../inputs/Number";
import { PriceRoundUp as PriceRoundUpUtil } from "../../../utils/NumberRounding";
import NumberFieldWithButtonInput from "../../inputs/NumberFieldWithButton";
import { RangeOfStatusForCalculate as RangeOfStatusForCalculateSellConstant } from "../../../constants/Sell";
import { NumericFormat } from "react-number-format";

type Props = {};

const NotEqualWeight = observer((props: Props) => {
  const { TradeStore } = useStores();

  const findLabourStandardByWeight = (weight: number) => {
    const findLabourProductByWeight =
      TradeStore.form.product?.labourByWeight?.find(
        (productLabour: ProductLabourByWeightModel) =>
          weight >= Number(productLabour.minWeight) &&
          weight <= Number(productLabour.maxWeight)
      );
    return findLabourProductByWeight;
  };

  const onWeightInputChange = (index: number, newWeight: number) => {
    const form: TradeModel = JSON.parse(JSON.stringify(TradeStore.form));
    const formHasItemIsInvalid: TradeHasItemIsInvalidModel = JSON.parse(
      JSON.stringify({
        ...TradeStore.formHasItemIsInvalid[index],
      })
    );
    const findLabourProductByWeight = findLabourStandardByWeight(newWeight);
    const labourStandard = Number(
      findLabourProductByWeight?.labour || TradeStore.form.product?.labour || 0
    );
    const priceNegotiation = CalculateSellNegotiationUtil(labourStandard);
    const goldPriceStandard = PriceRoundUpUtil(
      CalculateGoldPriceStandardUtil(
        form.goldSellPrice,
        form.productPercentage,
        form.categoryCode === "11",
        1,
        Number(newWeight)
      )
    );
    form.tradeHasItems = form.tradeHasItems.map(
      (item: TradeHasItemModel, ind: number) => {
        const sellPriceStandard =
          ind !== index
            ? CalculateSellPriceStandardUtil(
                item.goldPrice,
                item.labourStandard + item.priceNegotiation,
                1
              )
            : CalculateSellPriceStandardUtil(
                goldPriceStandard,
                labourStandard + priceNegotiation,
                1
              );
        return ind !== index
          ? {
              ...item,
              labour: item.labourStandard + item.priceNegotiation,
              priceStandard: sellPriceStandard,
              price: sellPriceStandard,
            }
          : {
              ...item,
              weight: newWeight,
              labourStandard: newWeight ? labourStandard : 0,
              labour: newWeight ? labourStandard + priceNegotiation : 0,
              goldPrice: goldPriceStandard,
              priceStandard: newWeight ? sellPriceStandard : 0,
              price: newWeight ? sellPriceStandard : 0,
              priceNegotiation: priceNegotiation,
            };
      }
    );
    const totalWeight = form.tradeHasItems?.reduce(
      (n, { weight }) => n + weight,
      0
    );
    const totalGoldPrice = form.tradeHasItems?.reduce(
      (n, { goldPrice }) => n + goldPrice,
      0
    );
    const totalLabourStandard = form.tradeHasItems?.reduce(
      (n, { labourStandard }) => n + labourStandard,
      0
    );
    const totalLabour = form.tradeHasItems?.reduce(
      (n, { labour }) => n + labour,
      0
    );
    const totalPay = form.tradeHasItems?.reduce(
      (n, { priceStandard }) => n + priceStandard,
      0
    );
    const totalPriceNegotiation = form.tradeHasItems?.reduce(
      (n, { priceNegotiation }) => n + priceNegotiation,
      0
    );
    form.totalWeight = totalWeight;
    form.totalGoldPrice = totalGoldPrice;
    form.totalLabourStandard = totalLabourStandard;
    form.totalLabour = totalLabour;
    form.totalPay = totalPay;
    form.totalPriceNegotiation = totalPriceNegotiation;
    form.standardExcellent =
      form.totalLabourStandard *
      RangeOfStatusForCalculateSellConstant.excellent;
    form.standardGood =
      form.totalLabourStandard * RangeOfStatusForCalculateSellConstant.good;
    form.standardFair =
      form.totalLabourStandard * RangeOfStatusForCalculateSellConstant.fair;
    form.currentStatus = CurrentStatusSellPriceUtil(
      form.totalLabour,
      form.standardExcellent,
      form.standardGood,
      form.standardFair
    );
    formHasItemIsInvalid.weight = Boolean(
      !newWeight ||
        (findLabourProductByWeight &&
          newWeight > Number(findLabourProductByWeight?.maxWeight))
    );
    formHasItemIsInvalid.price = Boolean(!form.tradeHasItems[index].price);
    TradeStore.onSetForm({ ...form });
    TradeStore.onSetFormHasItemIsInvalid({ ...formHasItemIsInvalid }, index);
    TradeStore.onClearPayment(totalPay);
  };

  const onTotalPayChange = (newTotalPay: number) => {
    const form: TradeModel = JSON.parse(JSON.stringify(TradeStore.form));
    const totalDiscount = CalculateSellDiscountUtil(
      form.totalGoldPrice + form.totalLabourStandard,
      newTotalPay,
      1
    );
    form.tradeHasItems = form.tradeHasItems.map((item: TradeHasItemModel) => {
      const newLabour = CalculateLabourByAverageDiscountUtil(
        totalDiscount,
        item.labourStandard,
        form.totalLabourStandard,
        form.totalWeight,
        item.weight
      );
      return {
        ...item,
        labour: newLabour,
        goldPrice: item.goldPrice,
        price: item.goldPrice + newLabour,
      };
    });
    form.totalLabour = form.totalLabourStandard - totalDiscount;
    form.totalPay = newTotalPay;
    form.currentStatus = CurrentStatusSellPriceUtil(
      form.totalLabour,
      form.standardExcellent,
      form.standardGood,
      form.standardFair
    );
    TradeStore.onSetForm({ ...form });
    TradeStore.onClearPayment(newTotalPay);
  };

  const onDeleteTradeItem = (index: number) => {
    const form: TradeModel = JSON.parse(JSON.stringify(TradeStore.form));
    const itemDeleted = form.tradeHasItems[index];
    form.tradeHasItems.splice(index, 1);
    form.totalQuantity -= 1;
    TradeStore.onSetForm({ ...form });
    TradeStore.onDeleteFormHasItemIsInvalid(index);
    itemDeleted.price && onWeightInputChange(0, form.tradeHasItems[0].weight);
  };

  return (
    <>
      <Box marginY={3} display={"flex"} justifyContent={"flex-end"}>
        <Button variant="outlined" onClick={TradeStore.onAddTradeItem}>
          เพิ่มจำนวน
        </Button>
      </Box>
      {TradeStore.form.tradeHasItems?.map(
        (item: TradeHasItemModel, index: number) => {
          return (
            <Box key={index}>
              <Hidden mdUp>
                {index > 0 ? (
                  <Divider
                    sx={{
                      backgroundColor: StyleVariables["color-border"],
                      marginBottom: 3,
                    }}
                  />
                ) : null}
              </Hidden>
              <Grid
                container
                spacing={{ xs: 3, md: 2 }}
                rowSpacing={1}
                alignItems={"center"}
              >
                <Grid item xs={12} md={3} marginBottom={3}>
                  <NumberInput
                    name="productNumber"
                    label={index > 0 ? "" : "สินค้าชิ้นที่"}
                    value={`${index + 1}`}
                    placeholder="ชิ้นที่"
                    isDisabled={true}
                    handleOnChange={(value: string) => {}}
                  />
                </Grid>
                <Grid item xs={12} md={3} marginBottom={3}>
                  <NumberInput
                    name="weightPerUnit"
                    label={index > 0 ? "" : "น้ำหนัก/ชิ้น"}
                    value={`${item.weight || ""}`}
                    placeholder="กรอกน้ำหนัก/ชิ้น"
                    inputMode="decimal"
                    decimalScale={3}
                    isError={TradeStore.formHasItemIsInvalid[index].weight}
                    handleOnChange={(value: string) => {
                      onWeightInputChange(index, Number(value));
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} marginBottom={3}>
                  <Box display={"flex"} alignItems={"center"}>
                    <Box width={"100%"}>
                      <NumberInput
                        name="sellPricePerUnit"
                        label={index > 0 ? "" : "ราคาขายออกเงินสด/ชิ้น"}
                        value={`${item.price || ""}`}
                        placeholder="กรอกอัตโนมัติ"
                        isDisabled={true}
                        handleOnChange={(value: string) => {}}
                      />
                    </Box>
                    {TradeStore.form.totalQuantity > 1 && (
                      <DeleteOutlineIcon
                        sx={{
                          fontSize: 24,
                          color: StyleVariables["color-menu-icon"],
                          cursor: "pointer",
                          marginTop: index > 0 ? 0 : "38px",
                          marginLeft: 1,
                        }}
                        onClick={() => onDeleteTradeItem(index)}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          );
        }
      )}
      <Grid container spacing={{ xs: 3, md: 2 }}>
        {/* Total Quantity */}
        <Grid item xs={6} md={3}>
          <NumberInput
            name="sellTotalPay"
            label="จำนวนรวม"
            placeholder="กรอกอัตโนมัติ"
            value={`${TradeStore.form.totalQuantity}`}
            isDisabled={true}
            handleOnChange={(value: string) => {}}
          />
        </Grid>
        {/* /.Total Quantity */}

        {/* Total Weight */}
        <Grid item xs={6} md={3}>
          <NumberInput
            name="totalWeight"
            label="น้ำหนักรวม"
            placeholder="กรอกอัตโนมัติ"
            decimalScale={3}
            value={`${TradeStore.form.totalWeight || ""}`}
            isDisabled={true}
            handleOnChange={(value: string) => {}}
          />
        </Grid>
        {/* /.Total Weight */}

        {/* Total Sell Price */}
        <Grid item xs={12} md={6}>
          <NumberFieldWithButtonInput
            name="sellTotalPay"
            label="รับเงินจากลูกค้า"
            helperLabel={
              <Typography
                variant="body2"
                fontWeight={"bold"}
                color={StyleVariables["color-header"]}
              >
                {TradeStore.form.totalPay > 0 ? (
                  <>
                    {"(เปิด "}
                    <NumericFormat
                      value={
                        TradeStore.form.totalGoldPrice +
                        TradeStore.form.totalLabourStandard +
                        TradeStore.form.totalPriceNegotiation
                      }
                      displayType="text"
                      thousandSeparator
                    />
                    {")"}
                  </>
                ) : null}
              </Typography>
            }
            placeholder={"กรอกอัตโนมัติ"}
            value={TradeStore.form.totalWeight ? TradeStore.form.totalPay : ""}
            isDisabled={true}
            isDecreaseDisabled={
              TradeStore.form.totalLabour <= 0 || !TradeStore.form.totalWeight
            }
            isIncreaseDisabled={!TradeStore.form.totalWeight}
            isError={TradeStore.form.currentStatus === "poor"}
            status={TradeStore.form.currentStatus}
            handleOnChange={(value: number | string) => {}}
            handleOnIncrease={(value: number | string) => {
              onTotalPayChange(Number(value));
            }}
            handleOnDecrease={(value: number | string) => {
              onTotalPayChange(Number(value));
            }}
          />
        </Grid>
        {/* /.Total Sell Price */}

        {TradeStore.isShowAll && (
          <>
            {/* Total Gold Price */}
            <Grid
              item
              xs={12}
              md={4}
              marginTop={{ xs: 0, md: 1 }}
              sx={{ opacity: 0.6 }}
            >
              <NumberInput
                name="totalGoldPrice"
                label="ราคาเนื้อทองรวม"
                placeholder="กรอกอัตโนมัติ"
                value={`${
                  TradeStore.form.totalWeight
                    ? TradeStore.form.totalGoldPrice
                    : ""
                }`}
                handleOnChange={(value: string) => {}}
                isDisabled={true}
              />
            </Grid>
            {/* /.Total Gold Price */}

            {/* Total Labour */}
            <Grid
              item
              xs={12}
              md={4}
              marginTop={{ xs: 0, md: 1 }}
              sx={{ opacity: 0.6 }}
            >
              <NumberInput
                name="totalLabour"
                label="ค่ากำเหน็จรวม"
                placeholder="กรอกอัตโนมัติ"
                value={`${
                  TradeStore.form.totalWeight ? TradeStore.form.totalLabour : ""
                }`}
                handleOnChange={(value: string) => {}}
                isDisabled={true}
                isError={TradeStore.form.currentStatus === "poor"}
                isShowErrorMessage={true}
                errorMessage={`กำเหน็จมาตรฐานรวม ${TradeStore.form.totalLabourStandard}`}
              />
            </Grid>
            {/* /.Total Labour */}
          </>
        )}
      </Grid>
    </>
  );
});

export default NotEqualWeight;
