import React from "react";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import StyleVariables from "../assets/styles/Variable.module.scss";

type Props = {
  isOpen: boolean;
};

const LoadProgressing = (props: Props) => {
  return (
    <Backdrop sx={{ zIndex: 9999 }} open={props.isOpen}>
      <Box textAlign={"center"} paddingX={2}>
        <CircularProgress
          size={40}
          style={{ color: StyleVariables["color-textfield-icon"] }}
        />
        <Typography
          variant="body2"
          fontWeight={"medium"}
          color={StyleVariables["color-white"]}
          marginTop={2}
        >
          ระบบกำลังดำเนินการ
        </Typography>
        <Typography
          variant="body2"
          fontWeight={"medium"}
          color={StyleVariables["color-white"]}
        >
          กรุณาอย่าปิดเบราว์เซอร์ หรือรีเฟรชหน้าเพจ
        </Typography>
      </Box>
    </Backdrop>
  );
};

export default LoadProgressing;
