import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import ErrorContentSnackbar from "../snackbars/Content";
import PasswordInput from "../inputs/Password";

type Props = {
  isLoading?: boolean;
  isShowErrorMessage?: boolean;
  onSubmit: Function;
};

const ConfirmPassword = (props: Props) => {
  const [pinCode, setPinCode] = useState<string>("");
  const [pinCodeIsInvalid, setPinCodeIsInvalid] = useState<boolean>(false);
  const [isShowError, setIsShowError] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setPinCode("");
      setPinCodeIsInvalid(false);
      setIsShowError(false);
    };
  }, []);

  useEffect(() => {
    setIsShowError(props.isShowErrorMessage || false);
    setPinCodeIsInvalid(props.isShowErrorMessage || false);
  }, [props.isShowErrorMessage]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!pinCode) {
      setPinCodeIsInvalid(true);
      return;
    }
    props.onSubmit(pinCode);
  };
  const onInputPasswordChange = (value: string) => {
    setPinCode(value);
    setPinCodeIsInvalid(false);
    setIsShowError(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      {isShowError && (
        <Box marginTop={5}>
          <ErrorContentSnackbar
            type="error"
            title={"รหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง"}
          />
        </Box>
      )}
      <Box marginTop={5}>
        <PasswordInput
          name="pinCode"
          label="รหัสผ่าน"
          isRequire={true}
          placeholder="กรอกรหัสผ่าน"
          value={pinCode}
          isError={pinCodeIsInvalid}
          autoFocus={true}
          handleOnChange={(value: string) => onInputPasswordChange(value)}
        />
      </Box>
      <Box marginTop={5}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          disabled={props.isLoading}
        >
          {!props.isLoading ? (
            "ยืนยันตัวตน"
          ) : (
            <CircularProgress
              size={22}
              style={{ color: StyleVariables["color-white"] }}
            />
          )}
        </Button>
      </Box>
    </form>
  );
};

export default ConfirmPassword;
