import { Grid } from "@mui/material";
import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import NumberInput from "../../inputs/Number";

type Props = {};

const Reduce = observer((props: Props) => {
  const { DepositStore, PaymentStore } = useStores();

  const onReducePriceInputChange = (newReducePrice: number) => {
    const form: any = JSON.parse(JSON.stringify({ ...DepositStore.form }));
    const formRenew: any = JSON.parse(
      JSON.stringify({ ...DepositStore.formRenew })
    );
    const formRenewIsInvalid: any = JSON.parse(
      JSON.stringify({ ...DepositStore.formRenewIsInvalid })
    );
    Object.keys(formRenewIsInvalid).forEach((key) => {
      formRenewIsInvalid[key] = false;
    });
    formRenewIsInvalid.reducePrice =
      newReducePrice <= 0 || newReducePrice >= form.depositPrice;
    form.totalPay = newReducePrice + form.interest;
    form.netPay = form.totalPay;
    formRenew.newDepositPrice = form.depositPrice - newReducePrice;
    formRenew.reducePrice = newReducePrice;
    PaymentStore.onClearStore(form.netPay);
    DepositStore.onSetForm({ ...form });
    DepositStore.onSetFormRenew({ ...formRenew });
    DepositStore.onSetFormRenewIsInvalid({ ...formRenewIsInvalid });
  };

  return (
    <Grid container spacing={2} rowSpacing={2}>
      <Grid item xs={12} md={6}>
        <NumberInput
          name="reducePrice"
          label="จำนวนเงินลดต้น"
          placeholder="กรอกจำนวนเงินลดต้น"
          value={`${DepositStore.formRenew.reducePrice || ""}`}
          isError={DepositStore.formRenewIsInvalid.reducePrice}
          maxLength={`${DepositStore.form.depositPrice}`.length}
          isDisabled={false}
          handleOnChange={(value: number) => {
            onReducePriceInputChange(Number(value));
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput
          name="depositTotalPay"
          label="รับเงินจากลูกค้า"
          placeholder="กรอกอัตโนมัติ"
          value={`${DepositStore.form.totalPay || ""}`}
          inputPropsClassName="input-highlight"
          isDisabled={true}
          handleOnChange={(value: number) => {}}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput
          name="newDepositPrice"
          label="ราคารับฝากใหม่"
          placeholder="กรอกอัตโนมัติ"
          value={`${DepositStore.formRenew.newDepositPrice || ""}`}
          isDisabled={true}
          handleOnChange={(value: number) => {}}
        />
      </Grid>
    </Grid>
  );
});

export default Reduce;
