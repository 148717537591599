import React from "react";
import { Typography } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";

type Props = {
  name: string;
  label?: string;
  placeholder: string;
  value: string;
  isError?: boolean;
  isDisabled?: boolean;
  handleOnChange: (value: string) => void;
};

const TextArea = (props: Props) => {
  return (
    <>
      {props.label && (
        <Typography
          variant="body1"
          fontWeight={"bold"}
          color={StyleVariables["color-header"]}
          marginBottom={1}
        >
          {props.label}
        </Typography>
      )}
      <textarea
        name={props.name}
        className={(props.isError && "textarea-error") || ""}
        rows={6}
        placeholder={props.placeholder}
        value={props.value}
        disabled={props.isDisabled}
        onChange={(e) => props.handleOnChange(e.target.value)}
      />
    </>
  );
};

export default TextArea;
