import Axios from "../plugins/Axios";

const onUpload = async (file: File) => {
  try {
    const form = new FormData();
    form.append("file", file);
    const { data } = await Axios.post("/storages", form);
    return {
      success: true,
      data: data.data?.id || "",
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: "",
    };
  }
};

const onDelete = async (id: string) => {
  try {
    await Axios.delete(`/storages/${id}`);
    return {
      success: true,
      data: "",
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: "",
    };
  }
};

const onDownloadPdf = async (id: string) => {
  try {
    const { data } = await Axios.get(`/storages/${id}`, {
      responseType: "blob",
    });
    return {
      success: true,
      data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const onPrintPdf = async (id: string) => {
  try {
    await Axios.get(`/storages/${id}/print`);
    return {
      success: true,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
    };
  }
};

export {
  onUpload as OnUpload,
  onDelete as OnDelete,
  onDownloadPdf as OnDownloadPdf,
  onPrintPdf as OnPrintPdf,
};
