import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import StyleVariables from "../../../assets/styles/Variable.module.scss";
import NumberInput from "../../inputs/Number";
import NumberFieldWithButtonInput from "../../inputs/NumberFieldWithButton";
import { NumericFormat } from "react-number-format";
import {
  Deposit as DepositModel,
  DepositIsInvalid as DepositIsInvalidModel,
  DepositRenewIsInvalid as DepositRenewIsInvalidModel,
  DepositRenew as DepositRenewModel,
} from "../../../models/Deposit";
import { CurrentStatusPurchasePrice as CurrentStatusPurchasePriceUtil } from "../../../utils/Gold";
import { ProductWeightType as ProductWeightTypeConstant } from "../../../constants/Gold";

type Props = {};

const Purchase = observer((props: Props) => {
  const { DepositStore } = useStores();

  const onPurchasePriceChange = (newPurchasePrice: number) => {
    const form: DepositModel = JSON.parse(JSON.stringify(DepositStore.form));
    const formIsInvalid: DepositIsInvalidModel = JSON.parse(
      JSON.stringify(DepositStore.formIsInvalid)
    );
    const formRenew: DepositRenewModel = JSON.parse(
      JSON.stringify(DepositStore.formRenew)
    );
    const formRenewIsInvalid: DepositRenewIsInvalidModel = JSON.parse(
      JSON.stringify(DepositStore.formRenewIsInvalid)
    );
    form.totalPay = newPurchasePrice;
    form.netPay =
      newPurchasePrice - (form.depositPrice + Number(form.interest || 0));
    formRenew.purchasePriceCurrentStatus =
      formRenew.purchaseType !== ProductWeightTypeConstant.manual
        ? CurrentStatusPurchasePriceUtil(
            newPurchasePrice,
            formRenew.purchaseStandardExcellent,
            formRenew.purchaseStandardGood,
            formRenew.purchaseStandardFair,
            formRenew.purchaseStandardFair
          )
        : "good";
    formIsInvalid.totalPay =
      formRenew.purchasePriceCurrentStatus === "poor" ||
      Boolean(!newPurchasePrice);
    DepositStore.onSetForm(form);
    DepositStore.onSetFormIsInvalid(formIsInvalid);
    DepositStore.onSetFormRenew(formRenew);
    DepositStore.onSetFormRenewIsInvalid(formRenewIsInvalid);
  };

  return (
    <Grid container spacing={2} rowSpacing={2} alignItems={"flex-end"}>
      <Grid item xs={12} md={6}>
        {DepositStore.formRenew.purchaseType !==
        ProductWeightTypeConstant.manual ? (
          <NumberFieldWithButtonInput
            name="purchaseTotalPay"
            label="ราคารับซื้อ"
            helperLabel={
              <Typography
                variant="body2"
                fontWeight={"bold"}
                color={StyleVariables["color-header"]}
              >
                {Number(DepositStore.form.totalPay || 0) > 0 ? (
                  <>
                    {"(เปิด "}
                    <NumericFormat
                      value={
                        DepositStore.formRenew.purchasePriceStandard -
                        DepositStore.formRenew.purchasePriceNegotiation
                      }
                      displayType="text"
                      thousandSeparator
                    />
                    {")"}
                  </>
                ) : null}
              </Typography>
            }
            placeholder={"กรอกอัตโนมัติ"}
            value={DepositStore.form.totalPay || 0}
            isDisabled={true}
            isDecreaseDisabled={Number(DepositStore.form.totalPay) <= 0}
            isIncreaseDisabled={
              (DepositStore.formRenew.purchasePriceCurrentStatus === "poor" &&
                Number(DepositStore.form.totalPay) >=
                  DepositStore.formRenew.purchaseStandardPoor) ||
              (DepositStore.form.productPercentage >= 100 &&
                Number(DepositStore.form.totalPay) ===
                  DepositStore.formRenew.purchasePriceStandard)
            }
            isError={
              DepositStore.formRenew.purchasePriceCurrentStatus === "poor"
            }
            inputPropsClassName="input-highlight-secondary input-highlight-regular"
            errorMessage={
              <Box display={"flex"} gap={1} alignItems={"center"} marginTop={1}>
                <ErrorIcon
                  sx={{
                    fontSize: 32,
                    color: StyleVariables["color-error"],
                  }}
                />
                <Typography
                  variant="subtitle1"
                  fontWeight={"medium"}
                  color={StyleVariables["color-error"]}
                >
                  ราคารับซื้อมาตรฐาน{" "}
                  <NumericFormat
                    value={DepositStore.formRenew.purchasePriceStandard}
                    displayType="text"
                    thousandSeparator
                  />
                </Typography>
              </Box>
            }
            status={DepositStore.formRenew.purchasePriceCurrentStatus}
            handleOnChange={(value: number | string) => {
              onPurchasePriceChange(Number(value || 0));
            }}
            handleOnIncrease={(value: number | string) => {
              onPurchasePriceChange(Number(value || 0));
            }}
            handleOnDecrease={(value: number | string) => {
              onPurchasePriceChange(Number(value || 0));
            }}
          />
        ) : (
          <NumberInput
            name="purchaseTotalPay"
            label="ราคารับซื้อ"
            placeholder="กรอกราคารับซื้อ"
            value={`${DepositStore.form.totalPay || ""}`}
            isError={DepositStore.formIsInvalid.totalPay}
            handleOnChange={(value: number) =>
              onPurchasePriceChange(Number(value || 0))
            }
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput
          name="depositTotalPay"
          label="ให้เงินลูกค้า"
          placeholder="กรอกอัตโนมัติ"
          value={`${DepositStore.form.netPay || 0}`}
          isError={Number(DepositStore.form.netPay) < 0}
          inputPropsClassName="input-highlight"
          isDisabled={true}
          handleOnChange={(value: number) => {}}
        />
      </Grid>
    </Grid>
  );
});

export default Purchase;
