import Axios from "../plugins/Axios";
import {
  CreateNew as CreateNewDepositModel,
  CreateToInterest as CreateToInterestDepositModel,
  CancelDeposit as CancelDepositModel,
} from "../models/Deposit";

const getLatestDeposits = async () => {
  try {
    const { data } = await Axios.get(`/deposits/latest`);
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: [],
    };
  }
};

const getLatestDepositsReturned = async () => {
  try {
    const { data } = await Axios.get(`/deposits/returned/latest`);
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: [],
    };
  }
};

const getLatestRefInvoiceNo = async () => {
  try {
    const { data } = await Axios.get("/deposits/latest-no");
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const getDepositByBillNo = async (query: any) => {
  try {
    const { data } = await Axios.get(`/deposits/search`, {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const getDepositById = async (id: string) => {
  try {
    const { data } = await Axios.get(`/deposits/${id}`);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const createDepositNew = async (body: CreateNewDepositModel) => {
  try {
    const { data } = await Axios.post("/deposits/new", body);
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const createDepositRenewToInterest = async (
  body: CreateToInterestDepositModel
) => {
  try {
    const { data } = await Axios.post("/deposits/to-interest", body);
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const createDepositRenewReduce = async (body: CreateToInterestDepositModel) => {
  try {
    const { data } = await Axios.post("/deposits/reduce", body);
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const createDepositRenewIncrease = async (
  body: CreateToInterestDepositModel
) => {
  try {
    const { data } = await Axios.post("/deposits/increase", body);
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const createDepositRenewPurchase = async (
  body: CreateToInterestDepositModel
) => {
  try {
    const { data } = await Axios.post("/deposits/purchase", body);
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const createDepositReturn = async (body: CreateToInterestDepositModel) => {
  try {
    const { data } = await Axios.post("/deposits/return", body);
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const submitDepositById = async (id: string) => {
  try {
    const { data } = await Axios.post(
      `deposits/${id}/submit`,
      {},
      {
        responseType: "blob",
      }
    );
    return {
      success: true,
      data: data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const cancelDeposit = async (id: string, body: CancelDepositModel) => {
  try {
    await Axios.post(`/deposits/${id}/cancel`, body);
    return {
      success: true,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
    };
  }
};

export {
  getLatestDeposits as GetLatestDeposits,
  getLatestDepositsReturned as GetLatestDepositsReturned,
  getDepositByBillNo as GetDepositByBillNo,
  getDepositById as GetDepositById,
  getLatestRefInvoiceNo as GetLatestRefInvoiceNo,
  createDepositNew as CreateDepositNew,
  createDepositRenewToInterest as CreateDepositRenewToInterest,
  createDepositRenewReduce as CreateDepositRenewReduce,
  createDepositRenewIncrease as CreateDepositRenewIncrease,
  createDepositRenewPurchase as CreateDepositRenewPurchase,
  createDepositReturn as CreateDepositReturn,
  submitDepositById as SubmitDepositById,
  cancelDeposit as CancelDeposit,
};
