import React from "react";
import {
  InputAdornment,
  Input,
  IconButton,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { Cancel as CancelIcon, Error as ErrorIcon } from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";

interface Props {
  name: string;
  placeholder: string;
  value: string;
  type?: string;
  label?: string;
  errorMessage?: string;
  isShowErrorMessage?: boolean;
  isError?: boolean;
  isDisabled?: boolean;
  inputMode?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search";
  handleOnChange: (value: any) => void;
  handleOnBlur?: () => void;
  handleOnClear?: () => void;
  handleOnKeyUp?: (key: string) => void;
}

const TextField = (props: Props) => {
  const onClearTextInput = () => {
    props.handleOnChange("");
    props.handleOnClear && props.handleOnClear();
  };
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleOnChange(event.target?.value);
  };
  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    props.handleOnKeyUp && props.handleOnKeyUp(event.key);
  };

  return (
    <Box>
      {props.label && (
        <Typography
          variant="body1"
          fontWeight={"bold"}
          color={StyleVariables["color-header"]}
          marginBottom={1}
        >
          {props.label}
        </Typography>
      )}
      <Input
        name={props.name}
        type={props.type || "text"}
        placeholder={props.placeholder}
        value={props.value}
        disabled={props.isDisabled}
        error={props.isError}
        inputMode={props.inputMode}
        onChange={onInputChange}
        onBlur={props.handleOnBlur}
        onKeyUp={onKeyUp}
        autoComplete="one-time-code"
        endAdornment={
          props.value &&
          !props.isDisabled && (
            <InputAdornment position="start">
              <IconButton
                aria-label="clear value"
                edge="end"
                sx={{
                  fontSize: 32,
                  color: StyleVariables["color-textfield-icon"],
                }}
                onClick={onClearTextInput}
              >
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          )
        }
      />
      {props.isError && props.isShowErrorMessage && (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          <ErrorIcon
            sx={{ fontSize: 32, color: StyleVariables["color-error-icon"] }}
          />
          <Typography
            variant="subtitle1"
            fontWeight={"medium"}
            color={StyleVariables["color-error"]}
          >
            {props.errorMessage}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export default TextField;
