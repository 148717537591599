import React, { useState } from "react";
import { Box, Button, Typography, Switch, Grid, Drawer } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { observer } from "mobx-react";
import { useStores } from "../stores";
import StyleVariables from "../assets/styles/Variable.module.scss";
import MainLayout from "../layouts/Main";
import SimpleAccordion from "../components/accordions/Simple";
import OldExchangeForm from "../components/forms/exchanges/Old";
import NewExchangeForm from "../components/forms/exchanges/New";
import PaymentForm from "../components/forms/Payment";
import CustomerForm from "../components/forms/Customer";
import CannotExchangeModal from "../components/modals/CannotExchange";
import InformationModal from "../components/modals/Information";
import ExchangeBill from "../components/bills/Exchange";
import BillModal from "../components/modals/Bill";
import LoadProgressing from "../components/LoadProgressing";
import AlertSnackbar from "../components/snackbars/Alert";
import ExchangeListTable from "../components/tables/ExchangesList";
import CancelBillModal from "../components/modals/CancelBill";
import { KeysType as KeysTypePaymentConstant } from "../constants/Payment";
import { ProductWeightType as ProductWeightTypeConstant } from "../constants/Gold";
import { ChargeRoundUp as ChargeRoundUpUtil } from "../utils/NumberRounding";
import {
  ScrollToTop as ScrollToTopUtil,
  ScrollToElementId as ScrollToElementIdUtil,
} from "../utils/ScrollToElement";
import { Create as CreateCustomerModel } from "../models/Customer";
import {
  Create as CreateExchangeModel,
  Exchange as ExchangeModel,
  CancelExchange as CancelExchangeModel,
} from "../models/Exchange";
import { CancelReasonForm as CancelReasonFormModel } from "../models/MasterData";
import { Create as CreateCustomerService } from "../services/Customer";
import { OnPrintPdf as OnPrintPdfService } from "../services/Storage";
import {
  CreateExchange as CreateExchangeService,
  GetLatestExchanges as GetLatestExchangesService,
  SubmitExchangeById as SubmitExchangeByIdService,
  GetExchangeById as GetExchangeByIdService,
  CancelExchange as CancelExchangeService,
} from "../services/Exchange";
import { Payment as PaymentModel } from "../models/Payment";

type Props = {};

const Exchange = observer((props: Props) => {
  const { MainStore, ExchangeStore, PaymentStore, CustomerStore } = useStores();
  const [isFailModalOpen, setIsFailModalOpen] = useState<boolean>(false);
  const [messageFailModal, setMessageFailModal] = useState<string>("");
  const [scrollToElementId, setScrollToElementId] = useState<string>("");
  const [
    isCanNotExchangeInformationModalOpen,
    setIsCanNotExchangeInformationModalOpen,
  ] = useState<boolean>(false);
  const [isBillModalOpen, setIsBillModalOpen] = useState<boolean>(false);
  const [isLoadProgressing, setIsLoadProgressing] = useState<boolean>(false);
  const [isOpenSuccessSnackbar, setIsOpenSuccessSnackbar] =
    useState<boolean>(false);
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false);
  const [isOpenList, setIsOpenList] = useState<boolean>(false);
  const [exchanges, setExchanges] = useState<any[]>([]);
  const [isCompleteBillModalOpen, setIsCompleteBillModalOpen] =
    useState<boolean>(false);
  const [exchange, setExchange] = useState<ExchangeModel | null>(null);
  const [payments, setPayments] = useState<PaymentModel[]>([]);
  const [isCancelBillModalOpen, setIsCancelBillModalOpen] =
    useState<boolean>(false);

  const onReset = () => {
    ExchangeStore.onClearStore(true);
    PaymentStore.onClearStore(0);
    CustomerStore.onClearStore();
    ScrollToTopUtil();
  };
  const onExchangeInputChange = (key: string, value: any) => {
    ExchangeStore.onClearFormIsInvalid();
    const formObj: any = { ...ExchangeStore.form };
    const formIsInvalidObj: any = { ...ExchangeStore.formIsInvalid };
    formObj[key] = value;
    switch (key) {
      case "paymentType":
        if (value !== KeysTypePaymentConstant.allCreditCard) {
          formObj.cardPay = 0;
          formObj.percentChargeCard = 0;
          formObj.chargeCard = 0;
          formObj.netPay = formObj.totalPay;
        } else {
          formObj.cardPay = formObj.totalPay;
          formObj.percentChargeCard = PaymentStore.percentCharge;
          formObj.chargeCard = ChargeRoundUpUtil(
            formObj.totalPay * (PaymentStore.percentCharge / 100)
          );
          formObj.netPay = formObj.totalPay + formObj.chargeCard;
        }
        PaymentStore.onClearStore(formObj.totalPay);
        PaymentStore.onTypeChange(value, formObj.netPay);
        break;

      case "cardPay":
        formObj.cardPay = value;
        formObj.percentChargeCard = PaymentStore.percentCharge;
        formObj.chargeCard = ChargeRoundUpUtil(
          value * (PaymentStore.percentCharge / 100)
        );
        formObj.netPay = formObj.totalPay + formObj.chargeCard;
        formIsInvalidObj.cardPay =
          formObj.paymentType === KeysTypePaymentConstant.mix &&
          value >= formObj.totalPay;
        PaymentStore.onTypeChange(formObj.paymentType, formObj.netPay);
        break;
      default:
        break;
    }
    ExchangeStore.onSetForm({ ...formObj });
    ExchangeStore.onSetFormIsInvalid({ ...formIsInvalidObj });
  };
  const onValidateForm = () => {
    const isFormOldIsValid = ExchangeStore.onValidateFormOld();
    const isFormNewIsValid = ExchangeStore.onValidateFormNew();
    const canExchange = ExchangeStore.onCheckConditionForExchange();
    const isValidFormPayment = PaymentStore.onValidateForm(
      ExchangeStore.form.paymentType,
      ExchangeStore.form.cardPay || 0,
      ExchangeStore.form.chargeCard || 0,
      ExchangeStore.form.netPay
    );
    if (!isFormOldIsValid) {
      setScrollToElementId("exchange-gold-old-information-content");
      setMessageFailModal(
        "กรุณาตรวจสอบข้อมูลทองเก่าให้ถูกต้อง และลองทำรายการใหม่อีกครั้ง"
      );
      setIsFailModalOpen(true);
      return;
    }
    if (!canExchange && ExchangeStore.form.netPay) {
      setIsCanNotExchangeInformationModalOpen(true);
      return;
    }
    if (!isFormNewIsValid) {
      setScrollToElementId("exchange-gold-new-information-content");
      setMessageFailModal(
        ExchangeStore.form.newTotalLabour >= 0
          ? "กรุณาตรวจสอบข้อมูลทองใหม่ให้ถูกต้อง และลองทำรายการใหม่อีกครั้ง"
          : "ค่ากำเหน็จห้ามต่ำกว่า 0 กรุณาตรวจสอบข้อมูลทองใหม่ให้ถูกต้อง และลองทำรายการใหม่อีกครั้ง"
      );
      setIsFailModalOpen(true);
      return;
    }
    if (!isValidFormPayment) {
      setScrollToElementId("payment-information-content");
      setMessageFailModal(
        "กรุณาตรวจสอบข้อมูลการชำระเงินให้ถูกต้อง และลองทำรายการใหม่อีกครั้ง"
      );
      setIsFailModalOpen(true);
      return;
    }
    setIsBillModalOpen(true);
  };
  const onCloseFailModal = () => {
    setIsFailModalOpen(false);
    setMessageFailModal("");
    scrollToElementId && ScrollToElementIdUtil(scrollToElementId);
    setScrollToElementId("");
  };
  const onSubmit = async () => {
    setIsBillModalOpen(false);
    setIsLoadProgressing(true);
    const customerBody: CreateCustomerModel | null = CustomerStore.getBody();
    const exchangeBody = ExchangeStore.getBody();
    const body: CreateExchangeModel = {
      ...exchangeBody,
      payment: {
        ...exchangeBody.payment,
        channel: PaymentStore.getBodyChannel(),
      },
    };
    let customerId: string = "";
    // Create Customer
    if (customerBody) {
      const responseCustomer = await onCreateCustomer(customerBody);
      if (!responseCustomer) return;
      customerId = responseCustomer?.id;
    }
    // /.Create Customer

    // Create Exchange
    const responseExchange = await onCreateExchange({
      ...body,
      ...(customerId && { customerId }),
    });
    if (!responseExchange) return;
    // /.Create Exchange

    await onSubmitTrade(responseExchange.id);

    ExchangeStore.onSetIsShowAll(true);
    onReset();
    setIsLoadProgressing(false);
    setIsOpenSuccessSnackbar(true);
  };
  const onCreateCustomer = async (body: CreateCustomerModel) => {
    const response = await CreateCustomerService(body);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message || "สร้างข้อมูลลูกค้าไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
    return response.data;
  };
  const onCreateExchange = async (body: CreateExchangeModel) => {
    const response = await CreateExchangeService(body);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message ||
          "สร้างข้อมูลการเปลี่ยนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
    return response.data;
  };
  const onSubmitTrade = async (id: string) => {
    const response = await SubmitExchangeByIdService(id);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message ||
          "เกิดข้อผิดพลาดในการสร้างบิล กรุณาพิมพ์บิลใหม่ในรายการประวัติการเปลี่ยน"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
  };
  const getExchanges = async () => {
    setIsLoadingList(true);
    const response = await GetLatestExchangesService();
    response.success && setExchanges(response.data);
    setIsLoadingList(false);
  };
  const onOpenList = () => {
    getExchanges();
    setIsOpenList(true);
  };
  const onCloseList = () => {
    setIsOpenList(false);
    setExchanges([]);
  };
  const onOpenCompleteBillModal = async (id: string) => {
    setIsLoadProgressing(true);
    const response = await GetExchangeByIdService(id);
    setIsLoadProgressing(false);
    const { payments, ...data } = response.data;
    if (response.success) {
      setIsOpenList(false);
      setExchange({
        type: "",
        currentStatus: "good",
        standardExcellent: 0,
        standardGood: 0,
        standardFair: 0,
        standardPoor: 0,
        ...data,
        oldTotalPrice: data.exchangeOldHasItems[0]?.price,
      });
      setPayments([...payments]);
      setIsCompleteBillModalOpen(true);
    }
  };
  const onCloseCompleteBillModal = () => {
    setIsCompleteBillModalOpen(false);
    setExchange(null);
    setPayments([]);
  };
  const onPrintBillPdfHistory = async (id: string) => {
    setIsCompleteBillModalOpen(false);
    setIsLoadProgressing(true);
    const response = await OnPrintPdfService(id);
    setIsLoadProgressing(false);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message || "เกิดข้อผิดพลาดในการพิมพ์ข้อมูลบิลการเปลี่ยน"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      return false;
    }
  };
  const onOpenCancelBillModal = (exchange: ExchangeModel) => {
    onCloseList();
    setExchange(exchange);
    setIsCancelBillModalOpen(true);
  };
  const onCloseCancelBillModal = () => {
    setIsCancelBillModalOpen(false);
    setExchange(null);
  };
  const onCancelExchange = async (form: CancelReasonFormModel) => {
    const body: CancelExchangeModel = {
      cancelReasonId: form.reason.id,
      cancelMisc: form.misc,
    };
    if (exchange?.id) {
      const response = await CancelExchangeService(exchange?.id, body);
      onCloseCancelBillModal();
      if (!response?.success) {
        setMessageFailModal(
          `${
            response.message ||
            "ยกเลิกรายการเปลี่ยนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
          } (${response.code})`
        );
        setIsFailModalOpen(true);
        return;
      }
      onOpenList();
    }
  };

  return (
    <MainLayout>
      <>
        <Box
          display={{
            xs: MainStore.isOpenSidebar ? "block" : "flex",
            md: "flex",
          }}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={2}
          textAlign={"center"}
        >
          <Typography
            variant="h6"
            fontWeight={"bold"}
            color={StyleVariables["color-header"]}
          >
            การเปลี่ยน
          </Typography>
          <Box>
            <Button variant="contained-change" onClick={onOpenList}>
              ดูประวัติการเปลี่ยน
            </Button>
          </Box>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={2}
          marginY={4}
        >
          <Switch
            name="showAllInformation"
            checked={ExchangeStore.isShowAll}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              ExchangeStore.onSetIsShowAll(event.target.checked)
            }
          />
          <Typography
            variant="h6"
            fontWeight={"bold"}
            color={StyleVariables["color-header"]}
          >
            แสดงข้อมูลทั้งหมด
          </Typography>
        </Box>
        <Box>
          {/* Form Old Product */}
          <SimpleAccordion
            id="exchange-gold-old-information"
            title="ข้อมูลทองเก่า"
            defaultExpanded={true}
          >
            <OldExchangeForm />
          </SimpleAccordion>
          {/* Form Old Product */}

          {/* Form New Product */}
          <SimpleAccordion
            id="exchange-gold-new-information"
            title="ข้อมูลทองใหม่"
            defaultExpanded={true}
          >
            <NewExchangeForm />
          </SimpleAccordion>
          {/* Form New Product */}

          {/* Form Payment */}
          <SimpleAccordion
            id="payment-information"
            title="ข้อมูลการชำระเงิน"
            defaultExpanded={true}
          >
            <PaymentForm
              type={ExchangeStore.form.paymentType}
              netPay={ExchangeStore.form.netPay}
              cardPay={ExchangeStore.form.cardPay}
              chargeCard={ExchangeStore.form.chargeCard}
              cardPayIsError={ExchangeStore.formIsInvalid.cardPay}
              onTypeChange={(value: string) => {
                onExchangeInputChange("paymentType", value);
              }}
              onCardPayChange={(value: number) => {
                onExchangeInputChange("cardPay", value);
              }}
            />
          </SimpleAccordion>
          {/* /.Form Payment */}

          {/* Form Customer */}
          <SimpleAccordion
            id="customer-information"
            title="ข้อมูลลูกค้า/ภาพสินค้า"
          >
            <CustomerForm type="EXCHANGE" />
          </SimpleAccordion>
          {/* /.Form Customer */}
        </Box>

        {/* Button */}
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          marginTop={3}
          flexDirection={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} md={6} lg={4}>
            <Button variant="outlined" fullWidth onClick={onReset}>
              เริ่มใหม่
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Button
              id="submit"
              variant="contained-success"
              fullWidth
              onClick={onValidateForm}
            >
              ทำรายการ
            </Button>
          </Grid>
        </Grid>
        {/* /.Button */}

        {/*  Table List */}
        <Drawer anchor={"bottom"} open={isOpenList} onClose={onCloseList}>
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={1}
              paddingY={1}
            >
              <Typography
                variant="h6"
                fontWeight={"bold"}
                color={StyleVariables["color-header"]}
              >
                ประวัติการเปลี่ยนล่าสุด
              </Typography>
              <CloseIcon
                sx={{
                  fontSize: 32,
                  color: StyleVariables["color-mainIcon"],
                  cursor: "pointer",
                }}
                onClick={onCloseList}
              />
            </Box>
            <Box marginTop={2}>
              <ExchangeListTable
                list={exchanges}
                isLoading={isLoadingList}
                noDataMessage="ไม่มีข้อมูลการเปลี่ยนวันนี้"
                onClickBill={(id: string) => onOpenCompleteBillModal(id)}
                onClickCancel={(exchange: ExchangeModel) =>
                  onOpenCancelBillModal(exchange)
                }
              />
            </Box>
          </>
        </Drawer>
        {/* /.Table List */}

        {/* Modal Cannot Exchange Information */}
        <CannotExchangeModal
          isOpen={isCanNotExchangeInformationModalOpen}
          currentGoldPurchasePrice={MainStore.goldPurchaseNow}
          percentGoldPurchase={
            ExchangeStore.form.exchangeOldHasItems[0].productPercentage
          }
          weightPurchase={ExchangeStore.form.oldTotalWeight}
          currentGoldSellPrice={MainStore.goldSellNow}
          categoryCodeSell={
            ExchangeStore.form.exchangeNewHasItems[0].categoryCode
          }
          percentGoldSell={
            ExchangeStore.form.exchangeNewHasItems[0].productPercentage
          }
          weightSell={
            ExchangeStore.form.exchangeNewHasItems[0].productType ===
            ProductWeightTypeConstant.equalWeight
              ? ExchangeStore.form.exchangeNewHasItems[0].weight
              : ExchangeStore.form.newTotalWeight
          }
          labourStandardSell={
            ExchangeStore.form.exchangeNewHasItems[0].productType ===
            ProductWeightTypeConstant.equalWeight
              ? ExchangeStore.form.exchangeNewHasItems[0].labourStandard
              : ExchangeStore.form.newTotalLabourStandard
          }
          quantitySell={
            ExchangeStore.form.exchangeNewHasItems[0].productType ===
            ProductWeightTypeConstant.equalWeight
              ? ExchangeStore.form.newTotalQuantity
              : 1
          }
          onClose={() => setIsCanNotExchangeInformationModalOpen(false)}
        />
        {/* /.Modal Cannot Exchange Information */}

        {/* Error Modal */}
        <InformationModal
          isOpen={isFailModalOpen}
          title="ทำรายการไม่สำเร็จ"
          onClose={onCloseFailModal}
        >
          <>
            <Box textAlign={"center"}>
              <Typography variant="body2" fontWeight={"regular"}>
                {messageFailModal}
              </Typography>
            </Box>
            <Button
              variant="contained"
              fullWidth
              sx={{ marginTop: 5 }}
              onClick={onCloseFailModal}
            >
              ตกลง
            </Button>
          </>
        </InformationModal>
        {/* /.Error Modal */}

        {/* Preview Bill Modal */}
        <BillModal
          isOpen={isBillModalOpen}
          title={"เปลี่ยน"}
          onSaveAndPrint={onSubmit}
          onClose={() => {
            setIsBillModalOpen(false);
          }}
        >
          <ExchangeBill
            exchange={ExchangeStore.form}
            payments={PaymentStore.list}
          />
        </BillModal>
        {/* Preview Bill Modal */}

        {/* Preview Complete Bill Modal */}
        <BillModal
          isOpen={isCompleteBillModalOpen}
          title={"เปลี่ยน (สำเนา)"}
          isComplete={true}
          onPrint={() => onPrintBillPdfHistory(exchange?.receiptCopy?.id || "")}
          onClose={onCloseCompleteBillModal}
        >
          <ExchangeBill exchange={exchange} payments={payments} />
        </BillModal>
        {/* Preview Complete Bill Modal */}

        {/* Cancel Bill Modal */}
        <CancelBillModal
          isOpen={isCancelBillModalOpen}
          onClose={onCloseCancelBillModal}
          onSubmit={(form: CancelReasonFormModel) => {
            onCancelExchange(form);
          }}
        />
        {/* /.Cancel Bill Modal */}

        <LoadProgressing isOpen={isLoadProgressing} />

        <AlertSnackbar
          type="success"
          isOpen={isOpenSuccessSnackbar}
          description="สร้างรายการเปลี่ยนสำเร็จ"
          onClose={() => setIsOpenSuccessSnackbar(false)}
        />
      </>
    </MainLayout>
  );
});

export default Exchange;
