import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { Type as TypeGoldConstant } from "../../../constants/Gold";
import {
  Category as CategoryModel,
  Product as ProductModel,
} from "../../../models/Product";
import {
  Exchange as ExchangeModel,
  ExchangeHasItemIsInvalid as ExchangeHasItemIsInvalidModel,
} from "../../../models/Exchange";
import {
  GetCategories as GetCategoriesService,
  GetProductsByCategory as GetProductsByCategoryService,
  GetProductsByCode as GetProductByCodeService,
} from "../../../services/MasterData";
import { ProductWeightType as ProductWeightTypeConstant } from "../../../constants/Gold";
import SelectionDropdown from "../../dropdowns/Selection";
import NewEqualWeightExchangeForm from "./NewEqualWeight";
import NewNotEqualWeightExchangeForm from "./NewNotEqualWeight";

type Props = {};

const New = observer((props: Props) => {
  const { ExchangeStore, UserStore, MainStore, PaymentStore } = useStores();
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [products, setProducts] = useState<ProductModel[]>([]);

  useEffect(() => {
    getCategories();
    return () => {
      ExchangeStore.onClearStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ExchangeStore]);

  const getCategories = async () => {
    const response = await GetCategoriesService(TypeGoldConstant.exchangeNew);
    if (response.success) {
      setCategories(response.data);
      response.data?.length === 1 && onCategorySelected(response.data[0]);
    }
  };
  const getProductsByCategory = async (code: string) => {
    setProducts([]);
    const response = await GetProductsByCategoryService(
      code,
      TypeGoldConstant.exchangeNew
    );
    setProducts(response.data);
    response.data?.length === 1 && onProductSelected(response.data[0]);
  };

  const onCategorySelected = (category: CategoryModel) => {
    PaymentStore.onClearStore(0);
    ExchangeStore.onClearExchangeHasItemsNew();
    const form: ExchangeModel = JSON.parse(JSON.stringify(ExchangeStore.form));
    const formHasItemsIsInvalidNew: ExchangeHasItemIsInvalidModel = JSON.parse(
      JSON.stringify({
        ...ExchangeStore.formHasItemsIsInvalidNew[0],
      })
    );
    form.userFirstName = UserStore.user.employeeFirstName;
    form.userLastName = UserStore.user.employeeLastName;
    form.branchName = UserStore.user.companyName;
    form.branchCode = UserStore.user.companyCode;
    form.goldSellPrice = MainStore.goldSellNow;
    form.goldPurchasePrice = MainStore.goldPurchaseNow;
    form.goldPriceDateTime = MainStore.goldDateTime;
    form.exchangeNewHasItems[0].category = category;
    form.exchangeNewHasItems[0].categoryCode = category.code;
    form.exchangeNewHasItems[0].categoryName = category.name;
    formHasItemsIsInvalidNew.categoryCode = false;
    ExchangeStore.onSetForm({ ...form });
    ExchangeStore.onSetFormHasItemIsInvalidNew(
      { ...formHasItemsIsInvalidNew },
      0
    );
    getProductsByCategory(category.code);
  };
  const onProductSelected = async (product: ProductModel) => {
    const response = await GetProductByCodeService(product.code);
    const formHasItemsNew = JSON.parse(
      JSON.stringify(ExchangeStore.form.exchangeNewHasItems[0])
    );
    ExchangeStore.onClearPayment(0);
    ExchangeStore.onClearExchangeHasItemsNew();
    ExchangeStore.onSetProducts(response.data);
    ExchangeStore.onSetForm({
      ...ExchangeStore.form,
      totalPay: 0,
      exchangeNewHasItems: [
        {
          ...ExchangeStore.form.exchangeNewHasItems[0],
          category: formHasItemsNew.category,
          categoryCode: formHasItemsNew.categoryCode,
          categoryName: formHasItemsNew.categoryName,
        },
      ],
    });
    if (
      !product.percentageGold &&
      !product.labour &&
      !product.labourByWeight?.length
    ) {
      ExchangeStore.onSetExchangeProductManual(
        product,
        ExchangeStore.form.goldSellPrice,
        ExchangeStore.form.goldPurchasePrice
      );
    } else {
      product.weight
        ? ExchangeStore.onSetExchangeProductEqualWeight(
            product,
            ExchangeStore.form.goldSellPrice,
            ExchangeStore.form.goldPurchasePrice
          )
        : ExchangeStore.onSetExchangeProductNotEqualWeight(
            product,
            ExchangeStore.form.goldSellPrice,
            ExchangeStore.form.goldPurchasePrice
          );
    }
    PaymentStore.onClearStore(ExchangeStore.form.netPay);
  };

  return (
    <Box>
      <Grid container spacing={{ xs: 3, md: 2 }}>
        {/* Category */}
        <Grid item xs={12} md={6}>
          <SelectionDropdown
            name="newCategory"
            label="ประเภทสินค้า"
            options={categories}
            selected={ExchangeStore.form.exchangeNewHasItems[0].category}
            labelKey="name"
            valueKey="code"
            imageKey="img"
            placeholder="เลือกประเภทสินค้า"
            isShowImage={true}
            isError={ExchangeStore.formHasItemsIsInvalidNew[0].categoryCode}
            isDisabled={!ExchangeStore.form.oldTotalPrice}
            onSelected={(selected: CategoryModel) => {
              onCategorySelected(selected);
            }}
          />
        </Grid>
        {/* /.Category */}

        {/* Product */}
        <Grid item xs={12} md={6}>
          <SelectionDropdown
            name="newProduct"
            label="ชื่อสินค้า"
            options={products}
            selected={ExchangeStore.form.exchangeNewHasItems[0].product}
            labelKey="name"
            valueKey="code"
            imageKey="img"
            placeholder="เลือกชื่อสินค้า"
            isDisabled={!ExchangeStore.form.exchangeNewHasItems[0].categoryCode}
            isShowImage={true}
            isError={ExchangeStore.formHasItemsIsInvalidNew[0].productCode}
            onSelected={(selected: ProductModel) => {
              onProductSelected(selected);
            }}
          />
        </Grid>
        {/* /.Product */}
      </Grid>
      {ExchangeStore.form.exchangeNewHasItems[0].productType ? (
        ExchangeStore.form.exchangeNewHasItems[0].productType ===
        ProductWeightTypeConstant.equalWeight ? (
          <NewEqualWeightExchangeForm />
        ) : ExchangeStore.form.exchangeNewHasItems[0].productType ===
          ProductWeightTypeConstant.notEqualWeight ? (
          <NewNotEqualWeightExchangeForm />
        ) : null
      ) : null}
    </Box>
  );
});

export default New;
