import StyleVariables from "../assets/styles/Variable.module.scss";
import { Typography } from "./Typography";
import StyleInput from "./Input";
import StyleButton from "./Button";
import StyleModal from "./Modal";
import StyleAccordion from "./Accordion";
import StyleSwitch from "./Switch";
import StyleDrawer from "./Drawer";

const Theme: any = {
  spacing: Number(StyleVariables.spacing),
  palette: {
    primary: {
      main: StyleVariables["color-primary"],
      dark: StyleVariables["color-primary"],
      contrastText: StyleVariables["color-white"],
    },
    secondary: {
      main: StyleVariables["color-secondary"],
      contrastText: StyleVariables["color-white"],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 658,
      lg: 1200,
      xl: 1440,
    },
  },
  typography: { ...Typography },
  components: {
    ...StyleInput,
    ...StyleButton,
    ...StyleModal,
    ...StyleAccordion,
    ...StyleSwitch,
    ...StyleDrawer,
  },
};

export default Theme;
