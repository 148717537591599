import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import SelectionDropdown from "../../dropdowns/Selection";
import EqualWeightSellForm from "./EqualWeight";
import NotEqualWeightSellForm from "./NotEqualWeight";
import ManualSellForm from "./Manual";
import {
  GetCategories as GetCategoriesService,
  GetProductsByCategory as GetProductsByCategoryService,
} from "../../../services/MasterData";
import { Trade as TradeModel } from "../../../models/Trade";
import {
  Category as CategoryModel,
  Product as ProductModel,
} from "../../../models/Product";
import {
  Type as TypeGoldConstant,
  ProductWeightType as ProductWeightTypeConstant,
  RefInvoice as RefInvoiceConstant,
} from "../../../constants/Gold";

type Props = {};

const Sell = observer((props: Props) => {
  const { TradeStore, MainStore, PaymentStore, UserStore } = useStores();
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [products, setProducts] = useState<ProductModel[]>([]);

  useEffect(() => {
    getCategories();
    return () => {
      TradeStore.onClearStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TradeStore]);

  useEffect(() => {
    PaymentStore.onClearStore(TradeStore.form.totalPay);
  }, [TradeStore.form.totalPay, PaymentStore]);

  const getCategories = async () => {
    const response = await GetCategoriesService(TypeGoldConstant.sell);
    if (response.success) {
      setCategories(response.data);
      response.data?.length === 1 && onCategorySelected(response.data[0]);
    }
  };
  const getProductsByCategory = async (code: string) => {
    setProducts([]);
    const response = await GetProductsByCategoryService(
      code,
      TypeGoldConstant.sell
    );
    setProducts(response.data);
    response.data?.length === 1 && onProductSelected(response.data[0]);
  };
  const onCategorySelected = (category: CategoryModel) => {
    TradeStore.onClearStore();
    const form: TradeModel = JSON.parse(JSON.stringify(TradeStore.form));
    form.userFirstName = UserStore.user.employeeFirstName;
    form.userLastName = UserStore.user.employeeLastName;
    form.branchName = UserStore.user.companyName;
    form.branchCode = UserStore.user.companyCode;
    form.ref = RefInvoiceConstant.sell;
    form.categoryCode = category.code;
    form.categoryName = category.name;
    form.category = category;
    form.goldSellPrice = MainStore.goldSellNow;
    form.goldPurchasePrice = MainStore.goldPurchaseNow;
    form.goldPriceDateTime = MainStore.goldDateTime;
    TradeStore.onSetForm({ ...form });
    PaymentStore.onClearStore(TradeStore.form.totalPay);
    getProductsByCategory(category.code);
  };
  const onProductSelected = (product: ProductModel) => {
    PaymentStore.onClearStore(TradeStore.form.totalPay);
    TradeStore.onClearPayment(0);
    if (
      !product.percentageGold &&
      !product.labour &&
      !product.labourByWeight?.length
    ) {
      TradeStore.onSetTradeByProductManual(
        product,
        TradeStore.form.goldSellPrice,
        TradeStore.form.goldPurchasePrice
      );
    } else {
      product.weight
        ? TradeStore.onSetTradeByProductEqualWeight(
            product,
            TradeStore.form.goldSellPrice,
            TradeStore.form.goldPurchasePrice
          )
        : TradeStore.onSetTradeByProductNotEqualWeight(
            product,
            TradeStore.form.goldSellPrice,
            TradeStore.form.goldPurchasePrice
          );
    }
  };

  return (
    <Box>
      <Grid container spacing={{ xs: 3, md: 2 }}>
        <Grid item xs={12} md={6}>
          <SelectionDropdown
            name="category"
            label="ประเภทสินค้า"
            options={categories}
            selected={TradeStore.form.category}
            labelKey="name"
            valueKey="code"
            imageKey="img"
            placeholder="เลือกประเภทสินค้า"
            isShowImage={true}
            isError={TradeStore.formIsInvalid.categoryCode}
            onSelected={(selected: CategoryModel) => {
              onCategorySelected(selected);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectionDropdown
            name="product"
            label="ชื่อสินค้า"
            options={products}
            selected={TradeStore.form.product}
            labelKey="name"
            valueKey="code"
            imageKey="img"
            placeholder="เลือกชื่อสินค้า"
            isDisabled={!TradeStore.form.categoryCode}
            isShowImage={true}
            isError={TradeStore.formIsInvalid.productCode}
            onSelected={(selected: ProductModel) => {
              onProductSelected(selected);
            }}
          />
        </Grid>
      </Grid>
      {TradeStore.form.productType ? (
        TradeStore.form.productType ===
        ProductWeightTypeConstant.equalWeight ? (
          <EqualWeightSellForm />
        ) : TradeStore.form.productType ===
          ProductWeightTypeConstant.notEqualWeight ? (
          <NotEqualWeightSellForm />
        ) : (
          <ManualSellForm />
        )
      ) : null}
    </Box>
  );
});

export default Sell;
