import React, { useState } from "react";
import {
  InputAdornment,
  Input,
  IconButton,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import {
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";

interface Props {
  name: string;
  placeholder: string;
  value: string;
  label?: string;
  isRequire?: boolean;
  errorMessage?: string;
  isShowErrorMessage?: boolean;
  isError?: boolean;
  isDisabled?: boolean;
  autoFocus?: boolean;
  handleOnChange: (value: string) => void;
}

const Password = (props: Props) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleClickShowPassword = () => setIsShowPassword((show) => !show);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleOnChange(event.target?.value);
  };

  return (
    <Box>
      {props.label && (
        <Box marginBottom={1}>
          <Typography
            variant="body1"
            fontWeight={"bold"}
            color={StyleVariables["color-header"]}
            display={"inline"}
          >
            {props.label}
          </Typography>
          {props.isRequire ? (
            <Typography
              variant="body1"
              fontWeight={"bold"}
              color={StyleVariables["color-primary"]}
              display={"inline"}
            >
              {" *"}
            </Typography>
          ) : null}
        </Box>
      )}
      <Input
        name={props.name}
        type={isShowPassword ? "text" : "password"}
        placeholder={props.placeholder}
        value={props.value}
        disabled={props.isDisabled}
        autoComplete="one-time-code"
        error={props.isError}
        autoFocus={props.autoFocus}
        onChange={onInputChange}
        endAdornment={
          <InputAdornment position="start">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onClick={handleClickShowPassword}
              sx={{
                fontSize: 32,
                color: StyleVariables["color-textfield-icon"],
              }}
            >
              {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        }
      />
      {props.isError && props.isShowErrorMessage && (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          <ErrorIcon
            sx={{ fontSize: 32, color: StyleVariables["color-error-icon"] }}
          />
          <Typography
            variant="subtitle1"
            fontWeight={"medium"}
            color={StyleVariables["color-error"]}
          >
            {props.errorMessage}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};
export default Password;
