import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Popover } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import LogoImage from "../../assets/images/Logo.png";
import {
  MenuRounded as MenuRoundedIcon,
  MenuOpenRounded as MenuOpenRoundedIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import { NumericFormat } from "react-number-format";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { DateTimeBuddhistEra as DateTimeBuddhistEraUtil } from "../../utils/DateFormat";

type Props = {};

const Header = observer((props: Props) => {
  const { MainStore, UserStore } = useStores();
  const [goldPriceInterval, setGoldPriceInterval] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClickProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    MainStore.getGoldPrice();
    const getGoldPriceInterval = setInterval(
      MainStore.getGoldPrice,
      MainStore.goldFetchTimeEvery
    );
    setGoldPriceInterval(getGoldPriceInterval);

    return () => {
      clearInterval(goldPriceInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    UserStore.handleLogout();
  };

  return (
    <Box
      bgcolor={StyleVariables["color-white"]}
      boxShadow={StyleVariables["shadow-xs"]}
      padding={2}
      zIndex={99}
      position={"sticky"}
      top={0}
      maxWidth={"100%"}
    >
      <Box
        display={{ xs: "block", md: "flex" }}
        alignItems={"center"}
        height={{ md: 56 }}
      >
        <Box display={"flex"} alignItems={"center"}>
          {MainStore.isOpenSidebar ? (
            <MenuOpenRoundedIcon
              sx={{
                color: StyleVariables["color-menu-icon"],
                fontSize: 40,
                marginRight: 2,
                cursor: "pointer",
              }}
              onClick={() => MainStore.handleSidebar(false)}
            />
          ) : (
            <MenuRoundedIcon
              sx={{
                color: StyleVariables["color-menu-icon"],
                fontSize: 40,
                marginRight: 2,
                cursor: "pointer",
              }}
              onClick={() => MainStore.handleSidebar(true)}
            />
          )}
          <img src={LogoImage} alt="logo" width={55} height={55} />
          <Box
            bgcolor={StyleVariables["color-red-light"]}
            padding={2}
            borderRadius={2}
            minWidth={{ md: 285 }}
            width={"100%"}
            marginLeft={2}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                variant="subtitle2"
                fontWeight={"bold"}
                color={StyleVariables["color-text"]}
              >
                ราคาทองแท่ง บาทละ
              </Typography>
              <Typography
                variant="subtitle2"
                fontWeight={"bold"}
                color={StyleVariables["color-primary"]}
                marginLeft={2}
              >
                <NumericFormat
                  value={MainStore.goldSellNow}
                  displayType="text"
                  thousandSeparator
                />{" "}
                -{" "}
                <NumericFormat
                  value={MainStore.goldPurchaseNow}
                  displayType="text"
                  thousandSeparator
                />
              </Typography>
            </Box>
            <Typography
              variant="body2"
              fontSize={12}
              lineHeight={"18px"}
              color={StyleVariables["color-text"]}
              textAlign={{ xs: "center", md: "left" }}
              marginTop={0.5}
            >
              อัปเดตล่าสุด{" "}
              {MainStore.goldLatestCheck &&
                DateTimeBuddhistEraUtil(MainStore.goldLatestCheck)}{" "}
              น.
            </Typography>
          </Box>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          marginLeft={"auto"}
          marginTop={{ xs: 2, md: 0 }}
          overflow={"hidden"}
        >
          <Box marginX={2} overflow={"hidden"}>
            <Typography
              variant="subtitle2"
              fontWeight={"medium"}
              color={StyleVariables["color-black"]}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {UserStore.user.employeeCode} {UserStore.user.employeeFirstName}{" "}
              {UserStore.user.employeeLastName[0]}.
            </Typography>
            <Typography
              variant="caption"
              fontWeight={"regular"}
              color={StyleVariables["color-placeholder"]}
              display={"block"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {UserStore.user.companyName}
            </Typography>
          </Box>
          <Button
            aria-describedby="profile-popover"
            onClick={handleClickProfile}
            sx={{
              backgroundColor: "transparent !important",
              padding: 0,
              minHeight: 0,
            }}
          >
            <AccountCircleIcon
              sx={{
                fontSize: 40,
                color: StyleVariables["color-secondary"],
                cursor: "pointer",
                marginLeft: 2,
              }}
            />
          </Button>
        </Box>
      </Box>
      <Popover
        id="profile-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseProfileMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            borderRadius: "10px",
            boxShadow: StyleVariables["shadow-sm"],
          },
        }}
      >
        <Typography
          variant="body2"
          fontWeight={"medium"}
          color={StyleVariables["color-primary"]}
          sx={{ cursor: "pointer" }}
          paddingY={2}
          paddingX={3}
          onClick={handleLogout}
        >
          ออกจากระบบ
        </Typography>
      </Popover>
    </Box>
  );
});

export default Header;
