import React, { useEffect } from "react";
import { Grid, Box } from "@mui/material";
import TextFieldInput from "../inputs/TextField";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import {
  InputNameFormat as InputNameFormatUtil,
  InputCitizenIdFormat as InputCitizenIdFormatUtil,
  InputNumberFormat as InputNumberFormatUtil,
} from "../../utils/Form";
import ImageUpload from "../uploads/Image";
import TextAreaInput from "../inputs/TextArea";

type Props = {
  type: "TRADE" | "DEPOSIT" | "EXCHANGE";
  isSoftDeleteImage?: boolean;
  isDisableEditing?: boolean;
};

const Customer = observer((props: Props) => {
  const { CustomerStore, TradeStore, DepositStore, ExchangeStore } =
    useStores();

  useEffect(() => {
    return () => {
      CustomerStore.onClearStore();
    };
  }, [CustomerStore]);

  const onInputCustomerChange = (key: string, value: any) => {
    const formObj: any = { ...CustomerStore.form };
    formObj[key] = value;
    CustomerStore.onSetForm({ ...formObj });
  };

  const onInputChange = (key: string, value: any) => {
    switch (props.type) {
      case "TRADE":
        const formTradeObj: any = { ...TradeStore.form };
        formTradeObj[key] = value;
        TradeStore.onSetForm({ ...formTradeObj });
        break;
      case "DEPOSIT":
        const formDepositObj: any = { ...DepositStore.form };
        formDepositObj[key] = value;
        DepositStore.onSetForm({ ...formDepositObj });
        break;
      case "EXCHANGE":
        const formExchangeObj: any = { ...ExchangeStore.form };
        formExchangeObj[key] = value;
        ExchangeStore.onSetForm({ ...formExchangeObj });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <TextFieldInput
            name="customerFirstName"
            label="ชื่อจริง"
            placeholder="กรอกชื่อจริง"
            value={CustomerStore.form.firstName}
            isDisabled={props.isDisableEditing}
            handleOnChange={(value: string) => {
              onInputCustomerChange("firstName", InputNameFormatUtil(value));
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldInput
            name="customerLastName"
            label="นามสกุล"
            placeholder="กรอกนามสกุล"
            value={CustomerStore.form.lastName}
            isDisabled={props.isDisableEditing}
            handleOnChange={(value: string) => {
              onInputCustomerChange("lastName", InputNameFormatUtil(value));
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldInput
            name="customerCitizenId"
            label="เลขบัตรประจำตัว"
            placeholder="กรอกเลขบัตรประจำตัว"
            value={CustomerStore.form.citizenId}
            isDisabled={props.isDisableEditing}
            handleOnChange={(value: string) => {
              onInputCustomerChange(
                "citizenId",
                InputCitizenIdFormatUtil(value)
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldInput
            name="customerPhoneNumber"
            label="เบอร์โทรศัพท์"
            placeholder="กรอกเบอร์โทรศัพท์"
            value={CustomerStore.form.phoneNumber}
            isDisabled={props.isDisableEditing}
            type="number"
            inputMode="numeric"
            handleOnChange={(value: string) => {
              onInputCustomerChange(
                "phoneNumber",
                InputNumberFormatUtil(value).slice(0, 10)
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextAreaInput
            name="customerAddress"
            label="ที่อยู่"
            placeholder="กรอกที่อยู่"
            value={CustomerStore.form.address}
            isDisabled={props.isDisableEditing}
            handleOnChange={(value: string) =>
              onInputCustomerChange("address", value)
            }
          />
        </Grid>
      </Grid>
      <Box marginTop={3}>
        <Grid container spacing={{ xs: 2, md: 3 }} alignItems={"flex-end"}>
          <Grid item xs={12} md={6} lg={4}>
            <ImageUpload
              label="อัปโหลดรูปภาพบัตรประจำตัว"
              storageId={CustomerStore.form.citizenCardImageId}
              isSoftDeleteImage={true}
              isDisabled={props.isDisableEditing}
              onFileChange={(storageId: string) =>
                onInputCustomerChange("citizenCardImageId", storageId)
              }
              onDelete={() => {
                onInputCustomerChange("citizenCardImageId", "");
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ImageUpload
              label="อัปโหลดรูปภาพสินค้า"
              storageId={
                props.type === "TRADE"
                  ? TradeStore.form.productImageId
                  : props.type === "DEPOSIT"
                  ? DepositStore.form.productImageId
                  : ExchangeStore.form.productImageId
              }
              isSoftDeleteImage={true}
              isDisabled={props.isDisableEditing}
              onFileChange={(storageId: string) => {
                onInputChange("productImageId", storageId);
              }}
              onDelete={() => {
                onInputChange("productImageId", "");
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ImageUpload
              label="อัปโหลดรูปภาพลูกค้า"
              storageId={CustomerStore.form.customerImageId}
              isSoftDeleteImage={true}
              isDisabled={props.isDisableEditing}
              onFileChange={(storageId: string) =>
                onInputCustomerChange("customerImageId", storageId)
              }
              onDelete={() => {
                onInputCustomerChange("customerImageId", "");
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
});

export default Customer;
