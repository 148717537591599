import StyleVariables from "../assets/styles/Variable.module.scss";

const Modal: any = {
  MuiModal: {
    styleOverrides: {
      root: {
        overflow: "scroll",
        "&.modal-md .modal-body": {
          maxWidth: 620,
          width: "80%",
        },
        "&.modal-lg .modal-body": {
          maxWidth: 700,
          width: "80%",
        },
        "&.modal-receipt .modal-body": {
          maxWidth: 280,
          width: "80%",
        },
        ".modal-body": {
          backgroundColor: StyleVariables["color-white"],
          borderRadius: "10px",
          outline: "none",
          position: "absolute",
          top: "50dvh",
          left: "50%",
          transform: "translate(-50%, -50dvh)",
          padding: `${Number(StyleVariables["spacing"]) * 5}px`,
          marginTop: 54,
        },
        "&.modal .MuiModal-backdrop": {
          backgroundColor: "rgba(0,0,0,0.7)",
        },
      },
    },
  },
};

export default Modal;
