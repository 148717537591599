import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { Grid } from "@mui/material";
import NumberInput from "../../inputs/Number";

type Props = {};

const ToInterest = observer((props: Props) => {
  const { DepositStore } = useStores();

  return (
    <Grid container spacing={2} rowSpacing={2}>
      <Grid item xs={12} md={6}>
        <NumberInput
          name="newDepositPrice"
          label="ราคารับฝากใหม่"
          placeholder="กรอกอัตโนมัติ"
          value={`${DepositStore.formRenew.newDepositPrice || ""}`}
          isDisabled={true}
          handleOnChange={(value: number) => {}}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput
          name="depositTotalPay"
          label="รับเงินจากลูกค้า"
          placeholder="กรอกอัตโนมัติ"
          value={`${DepositStore.form.totalPay || ""}`}
          inputPropsClassName="input-highlight"
          isDisabled={true}
          handleOnChange={(value: number) => {}}
        />
      </Grid>
    </Grid>
  );
});

export default ToInterest;
