import StyleVariables from "../assets/styles/Variable.module.scss";
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    "contained-sell": true;
    "outlined-sell": true;
    "contained-buy": true;
    "outlined-buy": true;
    "contained-change": true;
    "outlined-change": true;
    "contained-deposit": true;
    "outlined-deposit": true;
    "contained-refund": true;
    "outlined-refund": true;
    "contained-success": true;
    "outlined-success": true;
    "outlined-upload": true;
  }
}

const Button: any = {
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: "none !important",
        paddingTop: Number(StyleVariables.spacing) * 3,
        paddingBottom: Number(StyleVariables.spacing) * 3,
        paddingLeft: Number(StyleVariables.spacing) * 4,
        paddingRight: Number(StyleVariables.spacing) * 4,
        borderRadius: "10px !important",
        minHeight: 76,
        fontWeight: 700,
        position: "relative",
        "&.Mui-disabled": {
          borderColor: "transparent",
        },
      },
      outlined: {
        background: StyleVariables["color-white"],
        border: `1px solid ${StyleVariables["color-primary"]}`,
        "&:hover": {
          color: StyleVariables["color-primary"],
          border: `1px solid ${StyleVariables["color-primary"]}`,
          background: StyleVariables["color-white"],
        },
      },
      contained: {
        background: StyleVariables["color-primary"],
        color: StyleVariables["color-white"],
        border: `1px solid ${StyleVariables["color-primary"]}`,
        "&:hover": {
          color: StyleVariables["color-white"],
          borderColor: `1px solid ${StyleVariables["color-primary"]}`,
          background: StyleVariables["color-primary"],
        },
      },
      "contained-sell": {
        background: StyleVariables["color-beige-light"],
        color: StyleVariables["color-text"],
        border: `1px solid ${StyleVariables["color-beige-light"]}`,
        "&:hover": {
          color: StyleVariables["color-text"],
          borderColor: `1px solid ${StyleVariables["color-beige-light"]}`,
          background: StyleVariables["color-beige-light"],
        },
      },
      "outlined-sell": {
        background: StyleVariables["color-white"],
        color: StyleVariables["color-text"],
        border: `1px solid ${StyleVariables["color-beige-light"]}`,
        "&:hover": {
          color: StyleVariables["color-text"],
          borderColor: `1px solid ${StyleVariables["color-beige-light"]}`,
          background: StyleVariables["color-beige-light"],
        },
      },
      "contained-buy": {
        background: StyleVariables["color-pink"],
        color: StyleVariables["color-text"],
        border: `1px solid ${StyleVariables["color-pink"]}`,
        "&:hover": {
          color: StyleVariables["color-text"],
          borderColor: `1px solid ${StyleVariables["color-pink"]}`,
          background: StyleVariables["color-pink"],
        },
      },
      "outlined-buy": {
        background: StyleVariables["color-white"],
        color: StyleVariables["color-text"],
        border: `1px solid ${StyleVariables["color-pink"]}`,
        "&:hover": {
          color: StyleVariables["color-text"],
          borderColor: `1px solid ${StyleVariables["color-pink"]}`,
          background: StyleVariables["color-pink"],
        },
      },
      "contained-change": {
        background: StyleVariables["color-green-light"],
        color: StyleVariables["color-text"],
        border: `1px solid ${StyleVariables["color-green-light"]}`,
        "&:hover": {
          color: StyleVariables["color-text"],
          borderColor: `1px solid ${StyleVariables["color-green-light"]}`,
          background: StyleVariables["color-green-light"],
        },
      },
      "outlined-change": {
        background: StyleVariables["color-white"],
        color: StyleVariables["color-text"],
        border: `1px solid ${StyleVariables["color-green-light"]}`,
        "&:hover": {
          color: StyleVariables["color-text"],
          borderColor: `1px solid ${StyleVariables["color-green-light"]}`,
          background: StyleVariables["color-green-light"],
        },
      },
      "contained-deposit": {
        background: StyleVariables["color-blue-light"],
        color: StyleVariables["color-text"],
        border: `1px solid ${StyleVariables["color-blue-light"]}`,
        "&:hover": {
          color: StyleVariables["color-text"],
          borderColor: `1px solid ${StyleVariables["color-blue-light"]}`,
          background: StyleVariables["color-blue-light"],
        },
      },
      "outlined-deposit": {
        background: StyleVariables["color-white"],
        color: StyleVariables["color-text"],
        border: `1px solid ${StyleVariables["color-blue-light"]}`,
        "&:hover": {
          color: StyleVariables["color-text"],
          borderColor: `1px solid ${StyleVariables["color-blue-light"]}`,
          background: StyleVariables["color-blue-light"],
        },
      },
      "contained-refund": {
        background: StyleVariables["color-yellow-light"],
        color: StyleVariables["color-text"],
        border: `1px solid ${StyleVariables["color-yellow-light"]}`,
        "&:hover": {
          color: StyleVariables["color-text"],
          borderColor: `1px solid ${StyleVariables["color-yellow-light"]}`,
          background: StyleVariables["color-yellow-light"],
        },
      },
      "outlined-refund": {
        background: StyleVariables["color-white"],
        color: StyleVariables["color-text"],
        border: `1px solid ${StyleVariables["color-yellow-light"]}`,
        "&:hover": {
          color: StyleVariables["color-text"],
          borderColor: `1px solid ${StyleVariables["color-yellow-light"]}`,
          background: StyleVariables["color-yellow-light"],
        },
      },
      "contained-success": {
        background: StyleVariables["color-success"],
        color: StyleVariables["color-white"],
        border: `1px solid ${StyleVariables["color-success"]}`,
        "&:hover": {
          color: StyleVariables["color-white"],
          borderColor: `1px solid ${StyleVariables["color-success"]}`,
          background: StyleVariables["color-success"],
        },
      },
      "outlined-success": {
        background: StyleVariables["color-white"],
        color: StyleVariables["color-success"],
        border: `1px solid ${StyleVariables["color-success"]}`,
        "&:hover": {
          color: StyleVariables["color-white"],
          borderColor: `1px solid ${StyleVariables["color-success"]}`,
          background: StyleVariables["color-success"],
        },
      },
      "outlined-upload": {
        background: "transparent",
        border: `1px dashed ${StyleVariables["color-border"]}`,
        borderRadius: "10px",
        maxHeight: 300,
        padding: 0,
        "&:hover": {
          border: `1px dashed ${StyleVariables["color-border"]}`,
          background: "transparent",
        },
        "&::before": {
          float: "left",
          paddingTop: "100%",
          content: '""',
        },
        "&::after": {
          display: "block",
          clear: "both",
          content: '""',
        },
        "&.Mui-disabled": {
          border: `1px dashed ${StyleVariables["color-border"]}`,
          backgroundColor: `${StyleVariables["color-disable"]} !important`,
        },
      },
    },
    variants: [
      {
        props: { variant: "contained-sell" },
      },
      {
        props: { variant: "outlined-sell" },
      },
      {
        props: { variant: "contained-buy" },
      },
      {
        props: { variant: "outlined-buy" },
      },
      {
        props: { variant: "contained-change" },
      },
      {
        props: { variant: "outlined-change" },
      },
      {
        props: { variant: "contained-deposit" },
      },
      {
        props: { variant: "outlined-deposit" },
      },
      {
        props: { variant: "contained-refund" },
      },
      {
        props: { variant: "outlined-refund" },
      },
      {
        props: { variant: "contained-success" },
      },
      {
        props: { variant: "outlined-success" },
      },
      {
        props: { variant: "outlined-upload" },
      },
    ],
  },
};

export default Button;
