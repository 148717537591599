const status = {
  active: "ACTIVE",
  nearlyExpire: "NEARLY_EXPIRE",
  expired: "EXPIRED",
  returned: "RETURNED",
};

const renewBillTypeKey: {
  toInterest: "TO_INTEREST";
  reduce: "REDUCE";
  increase: "INCREASE";
  purchase: "PURCHASE";
  new: "NEW";
  return: "RETURN";
} = {
  toInterest: "TO_INTEREST",
  reduce: "REDUCE",
  increase: "INCREASE",
  purchase: "PURCHASE",
  new: "NEW",
  return: "RETURN",
};

const renewBillTypes = [
  {
    label: "คืน",
    value: renewBillTypeKey.return,
  },
  {
    label: "ต่อดอก",
    value: renewBillTypeKey.toInterest,
  },
  {
    label: "ลดต้น",
    value: renewBillTypeKey.reduce,
  },
  {
    label: "เพิ่มยอด",
    value: renewBillTypeKey.increase,
  },
  {
    label: "ตัดขาย",
    value: renewBillTypeKey.purchase,
  },
];

const rangeOfInterestStatusForCalculate = {
  excellent: 0.02,
  good: 1,
  fair: -0.01,
  poor: -0.02,
};

export {
  status as Status,
  renewBillTypes as RenewBillTypes,
  renewBillTypeKey as RenewBillTypeKey,
  rangeOfInterestStatusForCalculate as RangeOfInterestStatusForCalculate,
};
