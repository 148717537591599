import React, { useState, useEffect } from "react";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import TextFieldInput from "../inputs/TextField";
import PasswordInput from "../inputs/Password";
import ErrorContentSnackbar from "../snackbars/Content";
import { UserList as UserListModel } from "../../models/User";

type Props = {
  user: UserListModel | null;
  isShowErrorMessage?: boolean;
  errorMessage?: string;
  isLoading?: boolean;
  onForgotPassword: () => void;
  onSubmit: (form: any) => void;
};

const Login = (props: Props) => {
  const [pinCode, setPinCode] = useState<string>("");
  const [pinCodeIsInvalid, setPinCodeIsInvalid] = useState<boolean>(false);
  const [isShowError, setIsShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    setIsShowError(props.isShowErrorMessage || false);
    setPinCodeIsInvalid(props.isShowErrorMessage || false);
    setErrorMessage(props.errorMessage || "");
    return () => {
      setIsShowError(false);
      setErrorMessage("");
    };
  }, [props.isShowErrorMessage, props.errorMessage]);

  const onInputPinCodeChange = (value: string) => {
    setPinCode(value);
    setPinCodeIsInvalid(false);
    setIsShowError(false);
    setErrorMessage("");
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setPinCodeIsInvalid(!pinCode);
    pinCode &&
      props.user?.employeeCode &&
      !isShowError &&
      props.onSubmit({ employeeCode: props.user?.employeeCode, pinCode });
  };

  return (
    <form onSubmit={handleSubmit}>
      {isShowError && (
        <Box marginTop={5}>
          <ErrorContentSnackbar type="error" title={errorMessage} />
        </Box>
      )}
      <Box marginTop={5}>
        <TextFieldInput
          name="userId"
          label="ชื่อสกุล"
          placeholder="กรอกอัตโนมัติ"
          value={`${props.user?.employeeCode} ${props.user?.employeeName}`}
          isDisabled={true}
          handleOnChange={(value: any) => {}}
        />
      </Box>
      <Box marginTop={5}>
        <PasswordInput
          name="pinCode"
          label="รหัสผ่าน"
          isRequire={true}
          placeholder="กรอกรหัสผ่าน"
          value={pinCode}
          isError={pinCodeIsInvalid}
          autoFocus={true}
          handleOnChange={(value: string) => onInputPinCodeChange(value)}
        />
        <Box marginTop={3} marginLeft={"auto"} textAlign={"right"}>
          <Typography
            variant="body1"
            fontWeight={"bold"}
            color={StyleVariables["color-primary"]}
            sx={{ cursor: "pointer" }}
            onClick={props.onForgotPassword}
          >
            ลืมรหัสผ่าน ?
          </Typography>
        </Box>
      </Box>
      <Box marginTop={5}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          disabled={props.isLoading}
        >
          {!props.isLoading ? (
            "เข้าสู่ระบบ"
          ) : (
            <CircularProgress
              size={22}
              style={{ color: StyleVariables["color-white"] }}
            />
          )}
        </Button>
      </Box>
    </form>
  );
};

export default Login;
