import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import StyleVariables from "../../../assets/styles/Variable.module.scss";
import TextFieldInput from "../../inputs/TextField";
import SelectionDropdown from "../../dropdowns/Selection";
import NumberFieldWithButtonInput from "../../inputs/NumberFieldWithButton";
import NumberInput from "../../inputs/Number";
import { NumericFormat } from "react-number-format";
import { OnMappingQuantityOptions as OnMappingQuantityOptionsUtils } from "../../../utils/Form";
import {
  CalculateSellPrice as CalculateSellPriceUtil,
  CurrentStatusSellPrice as CurrentStatusSellPriceUtil,
} from "../../../utils/Gold";
import { Exchange as ExchangeModel } from "../../../models/Exchange";
import { RangeOfStatusForCalculate as RangeOfStatusForCalculateSellConstant } from "../../../constants/Sell";
import {
  CalculateSellPriceStandard as CalculateSellPriceStandardUtil,
  CalculateExchangePriceStandard as CalculateExchangePriceStandardUtil,
} from "../../../utils/Gold";
import {
  FixedDecimal as FixedDecimalUtil,
  PriceRoundUp as PriceRoundUpUtil,
} from "../../../utils/NumberRounding";

type Props = {};

const NewEqualWeight = observer((props: Props) => {
  const { ExchangeStore, PaymentStore } = useStores();

  const onTotalPayChange = (
    newTotalPay: number,
    formExchange?: ExchangeModel
  ) => {
    const form: ExchangeModel = JSON.parse(
      JSON.stringify(formExchange || ExchangeStore.form)
    );
    const formIsInvalid: any = JSON.parse(
      JSON.stringify(ExchangeStore.formIsInvalid)
    );
    const totalDiscount =
      form.totalExtraDiscountStandard + (form.totalPriceStandard - newTotalPay);
    const finalLabour =
      form.exchangeNewHasItems[0].labourStandard -
      totalDiscount / form.newTotalQuantity;
    const oldTotalWeightLessThanOrEqualNewTotalWeight =
      form.oldTotalWeight <= form.newTotalWeight || !form.newTotalWeight;
    form.exchangeNewHasItems[0].labour = finalLabour;
    form.exchangeNewHasItems[0].price =
      form.exchangeNewHasItems[0].goldPrice + finalLabour;
    form.newTotalLabour = FixedDecimalUtil(
      finalLabour * form.newTotalQuantity,
      2
    );
    form.totalPay = newTotalPay;
    form.currentStatus = oldTotalWeightLessThanOrEqualNewTotalWeight
      ? CurrentStatusSellPriceUtil(
          form.newTotalLabour,
          form.standardExcellent,
          form.standardGood,
          form.standardFair
        )
      : "poor";
    Object.keys(formIsInvalid).forEach((key) => {
      formIsInvalid[key] = false;
    });
    ExchangeStore.onSetForm({ ...form });
    ExchangeStore.onSetFormIsInvalid({ ...formIsInvalid });
    ExchangeStore.onClearPayment(
      oldTotalWeightLessThanOrEqualNewTotalWeight ? newTotalPay : -1
    );
    PaymentStore.onClearStore(
      oldTotalWeightLessThanOrEqualNewTotalWeight ? newTotalPay : -1
    );
  };

  const onQuantityChange = (newQuantity: number) => {
    const form: ExchangeModel = JSON.parse(JSON.stringify(ExchangeStore.form));
    const finalLabour =
      form.exchangeNewHasItems[0].labourStandard -
      form.totalExtraDiscountStandard / newQuantity;
    const sellPriceStandard = CalculateSellPriceStandardUtil(
      form.exchangeNewHasItems[0].goldPrice,
      finalLabour,
      newQuantity,
      true
    );
    const exchangeStandard = CalculateExchangePriceStandardUtil(
      sellPriceStandard,
      form.oldTotalPrice
    );
    const priceNegotiation = form.exchangeNewHasItems[0].priceNegotiation;
    const labourAfterIncreasePriceNegotiation = finalLabour + priceNegotiation;
    let totalLabourStandardAfterDiscount: number = 0;
    form.exchangeNewHasItems[0].quantity = newQuantity;
    form.exchangeNewHasItems[0].labour = labourAfterIncreasePriceNegotiation;
    form.exchangeNewHasItems[0].price =
      form.exchangeNewHasItems[0].priceStandard;
    form.newTotalQuantity = newQuantity;
    form.newTotalWeight = form.exchangeNewHasItems[0].weight * newQuantity;
    form.newTotalGoldPrice =
      PriceRoundUpUtil(form.exchangeNewHasItems[0].goldPrice) * newQuantity;
    form.newTotalLabourStandard =
      form.exchangeNewHasItems[0].labourStandard * newQuantity;
    form.totalPriceStandard = exchangeStandard;
    totalLabourStandardAfterDiscount =
      form.newTotalLabourStandard - form.totalExtraDiscountStandard;
    form.totalPriceNegotiation =
      form.exchangeNewHasItems[0].priceNegotiation * newQuantity;
    form.standardExcellent = FixedDecimalUtil(
      totalLabourStandardAfterDiscount *
        RangeOfStatusForCalculateSellConstant.excellent,
      2
    );
    form.standardGood = FixedDecimalUtil(
      totalLabourStandardAfterDiscount *
        RangeOfStatusForCalculateSellConstant.good,
      2
    );
    form.standardFair = FixedDecimalUtil(
      totalLabourStandardAfterDiscount *
        RangeOfStatusForCalculateSellConstant.fair,
      2
    );
    form.standardPoor = FixedDecimalUtil(
      totalLabourStandardAfterDiscount *
        RangeOfStatusForCalculateSellConstant.poor,
      2
    );
    onTotalPayChange(exchangeStandard + form.totalPriceNegotiation, form);
  };
  const checkCanCreateExchange = () => {
    return Boolean(
      ExchangeStore.form.oldTotalWeight <= ExchangeStore.form.newTotalWeight ||
        !ExchangeStore.form.newTotalWeight
    );
  };

  return (
    <Box marginTop={2}>
      {/* {`ราคาบนล่าง ${ExchangeStore.form.goldSellPrice}-${ExchangeStore.form.goldPurchasePrice}`} */}
      <Grid container spacing={{ xs: 3, md: 2 }}>
        <Grid
          item
          xs={12}
          md={6}
          marginTop={{ xs: 0, md: 1 }}
          alignSelf={"flex-end"}
        >
          <Grid container spacing={2}>
            {/* Weight Per Unit  */}
            <Grid item xs={12} md={6}>
              <TextFieldInput
                name="weightPerUnit"
                type="number"
                label="น้ำหนัก/ชิ้น"
                placeholder="กรอกน้ำหนัก/ชิ้น"
                value={
                  `${ExchangeStore.form.exchangeNewHasItems[0]?.weight}` || ""
                }
                isDisabled={true}
                handleOnChange={(value: string) => {}}
              />
            </Grid>
            {/* /.Weight Per Unit  */}

            {/* Total Quantity */}
            <Grid item xs={12} md={6}>
              <SelectionDropdown
                name="totalQuantity"
                label="จำนวนรวม"
                options={OnMappingQuantityOptionsUtils(99)}
                selected={ExchangeStore.form.newTotalQuantity}
                placeholder="เลือกจำนวนรวม"
                isError={Boolean(
                  ExchangeStore.form.newTotalWeight &&
                    ExchangeStore.form.oldTotalWeight >
                      ExchangeStore.form.newTotalWeight
                )}
                onSelected={(selected: number) => {
                  onQuantityChange(selected);
                }}
              />
            </Grid>
          </Grid>
          {/* /.Total Quantity */}
        </Grid>
        {/* Sell Price Cash */}
        <Grid item xs={12} md={6} marginTop={{ xs: 0, md: 1 }}>
          <NumberFieldWithButtonInput
            name="sellTotalPay"
            label="รับเงินจากลูกค้า"
            helperLabel={
              <Typography
                variant="body2"
                fontWeight={"bold"}
                color={StyleVariables["color-header"]}
              >
                {checkCanCreateExchange() ? (
                  <>
                    {"(เปิด "}
                    <NumericFormat
                      value={
                        ExchangeStore.form.totalPriceStandard +
                        ExchangeStore.form.totalPriceNegotiation
                      }
                      displayType="text"
                      thousandSeparator
                    />
                    {")"}
                  </>
                ) : null}
              </Typography>
            }
            placeholder={
              checkCanCreateExchange()
                ? "กรอกอัตโนมัติ"
                : "ไม่สามารถทำรายการได้"
            }
            value={checkCanCreateExchange() ? ExchangeStore.form.totalPay : ""}
            isDisabled={true}
            isDecreaseDisabled={
              ExchangeStore.form.newTotalLabour <= 0 ||
              ExchangeStore.form.oldTotalWeight >
                ExchangeStore.form.newTotalWeight
            }
            isIncreaseDisabled={
              !ExchangeStore.form.exchangeNewHasItems[0].productCode ||
              ExchangeStore.form.oldTotalWeight >
                ExchangeStore.form.newTotalWeight
            }
            isError={ExchangeStore.form.currentStatus === "poor"}
            status={ExchangeStore.form.currentStatus}
            handleOnChange={(value: number | string) => {}}
            handleOnIncrease={(value: number | string) => {
              onTotalPayChange(Number(value));
            }}
            handleOnDecrease={(value: number | string) => {
              onTotalPayChange(Number(value));
            }}
          />
          {/* {`ราคาเปลี่ยนมาตรฐาน ${ExchangeStore.form.totalPriceStandard} / `}
          {`เขียวx2 : ${ExchangeStore.form.standardExcellent} / เขียว : ${ExchangeStore.form.standardGood} / ส้ม : ${ExchangeStore.form.standardFair} / แดง: ${ExchangeStore.form.standardPoor}`} */}
        </Grid>
        {/* /.Sell Price Cash */}

        {ExchangeStore.isShowAll && (
          <>
            {/* Gold Price Per Unit */}
            <Grid
              item
              xs={12}
              md={4}
              marginTop={{ xs: 0, md: 1 }}
              sx={{ opacity: 0.6 }}
            >
              <NumberInput
                name="goldPricePerUnit"
                label="ราคาเนื้อทอง/ชิ้น"
                placeholder="กรอกอัตโนมัติ"
                value={`${
                  ExchangeStore.form.exchangeNewHasItems[0]?.goldPrice || ""
                }`}
                handleOnChange={(value: string) => {}}
                isDisabled={true}
              />
            </Grid>
            {/* /.Gold Price Per Unit */}

            {/* Labour Per Unit */}
            <Grid
              item
              xs={12}
              md={4}
              marginTop={{ xs: 0, md: 1 }}
              sx={{ opacity: 0.6 }}
            >
              <NumberInput
                name="labourPerUnit"
                label="ค่ากำเหน็จ/ชิ้น"
                placeholder="กรอกอัตโนมัติ"
                value={`${
                  ExchangeStore.form.totalPay
                    ? ExchangeStore.form.exchangeNewHasItems[0]?.labour
                    : ""
                }`}
                handleOnChange={(value: string) => {}}
                isDisabled={true}
                isError={ExchangeStore.form.currentStatus === "poor"}
                isShowErrorMessage={checkCanCreateExchange()}
                errorMessage={`กำเหน็จมาตรฐาน ${FixedDecimalUtil(
                  (ExchangeStore.form.newTotalLabourStandard -
                    ExchangeStore.form.totalExtraDiscountStandard) /
                    ExchangeStore.form.newTotalQuantity,
                  2
                )}`}
              />
              {/* {`ค่ากำเหน็จมาตรฐานรวม: ${ExchangeStore.form.newTotalLabourStandard} / ส่วนลด 20%: ${ExchangeStore.form.totalExtraDiscountStandard} / สุทธิ: ${ExchangeStore.form.newTotalLabour}`} */}
            </Grid>
            {/* /.Labour Per Unit */}

            {/* Total Price Per Unit */}
            <Grid
              item
              xs={12}
              md={4}
              marginTop={{ xs: 0, md: 1 }}
              sx={{ opacity: 0.6 }}
            >
              <NumberInput
                name="sellTotalPayPerUnit"
                label="ราคารวม/ชิ้น"
                placeholder="กรอกอัตโนมัติ"
                value={`${
                  (ExchangeStore.form.exchangeNewHasItems[0] &&
                    CalculateSellPriceUtil(
                      ExchangeStore.form.exchangeNewHasItems[0].goldPrice,
                      ExchangeStore.form.exchangeNewHasItems[0].labour
                    )) ||
                  "0"
                }`}
                handleOnChange={(value: string) => {}}
                isDisabled={true}
              />
              {/* {`ราคาขายมาตรฐาน ${ExchangeStore.form.exchangeNewHasItems[0].priceStandard}`} */}
            </Grid>
            {/* /.Total Price Per Unit */}
          </>
        )}
      </Grid>
    </Box>
  );
});

export default NewEqualWeight;
