import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Modal, Fade, Button } from "@mui/material";
import { CloseRounded as CloseRoundedIcon } from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import {
  CalculatePurchasePriceStandard as CalculatePurchasePriceStandardUtil,
  CalculateGoldPriceStandard as CalculateGoldPriceStandardUtil,
  CalculateSellPriceStandard as CalculateSellPriceStandardUtil,
} from "../../utils/Gold";
import {
  PriceRoundDown as PriceRoundDownUtil,
  PriceRoundUp as PriceRoundUpUtil,
} from "../../utils/NumberRounding";
import { Paths as PathsConstant } from "../../constants/Route";
import { NumericFormat } from "react-number-format";
interface Props {
  isOpen: boolean;
  currentGoldPurchasePrice: number;
  percentGoldPurchase: number;
  weightPurchase: number;
  currentGoldSellPrice: number;
  categoryCodeSell: string;
  percentGoldSell: number;
  weightSell: number;
  labourStandardSell: number;
  quantitySell: number;
  onClose: () => void;
}

const CannotExchange = (props: Props) => {
  const navigate = useNavigate();
  const [purchasePrice, setPurchasePrice] = useState<number>(0);
  const [sellPrice, setSellPrice] = useState<number>(0);

  useEffect(() => {
    calPurchasePrice();
    calSellPrice();
    return () => {
      setPurchasePrice(0);
      setSellPrice(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.currentGoldPurchasePrice,
    props.currentGoldSellPrice,
    props.isOpen,
  ]);

  const calPurchasePrice = () => {
    const purchasePriceStandard = CalculatePurchasePriceStandardUtil(
      props.currentGoldPurchasePrice,
      props.percentGoldPurchase,
      props.weightPurchase
    );
    const finalPurchasePrice = PriceRoundDownUtil(purchasePriceStandard);
    setPurchasePrice(finalPurchasePrice);
  };

  const calSellPrice = () => {
    const goldPriceStandard = PriceRoundUpUtil(
      CalculateGoldPriceStandardUtil(
        props.currentGoldPurchasePrice,
        props.percentGoldSell,
        props.categoryCodeSell === "11",
        props.quantitySell,
        props.weightSell
      )
    );
    const sellPriceStandard = CalculateSellPriceStandardUtil(
      goldPriceStandard,
      props.labourStandardSell,
      props.quantitySell
    );
    setSellPrice(sellPriceStandard);
  };

  return (
    <Modal
      className="modal modal-md"
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={props.isOpen}>
        <Box className="modal-body">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom={`1px solid ${StyleVariables["color-border"]}`}
            paddingBottom={2}
            onClick={props.onClose}
          >
            <Typography
              variant="body1"
              fontWeight={"bold"}
              color={StyleVariables["color-header"]}
            >
              ทำรายการไม่สำเร็จ
            </Typography>
            <CloseRoundedIcon
              sx={{
                color: StyleVariables["color-main-icon"],
                fontSize: 32,
                cursor: "pointer",
              }}
            />
          </Box>
          <Box marginTop={4}>
            <Typography
              variant="body2"
              fontWeight={"regular"}
              color={StyleVariables["color-text"]}
            >
              ไม่สามารถทำรายการได้ เนื่องจากรายการไม่ตรงเงื่อนไขที่ระบบ กำหนดไว้
            </Typography>
            <Typography
              variant="body2"
              fontWeight={"regular"}
              color={StyleVariables["color-text"]}
            >
              1. น้ำหนักทองใหม่จะต้องมากกว่าน้ำหนักทองเก่า
            </Typography>
            <Typography
              variant="body2"
              fontWeight={"regular"}
              color={StyleVariables["color-text"]}
            >
              2. ทองเก่าต้องมีเปอร์เซ็นต์ทองอยู่ในช่วง 92-99.99%
            </Typography>
            <Typography
              variant="body2"
              fontWeight={"regular"}
              color={StyleVariables["color-text"]}
            >
              3. ทองใหม่จะต้องเป็นทอง 100% เท่านั้น
            </Typography>
            <Typography
              variant="body2"
              fontWeight={"regular"}
              color={StyleVariables["color-text"]}
              marginTop={3}
            >
              ถ้าเป็นเงื่อนไขอื่นๆ กรุณาไปทำการซื้อเข้าขายออกปกติ
            </Typography>
            <Typography
              variant="body2"
              fontWeight={"regular"}
              color={StyleVariables["color-text"]}
            >
              ราคารับซื้อทองเก่า:{" "}
              <NumericFormat
                value={purchasePrice}
                displayType="text"
                thousandSeparator
              />
            </Typography>
            <Typography
              variant="body2"
              fontWeight={"regular"}
              color={StyleVariables["color-text"]}
            >
              ราคาทองใหม่:{" "}
              <NumericFormat
                value={sellPrice}
                displayType="text"
                thousandSeparator
              />
            </Typography>
            {sellPrice - purchasePrice <= 0 ? (
              <Typography
                variant="body2"
                fontWeight={"regular"}
                color={StyleVariables["color-primary"]}
              >
                ให้เงินลูกค้า:{" "}
                <NumericFormat
                  value={purchasePrice - sellPrice}
                  displayType="text"
                  thousandSeparator
                />
              </Typography>
            ) : (
              <Typography
                variant="body2"
                fontWeight={"regular"}
                color={StyleVariables["color-primary"]}
              >
                รับเงินจากลูกค้า:{" "}
                <NumericFormat
                  value={sellPrice - purchasePrice}
                  displayType="text"
                  thousandSeparator
                />
              </Typography>
            )}
            <Button
              variant="contained-sell"
              fullWidth
              sx={{ marginTop: 5 }}
              onClick={() => navigate(PathsConstant.sell)}
            >
              ไปเมนูการขาย
            </Button>
            <Button
              variant="contained-buy"
              fullWidth
              sx={{ marginTop: 2 }}
              onClick={() => navigate(PathsConstant.purchase)}
            >
              ไปเมนูการรับซื้อ
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CannotExchange;
