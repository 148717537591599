import React from "react";
import {
  InputAdornment,
  Input,
  IconButton,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { Cancel as CancelIcon, Error as ErrorIcon } from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import { NumericFormat } from "react-number-format";

interface Props {
  placeholder: string;
  value: string;
  name: string;
  type?: string;
  label?: string;
  subLabel?: JSX.Element;
  errorMessage?: string;
  isShowErrorMessage?: boolean;
  isError?: boolean;
  isDisabled?: boolean;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  maxLength?: number;
  inputMode?: "decimal" | "numeric";
  inputPropsClassName?: string;
  handleOnChange: (value: any) => void;
  handleOnBlur?: () => void;
  handleOnClear?: () => void;
}

const Number = (props: Props) => {
  const onClearTextInput = () => {
    props.handleOnChange("");
    props.handleOnClear && props.handleOnClear();
  };
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value.replaceAll(",", "");
    props.handleOnChange(value || 0);
  };

  return (
    <Box>
      {props.label && (
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          gap={1}
          rowGap={0}
          marginBottom={1}
        >
          <Typography
            variant="body1"
            fontWeight={"bold"}
            color={StyleVariables["color-header"]}
          >
            {props.label}
          </Typography>
          {props.subLabel}
        </Box>
      )}
      <NumericFormat
        thousandSeparator
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        disabled={props.isDisabled}
        error={props.isError}
        autoComplete="one-time-code"
        onChange={onInputChange}
        onBlur={props.handleOnBlur}
        customInput={Input}
        decimalScale={props.decimalScale === undefined ? 2 : props.decimalScale}
        fixedDecimalScale={props.fixedDecimalScale}
        allowNegative={props.isDisabled ? true : false}
        allowLeadingZeros={false}
        inputProps={{
          inputMode: props.inputMode || "numeric",
          className: props.inputPropsClassName,
          max: 10000,
        }}
        isAllowed={(values) => {
          return !props.maxLength
            ? true
            : values.value.length <= props.maxLength;
        }}
        endAdornment={
          props.value &&
          !props.isDisabled && (
            <InputAdornment position="start">
              <IconButton
                aria-label="clear value"
                edge="end"
                sx={{
                  fontSize: 32,
                  color: StyleVariables["color-textfield-icon"],
                }}
                onClick={onClearTextInput}
              >
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          )
        }
      />
      {props.isError && props.isShowErrorMessage && (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          <ErrorIcon
            sx={{ fontSize: 32, color: StyleVariables["color-error-icon"] }}
          />
          <Typography
            variant="subtitle1"
            fontWeight={"medium"}
            color={StyleVariables["color-error"]}
          >
            {props.errorMessage}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export default Number;
