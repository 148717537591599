import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { Grid, Box } from "@mui/material";
import SelectionDropdown from "../../dropdowns/Selection";
import NumberInput from "../../inputs/Number";
import {
  Type as TypeGoldConstant,
  ProductWeightType as ProductWeightTypeConstant,
} from "../../../constants/Gold";
import { OnMappingQuantityOptions as OnMappingQuantityOptionsUtils } from "../../../utils/Form";
import {
  CalculatePercentGoldPurchasePriceForExchange as CalculatePercentGoldPurchasePriceForExchangeUtil,
  CalculatePurchasePriceStandardForExchange as CalculatePurchasePriceStandardForExchangeUtil,
} from "../../../utils/Gold";
import {
  Category as CategoryModel,
  Product as ProductModel,
} from "../../../models/Product";
import {
  GetCategories as GetCategoriesService,
  GetProductsByCategory as GetProductsByCategoryService,
} from "../../../services/MasterData";
import {
  Exchange as ExchangeModel,
  ExchangeHasItemIsInvalid as ExchangeHasItemIsInvalidModel,
} from "../../../models/Exchange";

type Props = {};

const Old = observer((props: Props) => {
  const { ExchangeStore, UserStore, MainStore, PaymentStore } = useStores();
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [products, setProducts] = useState<ProductModel[]>([]);

  useEffect(() => {
    getCategories();
    return () => {
      ExchangeStore.onClearStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ExchangeStore]);

  const getCategories = async () => {
    const response = await GetCategoriesService(TypeGoldConstant.exchangeOld);
    if (response.success) {
      setCategories(response.data);
      response.data?.length === 1 && onCategorySelected(response.data[0]);
    }
  };
  const getProductsByCategory = async (code: string) => {
    setProducts([]);
    const response = await GetProductsByCategoryService(
      code,
      TypeGoldConstant.exchangeOld
    );
    setProducts(response.data);
    response.data?.length === 1 && onProductSelected(response.data[0]);
  };

  const onCategorySelected = (category: CategoryModel) => {
    PaymentStore.onClearStore(0);
    ExchangeStore.onClearStore();
    const form: ExchangeModel = JSON.parse(JSON.stringify(ExchangeStore.form));
    const formHasItemsIsInvalidOld: ExchangeHasItemIsInvalidModel = JSON.parse(
      JSON.stringify(ExchangeStore.formHasItemsIsInvalidOld[0])
    );
    form.userFirstName = UserStore.user.employeeFirstName;
    form.userLastName = UserStore.user.employeeLastName;
    form.branchName = UserStore.user.companyName;
    form.branchCode = UserStore.user.companyCode;
    form.goldSellPrice = MainStore.goldSellNow;
    form.goldPurchasePrice = MainStore.goldPurchaseNow;
    form.goldPriceDateTime = MainStore.goldDateTime;
    form.exchangeOldHasItems[0].category = category;
    form.exchangeOldHasItems[0].categoryCode = category.code;
    form.exchangeOldHasItems[0].categoryName = category.name;
    formHasItemsIsInvalidOld.categoryCode = false;
    ExchangeStore.onSetForm({ ...form });
    ExchangeStore.onSetFormHasItemIsInvalidOld(
      { ...formHasItemsIsInvalidOld },
      0
    );
    getProductsByCategory(category.code);
  };
  const onProductSelected = (product: ProductModel) => {
    PaymentStore.onClearStore(0);
    ExchangeStore.onClearPayment(0);
    ExchangeStore.onClearExchangeHasItemsNew();
    const form: ExchangeModel = JSON.parse(JSON.stringify(ExchangeStore.form));
    const formIsInvalid: any = JSON.parse(
      JSON.stringify(ExchangeStore.formIsInvalid)
    );
    const formHasItemsIsInvalidOld: any = JSON.parse(
      JSON.stringify(ExchangeStore.formHasItemsIsInvalidOld[0])
    );
    form.oldTotalQuantity = 1;
    form.oldTotalWeight = 0;
    form.oldTotalPrice = 0;
    form.currentStatus = "good";
    form.standardExcellent = 0;
    form.standardGood = 0;
    form.standardFair = 0;
    form.standardPoor = 0;
    form.exchangeOldHasItems[0].product = product;
    form.exchangeOldHasItems[0].productCode = product.code;
    form.exchangeOldHasItems[0].productName = product.name;
    form.exchangeOldHasItems[0].productPercentage = product.percentageGold;
    form.exchangeOldHasItems[0].productType =
      !product.percentageGold && !product.labour
        ? ProductWeightTypeConstant.manual
        : ProductWeightTypeConstant.equalWeight;
    form.exchangeOldHasItems[0].weight = 0;
    form.exchangeOldHasItems[0].quantity = 1;
    form.exchangeOldHasItems[0].labourStandard = product.labour;
    form.exchangeOldHasItems[0].labour = product.labour;
    form.exchangeOldHasItems[0].goldPrice = 0;
    form.exchangeOldHasItems[0].priceStandard = 0;
    form.exchangeOldHasItems[0].price = 0;
    Object.keys(formIsInvalid).forEach((key) => {
      formIsInvalid[key] = false;
    });
    Object.keys(formHasItemsIsInvalidOld).forEach((key) => {
      formHasItemsIsInvalidOld[key] = false;
    });
    ExchangeStore.onSetForm({ ...form });
    ExchangeStore.onSetFormIsInvalid({ ...formIsInvalid });
    ExchangeStore.onSetFormHasItemIsInvalidOld(
      { ...formHasItemsIsInvalidOld },
      0
    );
  };

  const onInputWeightChange = (newWeight: number) => {
    ExchangeStore.onClearPayment(0);
    ExchangeStore.onClearExchangeHasItemsNew();
    const form: ExchangeModel = JSON.parse(JSON.stringify(ExchangeStore.form));
    const formIsInvalid: any = JSON.parse(
      JSON.stringify(ExchangeStore.formIsInvalid)
    );
    const formHasItemsIsInvalidOld: any = JSON.parse(
      JSON.stringify(ExchangeStore.formHasItemsIsInvalidOld[0])
    );
    const newProductPercentage =
      CalculatePercentGoldPurchasePriceForExchangeUtil(
        form.exchangeOldHasItems[0].productPercentage,
        newWeight
      );
    const purchasePriceStandard = CalculatePurchasePriceStandardForExchangeUtil(
      form.goldPurchasePrice,
      newProductPercentage,
      newWeight
    );
    form.oldTotalWeight = newWeight;
    form.oldTotalPrice = purchasePriceStandard;
    form.exchangeOldHasItems[0].weight = newWeight;
    form.exchangeOldHasItems[0].priceStandard = purchasePriceStandard;
    form.exchangeOldHasItems[0].price = purchasePriceStandard;
    Object.keys(formIsInvalid).forEach((key) => {
      formIsInvalid[key] = false;
    });
    Object.keys(formHasItemsIsInvalidOld).forEach((key) => {
      formHasItemsIsInvalidOld[key] = false;
    });
    formHasItemsIsInvalidOld.weight = !newWeight;
    ExchangeStore.onSetForm({ ...form });
    ExchangeStore.onSetFormIsInvalid({ ...formIsInvalid });
    ExchangeStore.onSetFormHasItemIsInvalidOld(
      { ...formHasItemsIsInvalidOld },
      0
    );
  };
  const onQuantityChange = (newQuantity: number) => {
    const form: ExchangeModel = JSON.parse(JSON.stringify(ExchangeStore.form));
    form.oldTotalQuantity = newQuantity;
    form.exchangeOldHasItems[0].quantity = newQuantity;
    ExchangeStore.onSetForm({ ...form });
  };
  const onTotalPayChange = (newTotalPay: number) => {
    ExchangeStore.onClearPayment(0);
    ExchangeStore.onClearExchangeHasItemsNew();
    const form: ExchangeModel = JSON.parse(JSON.stringify(ExchangeStore.form));
    const formIsInvalid: any = JSON.parse(
      JSON.stringify(ExchangeStore.formIsInvalid)
    );
    const formHasItemsIsInvalidOld: any = JSON.parse(
      JSON.stringify(ExchangeStore.formHasItemsIsInvalidOld[0])
    );
    form.exchangeOldHasItems[0].price = newTotalPay;
    form.oldTotalPrice = newTotalPay;
    Object.keys(formIsInvalid).forEach((key) => {
      formIsInvalid[key] = false;
    });
    Object.keys(formHasItemsIsInvalidOld).forEach((key) => {
      formHasItemsIsInvalidOld[key] = false;
    });
    ExchangeStore.onSetForm({ ...form });
    ExchangeStore.onSetFormIsInvalid({ ...formIsInvalid });
    ExchangeStore.onSetFormHasItemIsInvalidOld(
      { ...formHasItemsIsInvalidOld },
      0
    );
  };

  return (
    <Box>
      <Grid container spacing={{ xs: 3, md: 2 }}>
        {/* Category */}
        <Grid item xs={12} md={6}>
          <SelectionDropdown
            name="oldCategory"
            label="ประเภทสินค้า"
            options={categories}
            selected={ExchangeStore.form.exchangeOldHasItems[0].category}
            labelKey="name"
            valueKey="code"
            imageKey="img"
            placeholder="เลือกประเภทสินค้า"
            isShowImage={true}
            isError={ExchangeStore.formHasItemsIsInvalidOld[0].categoryCode}
            onSelected={(selected: CategoryModel) => {
              onCategorySelected(selected);
            }}
          />
        </Grid>
        {/* /.Category */}

        {/* Product */}
        <Grid item xs={12} md={6}>
          <SelectionDropdown
            name="oldProduct"
            label="ชื่อสินค้า"
            options={products}
            selected={ExchangeStore.form.exchangeOldHasItems[0].product}
            labelKey="name"
            valueKey="code"
            imageKey="img"
            placeholder="เลือกชื่อสินค้า"
            isDisabled={!ExchangeStore.form.exchangeOldHasItems[0].categoryCode}
            isShowImage={true}
            isError={ExchangeStore.formHasItemsIsInvalidOld[0].productCode}
            onSelected={(selected: ProductModel) => {
              onProductSelected(selected);
            }}
          />
        </Grid>
        {/* /.Product */}
      </Grid>
      {ExchangeStore.form.exchangeOldHasItems[0].productCode && (
        <Box marginTop={2}>
          <Grid container spacing={{ xs: 3, md: 2 }}>
            <Grid item xs={12} md={6} marginTop={{ xs: 0, md: 1 }}>
              <Grid container spacing={2}>
                {/* Total Weight  */}
                <Grid item xs={12} md={6}>
                  <NumberInput
                    name="totalWeight"
                    label="น้ำหนักรวม"
                    placeholder="กรอกน้ำหนักรวม"
                    inputMode="decimal"
                    decimalScale={3}
                    value={`${
                      ExchangeStore.form.exchangeOldHasItems[0].weight || ""
                    }`}
                    isError={ExchangeStore.formHasItemsIsInvalidOld[0].weight}
                    handleOnChange={(value: string) => {
                      onInputWeightChange(Number(value || 0));
                    }}
                  />
                </Grid>
                {/* /.Weight Per Unit  */}

                {/* Total Quantity */}
                <Grid item xs={12} md={6}>
                  <SelectionDropdown
                    name="totalQuantity"
                    label="จำนวนรวม"
                    options={OnMappingQuantityOptionsUtils(99)}
                    selected={ExchangeStore.form.oldTotalQuantity}
                    placeholder="เลือกจำนวนรวม"
                    onSelected={(selected: number) => {
                      onQuantityChange(selected);
                    }}
                  />
                </Grid>
              </Grid>
              {/* /.Total Quantity */}
            </Grid>
            {/* Purchase Price Cash */}
            <Grid item xs={12} md={6} marginTop={{ xs: 0, md: 1 }}>
              {ExchangeStore.form.exchangeOldHasItems[0].productType ===
                ProductWeightTypeConstant.equalWeight &&
              ExchangeStore.isShowAll ? (
                <Box sx={{ opacity: 0.6 }}>
                  <NumberInput
                    name="purchaseTotalPay"
                    label="ราคารับซื้อ"
                    placeholder="กรอกอัตโนมัติ"
                    value={`${
                      ExchangeStore.form.exchangeOldHasItems[0].price || ""
                    }`}
                    isError={ExchangeStore.formHasItemsIsInvalidOld[0].price}
                    isDisabled={true}
                    handleOnChange={(value: string) => {}}
                  />
                </Box>
              ) : ExchangeStore.form.exchangeOldHasItems[0].productType ===
                ProductWeightTypeConstant.manual ? (
                <NumberInput
                  name="purchaseTotalPay"
                  label="ราคารับซื้อ"
                  placeholder="กรอกราคารับซื้อสินค้าเงินสด"
                  value={`${
                    ExchangeStore.form.exchangeOldHasItems[0].price || ""
                  }`}
                  isError={ExchangeStore.formHasItemsIsInvalidOld[0].price}
                  handleOnChange={(value: string) => {
                    onTotalPayChange(Number(value || 0));
                  }}
                />
              ) : null}
            </Grid>
            {/* /.Purchase Price Cash */}
          </Grid>
        </Box>
      )}
    </Box>
  );
});

export default Old;
