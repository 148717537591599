import React from "react";
import { Grid, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import StyleVariables from "../../../assets/styles/Variable.module.scss";
import NumberInput from "../../inputs/Number";
import { NumericFormat } from "react-number-format";
import { ProductWeightType as ProductWeightTypeConstant } from "../../../constants/Gold";

type Props = {};

const Increase = observer((props: Props) => {
  const { DepositStore, PaymentStore } = useStores();

  const onIncreaseDepositPriceInputChange = (increaseDepositPrice: number) => {
    const form: any = JSON.parse(JSON.stringify({ ...DepositStore.form }));
    const formRenew: any = JSON.parse(
      JSON.stringify({ ...DepositStore.formRenew })
    );
    const formRenewIsInvalid: any = JSON.parse(
      JSON.stringify({ ...DepositStore.formRenewIsInvalid })
    );
    Object.keys(formRenewIsInvalid).forEach((key) => {
      formRenewIsInvalid[key] = false;
    });
    formRenewIsInvalid.increasePrice =
      form.productType !== ProductWeightTypeConstant.manual &&
      (increaseDepositPrice > formRenew.increasePriceStandard ||
        increaseDepositPrice < form.interest);
    form.totalPay = increaseDepositPrice;
    form.netPay =
      (increaseDepositPrice > 0 && increaseDepositPrice - form.interest) || 0;
    formRenew.increasePrice = increaseDepositPrice;
    formRenew.newDepositPrice =
      increaseDepositPrice && !formRenewIsInvalid.increasePrice
        ? form.depositPrice + increaseDepositPrice
        : 0;
    PaymentStore.onClearStore(form.netPay);
    DepositStore.onSetForm({ ...form });
    DepositStore.onSetFormRenew({ ...formRenew });
    DepositStore.onSetFormRenewIsInvalid({ ...formRenewIsInvalid });
  };

  return (
    <Grid container spacing={2} rowSpacing={2} alignItems={"flex-end"}>
      <Grid item xs={12} md={6}>
        <NumberInput
          name="reducePrice"
          label="จำนวนเงินเพิ่มยอด"
          subLabel={
            <Typography
              variant="body1"
              fontWeight={"bold"}
              color={StyleVariables["color-primary"]}
            >
              {DepositStore.formRenew.increasePriceStandard > 0 &&
              DepositStore.formRenew.increasePriceStandard >
                (DepositStore.form.interest || 0) ? (
                <>
                  {"(เพิ่มได้สูงสุด "}
                  <NumericFormat
                    value={DepositStore.formRenew.increasePriceStandard}
                    displayType="text"
                    thousandSeparator
                  />
                  {")"}
                </>
              ) : (
                <>
                  {DepositStore.form.productType !==
                  ProductWeightTypeConstant.manual
                    ? "(ไม่สามารถเพิ่มยอดได้)"
                    : ""}
                </>
              )}
            </Typography>
          }
          placeholder="กรอกจำนวนเงินเพิ่มยอด"
          value={`${DepositStore.formRenew.increasePrice || ""}`}
          isError={DepositStore.formRenewIsInvalid.increasePrice}
          isDisabled={
            DepositStore.form.productType !==
              ProductWeightTypeConstant.manual &&
            (DepositStore.formRenew.increasePriceStandard <= 0 ||
              DepositStore.formRenew.increasePriceStandard <=
                (DepositStore.form.interest || 0))
          }
          maxLength={`${DepositStore.formRenew.increasePriceStandard}`.length}
          handleOnChange={(value: number) => {
            onIncreaseDepositPriceInputChange(Number(value));
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput
          name="depositTotalPay"
          label="ให้เงินลูกค้า"
          placeholder="กรอกอัตโนมัติ"
          value={`${DepositStore.form.netPay}`}
          inputPropsClassName="input-highlight"
          isDisabled={true}
          handleOnChange={(value: number) => {}}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput
          name="newDepositPrice"
          label="ราคารับฝากใหม่"
          placeholder="กรอกอัตโนมัติ"
          value={`${DepositStore.formRenew.newDepositPrice || ""}`}
          isDisabled={true}
          handleOnChange={(value: number) => {}}
        />
      </Grid>
    </Grid>
  );
});

export default Increase;
