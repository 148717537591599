import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import dayjs from "dayjs";
import {
  Category as CategoryModel,
  Product as ProductModel,
} from "../../models/Product";
import {
  GetCategories as GetCategoriesService,
  GetProductsByCategory as GetProductsByCategoryService,
} from "../../services/MasterData";
import { Deposit as DepositModel } from "../../models/Deposit";
import { OnMappingQuantityOptions as OnMappingQuantityOptionsUtil } from "../../utils/Form";
import { DateBuddhistEra as DateBuddhistEraUtil } from "../../utils/DateFormat";
import {
  CurrentInterestStatusDeposit as CurrentInterestStatusDepositUtil,
  CalculateMaxIncreaseDepositPrice as CalculateMaxIncreaseDepositPriceUtil,
} from "../../utils/Gold";
import {
  Type as TypeGoldConstant,
  ProductWeightType as ProductWeightTypeConstant,
} from "../../constants/Gold";
import {
  RangeOfInterestStatusForCalculate as RangeOfInterestStatusForCalculateDepositConstant,
  RenewBillTypeKey as RenewBillTypeKeyConstant,
} from "../../constants/Deposit";
import { KeysType as KeysTypePaymentConstant } from "../../constants/Payment";
import SelectionDropdown from "../dropdowns/Selection";
import NumberInput from "../inputs/Number";
import TextFieldInput from "../inputs/TextField";
import NumberFieldWithButtonInput from "../inputs/NumberFieldWithButton";
import RenewBillForm from "./deposits/RenewBill";

type Props = {};

const Return = observer((props: Props) => {
  const { DepositStore, PaymentStore } = useStores();
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [products, setProducts] = useState<ProductModel[]>([]);

  useEffect(() => {
    getCategories();
    return () => {
      DepositStore.onClearStore();
    };
  }, [DepositStore]);

  useEffect(() => {
    const initFormRenewBill = async () => {
      const responseProducts: ProductModel[] = await getProductsByCategory(
        DepositStore.form.categoryCode
      );
      const form: DepositModel = { ...DepositStore.form };
      const findCategory = categories.find(
        (category) => category.code === form.categoryCode
      );
      const findProduct = responseProducts.find(
        (product) => product.code === form.productCode
      );
      form.category = findCategory;
      form.product = findProduct;
      DepositStore.onSetForm({ ...form });
      PaymentStore.onClearStore(form.netPay || 0);
    };
    if (DepositStore.isBillRenew) {
      initFormRenewBill();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DepositStore.isBillRenew]);

  const getCategories = async () => {
    const response = await GetCategoriesService(TypeGoldConstant.purchase);
    response.success && setCategories(response.data);
  };
  const getProductsByCategory = async (code: string) => {
    setProducts([]);
    const response = await GetProductsByCategoryService(
      code,
      TypeGoldConstant.purchase
    );
    setProducts(response.data);
    return response.data;
  };
  const onInputInterestChange = (newInterest: number) => {
    const form: DepositModel = { ...DepositStore.form };
    const formIsInvalid: any = JSON.parse(
      JSON.stringify({ ...DepositStore.formIsInvalid })
    );
    const formRenew: any = JSON.parse(
      JSON.stringify({ ...DepositStore.formRenew })
    );
    const formRenewIsInvalid: any = JSON.parse(
      JSON.stringify({ ...DepositStore.formRenewIsInvalid })
    );
    Object.keys(formRenewIsInvalid).forEach((key) => {
      formRenewIsInvalid[key] = false;
    });
    form.cardPay = 0;
    form.paymentType = KeysTypePaymentConstant.allCash;
    form.percentChargeCard = 0;
    form.chargeCard = 0;
    form.interest = newInterest;
    switch (form.type) {
      case RenewBillTypeKeyConstant.return:
        form.totalPay = form.depositPrice + newInterest;
        form.netPay = form.depositPrice + newInterest;
        break;
      case RenewBillTypeKeyConstant.toInterest:
        form.totalPay = newInterest;
        form.netPay = newInterest;
        break;
      case RenewBillTypeKeyConstant.reduce:
        form.totalPay = formRenew.reducePrice + form.interest;
        form.netPay = form.totalPay;
        break;
      case RenewBillTypeKeyConstant.increase:
        formRenewIsInvalid.increasePrice =
          form.productType !== ProductWeightTypeConstant.manual &&
          (formRenew.increasePrice > formRenew.increasePriceStandard ||
            formRenew.increasePrice < newInterest);
        form.netPay =
          (formRenew.increasePrice > 0 &&
            formRenew.increasePrice - form.interest) ||
          0;
        DepositStore.onSetFormRenew({ ...formRenew });
        DepositStore.onSetFormRenewIsInvalid({ ...formRenewIsInvalid });
        break;
      case RenewBillTypeKeyConstant.purchase:
        form.netPay =
          (form.totalPay || 0) -
          (form.depositPrice + Number(form.interest || 0));
        break;
      default:
        break;
    }
    form.currentInterestStatus =
      CurrentInterestStatusDepositUtil(
        newInterest,
        form.standardInterest || 0,
        form.depositPrice,
        form.percentInterest || 0
      ) || "good";
    formIsInvalid.type = false;
    formIsInvalid.cardPay = false;
    PaymentStore.onClearStore(form.netPay || 0);
    DepositStore.onSetForm({ ...form });
    DepositStore.onSetFormIsInvalid({ ...formIsInvalid });
  };
  const onInputPeriodChange = (newPeriod: number) => {
    const form: DepositModel = JSON.parse(JSON.stringify(DepositStore.form));
    const formRenew: any = JSON.parse(
      JSON.stringify({ ...DepositStore.formRenew })
    );
    const formRenewIsInvalid: any = JSON.parse(
      JSON.stringify({ ...DepositStore.formRenewIsInvalid })
    );
    form.period = newPeriod;
    form.currentStatus = "good";
    if (DepositStore.form.type === RenewBillTypeKeyConstant.increase) {
      form.totalPay = 0;
      form.netPay = 0;
      formRenew.newDepositPrice = 0;
      formRenew.reducePrice = 0;
      formRenew.increasePrice = 0;
      formRenew.increasePriceStandard = CalculateMaxIncreaseDepositPriceUtil(
        formRenew.goldPurchasePrice,
        form.productPercentage,
        form.totalWeight,
        form.period,
        form.depositPriceStandard,
        form.depositPrice
      );
      formRenew.purchasePriceStandard = 0;
      Object.keys(formRenewIsInvalid).forEach((key) => {
        formRenewIsInvalid[key] = false;
      });
      DepositStore.onSetFormRenew({ ...formRenew });
      DepositStore.onSetFormRenewIsInvalid({ ...formRenewIsInvalid });
    }
    DepositStore.onSetForm({ ...form });
  };

  return (
    <Box>
      <Grid container spacing={{ xs: 3, md: 2 }}>
        <Grid item xs={12} md={6}>
          <TextFieldInput
            name="depositDate"
            label="วันที่ฝาก"
            placeholder="กรอกอัตโนมัติ"
            value={
              (DepositStore.form.createdAt &&
                DateBuddhistEraUtil(DepositStore.form.createdAt)) ||
              ""
            }
            isDisabled={true}
            handleOnChange={(value: string) => {}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldInput
            name="returnDate"
            label="วันที่คืน"
            placeholder="กรอกอัตโนมัติ"
            value={
              (DepositStore.form.createdAt && DateBuddhistEraUtil(dayjs())) ||
              ""
            }
            isDisabled={true}
            handleOnChange={(value: string) => {}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectionDropdown
            name="category"
            label="ประเภทสินค้า"
            options={categories}
            selected={DepositStore.form.category}
            labelKey="name"
            valueKey="code"
            imageKey="img"
            placeholder="เลือกประเภทสินค้า"
            isShowImage={true}
            isError={DepositStore.formIsInvalid.categoryCode}
            isDisabled={true}
            onSelected={(selected: CategoryModel) => {}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectionDropdown
            name="product"
            label="ชื่อสินค้า"
            options={products}
            selected={DepositStore.form.product}
            labelKey="name"
            valueKey="code"
            placeholder="เลือกชื่อสินค้า"
            imageKey="img"
            isDisabled={true}
            isShowImage={true}
            isError={DepositStore.formIsInvalid.productCode}
            onSelected={(selected: ProductModel) => {}}
          />
        </Grid>
      </Grid>
      {DepositStore.form.productCode && (
        <Box marginTop={2}>
          <Grid
            container
            spacing={{ xs: 3, md: 2 }}
            rowSpacing={2}
            alignItems={"flex-end"}
          >
            <Grid item xs={12} md={6} marginTop={{ xs: 0, md: 1 }}>
              <Grid container spacing={2} alignItems={"flex-end"}>
                {/* Total Weight  */}
                <Grid item xs={12} md={6}>
                  <NumberInput
                    name="totalWeight"
                    label="น้ำหนักรวม"
                    placeholder="กรอกน้ำหนักรวม"
                    inputMode="decimal"
                    decimalScale={3}
                    value={`${DepositStore.form.totalWeight || ""}`}
                    isError={DepositStore.formIsInvalid.totalWeight}
                    isDisabled={true}
                    handleOnChange={(value: string) => {}}
                  />
                </Grid>
                {/* /.Weight Per Unit  */}

                {/* Total Quantity */}
                <Grid item xs={12} md={6}>
                  <SelectionDropdown
                    name="totalQuantity"
                    label="จำนวนรวม"
                    options={OnMappingQuantityOptionsUtil(99)}
                    selected={DepositStore.form.totalQuantity}
                    placeholder="เลือกจำนวนรวม"
                    isDisabled={true}
                    onSelected={(selected: number) => {}}
                  />
                </Grid>
                {/* /.Total Quantity */}
              </Grid>
            </Grid>
            {/* Period */}
            <Grid item xs={12} md={6} marginTop={{ xs: 0, md: 1 }}>
              <SelectionDropdown
                name="period"
                label="ระยะเวลา(เดือน)"
                options={OnMappingQuantityOptionsUtil(6)}
                selected={DepositStore.form.period}
                placeholder="เลือกระยะเวลา(เดือน)"
                isDisabled={
                  !DepositStore.form.type ||
                  DepositStore.form.type === RenewBillTypeKeyConstant.return ||
                  DepositStore.form.type === RenewBillTypeKeyConstant.new ||
                  DepositStore.form.type === RenewBillTypeKeyConstant.purchase
                }
                onSelected={(selected: number) => {
                  onInputPeriodChange(selected);
                }}
              />
            </Grid>
            {/* /.Period */}

            {/* Deposit Price Cash */}
            <Grid
              item
              xs={12}
              md={5}
              lg={6}
              marginTop={{ xs: 0, md: 1 }}
              alignSelf={"flex-end"}
            >
              <NumberInput
                name="depositPrice"
                label="ราคารับฝาก"
                placeholder="กรอกอัตโนมัติ"
                value={`${DepositStore.form.depositPrice || ""}`}
                isDisabled={true}
                handleOnChange={(value: number) => {}}
              />
            </Grid>
            {/* /.Deposit Price Cash */}

            {/* Interest */}
            <Grid item xs={12} md={7} lg={6} marginTop={{ xs: 0, md: 1 }}>
              <NumberFieldWithButtonInput
                name="interest"
                label="ดอกเบี้ยที่ต้องจ่าย"
                subLabel={
                  <>
                    {" "}
                    <Typography
                      variant="body1"
                      fontWeight={"bold"}
                      color={StyleVariables["color-primary"]}
                    >
                      {`(${DepositStore.form.percentInterest}%)`}
                    </Typography>
                  </>
                }
                helperLabel={
                  <Typography
                    variant="body2"
                    color={StyleVariables["color-header"]}
                    fontWeight={"bold"}
                  >{`(เปิด ${DepositStore.form.standardInterest})`}</Typography>
                }
                placeholder={"กรอกอัตโนมัติ"}
                value={`${
                  DepositStore.form.interest !== undefined
                    ? DepositStore.form.interest
                    : ""
                }`}
                isDecreaseDisabled={
                  (DepositStore.form.interest || 0) <= 0 ||
                  (DepositStore.form.interest || 0) <=
                    DepositStore.form.depositPrice *
                      (-((DepositStore.form.percentInterest || 0) / 100) +
                        RangeOfInterestStatusForCalculateDepositConstant.poor)
                }
                isIncreaseDisabled={
                  (DepositStore.form.interest || 0) >=
                  DepositStore.form.depositPrice *
                    ((DepositStore.form.percentInterest || 0) / 100) +
                    RangeOfInterestStatusForCalculateDepositConstant.excellent
                }
                rangeOfDecrease={10}
                rangeOfIncrease={10}
                isError={DepositStore.form.currentInterestStatus === "poor"}
                isDisabled={true}
                status={
                  DepositStore.form.interest !== undefined
                    ? DepositStore.form.currentInterestStatus
                    : ""
                }
                handleOnChange={(value: number | string) => {
                  onInputInterestChange(Number(value || 0));
                }}
                handleOnIncrease={(value: number | string) => {
                  onInputInterestChange(Number(value || 0));
                }}
                handleOnDecrease={(value: number | string) => {
                  onInputInterestChange(Number(value || 0));
                }}
                handleOnClear={() => onInputInterestChange(0)}
              />
            </Grid>
            {/* /.Interest */}
          </Grid>
          {DepositStore.isBillRenew ? <RenewBillForm /> : null}
        </Box>
      )}
    </Box>
  );
});

export default Return;
