import StyleVariables from "../assets/styles/Variable.module.scss";

const Modal: any = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        border: `1px solid ${StyleVariables["color-border"]}`,
        borderRadius: "8px !important",
        boxShadow: "none !important",
        marginBottom: Number(StyleVariables.spacing) * 2,
        "&:before": {
          height: 0,
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: Number(StyleVariables.spacing) * 3,
        ".MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root": {
          fontSize: 40,
          color: StyleVariables["color-main-icon"],
        },
        cursor: "pointer",
        "&.Mui-expanded": {
          borderBottom: `1px solid ${StyleVariables["color-border"]}`,
        },
      },
      content: {
        margin: 0,
        "&.Mui-expanded": {
          margin: 0,
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        paddingTop: Number(StyleVariables.spacing) * 4,
        paddingBottom: Number(StyleVariables.spacing) * 4,
        paddingLeft: Number(StyleVariables.spacing) * 3,
        paddingRight: Number(StyleVariables.spacing) * 3,
      },
    },
  },
};

export default Modal;
