const maximumPercentGold = 100;

const rangeOfStatusForCalculateTypeMore80 = {
  excellent: -1.25,
  good: 0,
  fair: 1.25,
  poor: 2.5,
};

const rangeOfStatusForCalculateTypeLess80 = {
  excellent: -5,
  good: 0,
  fair: 5,
  poor: 10,
};

export {
  maximumPercentGold as MaximumPercentGold,
  rangeOfStatusForCalculateTypeMore80 as RangeOfStatusForCalculateTypeMore80,
  rangeOfStatusForCalculateTypeLess80 as RangeOfStatusForCalculateTypeLess80,
};
