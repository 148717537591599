import { observable, makeAutoObservable, action } from "mobx";
import { GetGoldPrice as GetGoldPriceService } from "../services/MasterData";

export default class UserStore {
  @observable isOpenSidebar: boolean = true;
  @observable goldSellNow: number = 0;
  @observable goldPurchaseNow: number = 0;
  @observable goldDateTime: string = "";
  @observable goldFetchTimeEvery: number = 300000;
  @observable goldLatestCheck: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  @action
  handleSidebar = (isOpen: boolean) => {
    this.isOpenSidebar = isOpen;
  };

  setStore(data: any) {
    this.goldSellNow = data?.goldPriceSale;
    this.goldPurchaseNow = data?.goldPricePurchase;
    this.goldDateTime = data?.goldDate;
    this.goldFetchTimeEvery = data?.goldMinutes * 60 * 1000;
    this.goldLatestCheck = data?.latestCheck;
  }

  @action
  getGoldPrice = async () => {
    const response = await GetGoldPriceService();
    if (!response.success) return false;
    this.setStore(response.data);
  };
}
