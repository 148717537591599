import React from "react";
import { RadioGroup, FormControlLabel, Radio, Typography } from "@mui/material";
import {
  Circle as CircleIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";

interface Props {
  name: string;
  value: boolean | string;
  selectedValue: boolean | string;
  label: string;
  isDisabled?: boolean;
  onChange: (value: any) => void;
}

const RadioSelection = (props: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    props.onChange(value);
  };

  return (
    <RadioGroup
      aria-labelledby="radio-buttons-label"
      value={props.selectedValue}
      name="radio-buttons-group"
      onChange={handleChange}
    >
      <FormControlLabel
        value={props.value}
        control={
          <Radio
            name={props.name}
            checkedIcon={
              <RadioButtonCheckedIcon
                sx={{
                  fontSize: 32,
                  fill: !props.isDisabled
                    ? StyleVariables["color-success"]
                    : StyleVariables["color-disable"],
                }}
              />
            }
            icon={
              !props.isDisabled ? (
                <RadioButtonUncheckedIcon
                  sx={{ fontSize: 32, color: StyleVariables["color-border"] }}
                />
              ) : (
                <CircleIcon
                  sx={{ fontSize: 32, color: StyleVariables["color-disable"] }}
                />
              )
            }
            disabled={props.isDisabled}
          />
        }
        label={
          <Typography
            variant="body2"
            fontWeight={"regular"}
            color={StyleVariables["color-text"]}
          >
            {props.label}
          </Typography>
        }
        disabled={props.isDisabled}
      />
    </RadioGroup>
  );
};

export default RadioSelection;
