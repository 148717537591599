import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
} from "@mui/material";
import StyleVariables from "../../../assets/styles/Variable.module.scss";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import {
  PaymentIsInvalid as PaymentIsInvalidModel,
  Payment as PaymentModel,
} from "../../../models/Payment";
import NumberInput from "../../inputs/Number";
import TextFieldInput from "../../inputs/TextField";
import ImageUpload from "../../uploads/Image";
import { KeysType as KeysTypeConstant } from "../../../constants/Payment";
import { OnDelete as OnDeleteStorageService } from "../../../services/Storage";

interface Props {
  type: string;
  netPay: number;
}

const CreditOrMix = observer((props: Props) => {
  const columns: {
    align: "left" | "right" | "center";
    minWidth: number;
    maxWidth?: number;
    name: string;
    sticky?: boolean;
    stickyPosition?: number;
    zIndex?: number;
  }[] = [
    {
      align: "center",
      minWidth: 180,
      name: "ช่องทาง",
      sticky: true,
      stickyPosition: 0,
      zIndex: 10,
    },
    {
      align: "center",
      minWidth: 150,
      name: "จำนวนเงิน",
      sticky: true,
      stickyPosition: 166,
      zIndex: 9,
    },
    {
      align: "center",
      minWidth: 180,
      name: "หมายเลขบัตรเครดิต",
      stickyPosition: 166,
    },
    {
      align: "center",
      minWidth: 200,
      name: "หมายเหตุ",
    },
    {
      align: "center",
      minWidth: 120,
      name: "อัปโหลดรูป",
    },
  ];
  const { PaymentStore, MainStore } = useStores();

  const onInputChange = (key: string, value: any, index: number) => {
    const arr: any = JSON.parse(JSON.stringify([...PaymentStore.list]));
    let arrIsInvalid: any = JSON.parse(
      JSON.stringify([...PaymentStore.listIsInvalid])
    );
    let totalAmount = 0;
    arr[index][key] = value;
    if (key === "amount") {
      if (props.type === KeysTypeConstant.mix) {
        totalAmount =
          arr.reduce(
            (n: number, { amount }: { amount: number }) => n + amount,
            0
          ) - arr[0].amount;
        arr[0].amount =
          props.netPay - totalAmount >= 0 ? props.netPay - totalAmount : 0;
      }
      if (!value) {
        arr[index].creditCardNumber = "";
        arr[index].remark = "";
        arr[index].amount = 0;
        arr[index].imageId = "";
        if (arr[index].imageId) OnDeleteStorageService(arr[index].imageId);
      }
    }
    arrIsInvalid = arrIsInvalid.map((item: PaymentIsInvalidModel) => {
      item.amount = false;
      return item;
    });
    PaymentStore.onSetList([...arr]);
    PaymentStore.onSetListIsInvalid([...arrIsInvalid]);
  };

  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column: any, columnIndex: number) => (
              <TableCell
                key={columnIndex}
                sx={{
                  minWidth: column.minWidth,
                  backgroundColor: StyleVariables["color-background"],
                  paddingX: 1,
                  paddingY: 3,
                  textAlign: column.align,
                  ...(column.sticky && {
                    "@media (min-width:714px)": {
                      position: "sticky",
                      left: column.stickyPosition,
                      zIndex: column.zIndex,
                      boxShadow:
                        columnIndex === 1 &&
                        StyleVariables["shadow-table-sticky"],
                    },
                    "@media (min-width: 765px)": {
                      ...(columnIndex === 1 && {
                        boxShadow: !MainStore.isOpenSidebar
                          ? "none"
                          : StyleVariables["shadow-table-sticky"],
                      }),
                    },
                    "@media (min-width: 932px)": {
                      boxShadow: "none",
                    },
                  }),
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={"bold"}
                  color={StyleVariables["color-header"]}
                >
                  {column.name}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {PaymentStore.list.map((list: PaymentModel, index: number) => {
            return (
              <TableRow key={index}>
                {/* Channel Name */}
                <TableCell
                  align={columns[0].align || "center"}
                  sx={{
                    minWidth: columns[0].minWidth,
                    paddingX: 1,
                    paddingY: 2,
                    backgroundColor: StyleVariables["color-white"],
                    "@media (min-width:714px)": {
                      position: "sticky",
                      left: columns[0].stickyPosition,
                      zIndex: columns[0].zIndex,
                    },
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={"medium"}
                    color={StyleVariables["color-text"]}
                  >
                    {list.channelName}
                  </Typography>
                </TableCell>
                {/* /.Channel Name */}

                {/* Amount */}
                <TableCell
                  align={columns[1].align || "center"}
                  sx={{
                    minWidth: columns[1].minWidth,
                    paddingX: 1,
                    paddingY: 2,
                    "@media (min-width:714px)": {
                      position: "sticky",
                      left: columns[1].stickyPosition,
                      backgroundColor: StyleVariables["color-white"],
                      zIndex: columns[1].zIndex,
                      boxShadow: StyleVariables["shadow-table-sticky"],
                    },
                    "@media (min-width: 765px)": {
                      boxShadow: !MainStore.isOpenSidebar
                        ? "none"
                        : StyleVariables["shadow-table-sticky"],
                    },
                    "@media (min-width: 932px)": {
                      boxShadow: "none",
                    },
                  }}
                >
                  <NumberInput
                    name={`${list.channelName}_amount`}
                    value={list.amount >= 0 ? `${list.amount || ""}` : ""}
                    placeholder={
                      index > 0 || props.type === KeysTypeConstant.allCreditCard
                        ? "กรอกจำนวนเงิน"
                        : "กรอกอัตโนมัติ"
                    }
                    decimalScale={0}
                    maxLength={`${props.netPay}`.length}
                    isError={
                      PaymentStore.listIsInvalid[index].amount &&
                      (props.type !== KeysTypeConstant.mix || index > 0)
                    }
                    isDisabled={
                      index === 0 && props.type === KeysTypeConstant.mix
                    }
                    handleOnChange={(value: string) =>
                      index > 0 || props.type === KeysTypeConstant.allCreditCard
                        ? onInputChange("amount", Number(value || 0), index)
                        : {}
                    }
                  />
                </TableCell>
                {/* /.Amount */}

                {/* Credit Card Number */}
                <TableCell
                  align={columns[2].align || "center"}
                  sx={{
                    minWidth: columns[2].minWidth,
                    paddingX: 1,
                    paddingY: 2,
                    backgroundColor: StyleVariables["color-white"],
                  }}
                >
                  <TextFieldInput
                    name={`${list.channelName}_credit_card_number`}
                    value={`${list.creditCardNumber}`}
                    placeholder="กรอกหมายเลขบัตร"
                    type="number"
                    isDisabled={
                      (index <= 2 && props.type === KeysTypeConstant.mix) ||
                      !list.amount
                    }
                    handleOnChange={(value: string) => {
                      onInputChange("creditCardNumber", value, index);
                    }}
                  />
                </TableCell>
                {/* /.Credit Card Number */}

                {/* Remark */}
                <TableCell
                  align={columns[2].align || "center"}
                  sx={{
                    minWidth: columns[2].minWidth,
                    paddingX: 1,
                    paddingY: 2,
                    backgroundColor: StyleVariables["color-white"],
                  }}
                >
                  <TextFieldInput
                    name={`${list.channelName}_remark`}
                    value={`${list.remark}`}
                    placeholder="กรอกหมายเหตุ"
                    isDisabled={!list.amount}
                    handleOnChange={(value: string) => {
                      onInputChange("remark", value, index);
                    }}
                  />
                </TableCell>
                {/* /.Remark */}

                {/* Upload */}
                <TableCell
                  align={columns[1].align || "center"}
                  sx={{
                    minWidth: columns[3].minWidth,
                    paddingX: 1,
                    paddingY: 2,
                    display: "flex",
                    justifyContent: "center",
                    height: 80,
                    backgroundColor: StyleVariables["color-white"],
                  }}
                >
                  <Box
                    display={"flex"}
                    minWidth={80}
                    maxWidth={80}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ImageUpload
                      storageId={list.imageId}
                      isDisabled={!list.amount}
                      onFileChange={(storageId: string) => {
                        onInputChange("imageId", storageId, index);
                      }}
                      onDelete={() => {
                        onInputChange("imageId", "", index);
                      }}
                    />
                  </Box>
                </TableCell>
                {/* /.Upload */}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default CreditOrMix;
