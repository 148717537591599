import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import {
  DateBuddhistEra as DateBuddhistEraUtil,
  DateTimeBuddhistEra as DateTimeBuddhistEraUtil,
} from "../../utils/DateFormat";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";
import { KeysType as KeysTypeConstant } from "../../constants/Payment";
import { RefInvoice as RefInvoiceConstant } from "../../constants/Gold";
import {
  RenewBillTypeKey as RenewBillTypeKeyDepositConstant,
  RenewBillTypes as RenewBillTypesDepositConstant,
} from "../../constants/Deposit";
import { Deposit as DepositModel } from "../../models/Deposit";
import { Payment as PaymentModel } from "../../models/Payment";

type Props = {
  deposit: DepositModel | null;
  payments: PaymentModel[];
};

const Return = observer((props: Props) => {
  const [payments, setPayments] = useState<any[]>([]);

  useEffect(() => {
    let mappingPayment = [];
    let totalCash =
      props.deposit?.paymentType === KeysTypeConstant.allCash ||
      props.deposit?.paymentType === KeysTypeConstant.mix
        ? props.payments[0]?.amount
        : 0;
    let totalQrCode = props.payments
      ?.filter(
        (item) =>
          item.channelName.toLocaleLowerCase().search("qr") >= 0 ||
          item.channelName.toLocaleLowerCase().search("ลูกค้าโอน") >= 0
      )
      .reduce((n, { amount }) => n + amount, 0);
    let totalCreditCard = props.payments
      ?.filter(
        (item, index) =>
          item.channelName.toLocaleLowerCase().search("qr") < 0 &&
          item.channelName.toLocaleLowerCase().search("ลูกค้าโอน") < 0 &&
          item.channelName.toLocaleLowerCase().search("เฟิร์สช้อยส์") < 0 &&
          item.channelName.toLocaleLowerCase().search("อิออน") < 0 &&
          item.channelCode !== "99"
      )
      .reduce((n, { amount }) => n + amount, 0);
    let totalFirstChoice = props.payments
      ?.filter(
        (item) =>
          item.channelName.toLocaleLowerCase().search("เฟิร์สช้อยส์") >= 0 ||
          item.channelName.toLocaleLowerCase().search("อิออน") >= 0
      )
      .reduce((n, { amount }) => n + amount, 0);

    totalCash > 0 &&
      mappingPayment.push({ channelName: "เงินสด", amount: totalCash });
    totalQrCode > 0 &&
      mappingPayment.push({ channelName: "QR Code", amount: totalQrCode });
    totalCreditCard > 0 &&
      mappingPayment.push({
        channelName: "บัตรเครดิต",
        amount: totalCreditCard,
      });
    totalFirstChoice > 0 &&
      mappingPayment.push({
        channelName: "เฟิร์สช้อยส์/อิออน",
        amount: totalFirstChoice,
      });
    setPayments([...mappingPayment]);
  }, [props.payments, props.deposit?.paymentType]);

  const nameRenewBillDeposit = () => {
    return (
      RenewBillTypesDepositConstant.find(
        (item) => item.value === props.deposit?.type
      )?.label || ""
    );
  };

  return (
    <Box color={StyleVariables["color-text"]}>
      {/* Heading */}
      <Box borderBottom={`1px dashed ${StyleVariables["color-border"]}`}>
        {/* Branch Name && Ref Invoice Number */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          marginBottom={1}
        >
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.deposit?.branchName}
          </Typography>
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.deposit?.returnInvoiceNo
              ? `${props.deposit?.returnInvoiceNo}`
              : "X-XXXXXXXX-XXXX"}
          </Typography>
        </Box>
        {/* /.Branch Name && Ref Invoice Number */}

        {/* Employee Name && Returned Date */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          marginBottom={2}
        >
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.deposit?.userFirstName}{" "}
            {props.deposit?.userLastName && props.deposit?.userLastName[0]}.
          </Typography>
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.deposit?.returnedAt
              ? DateTimeBuddhistEraUtil(props.deposit?.returnedAt)
              : `${DateBuddhistEraUtil(dayjs())} --:--`}
          </Typography>
        </Box>
        {/* /.Employee Name && Returned Date */}
      </Box>
      {/* /.Heading */}

      {/* Return Price */}
      <Box marginY={2}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={8}>
            <Typography variant="subtitle2" fontWeight={"regular"}>
              คืนบิลหมายเลข
              <br />
              {props.deposit?.refInvoiceNo?.replace(
                `${RefInvoiceConstant.deposit}-`,
                ""
              )}
            </Typography>
            <Typography variant="caption" fontWeight={"regular"} marginTop={1}>
              {"("}
              <NumericFormat
                value={props.deposit?.depositPrice}
                displayType="text"
                thousandSeparator
              />{" "}
              +{" "}
              <NumericFormat
                value={
                  (props.deposit?.interest || 0) +
                  (props.deposit?.chargeCard || 0)
                }
                displayType="text"
                thousandSeparator
              />{" "}
              ={" "}
              <NumericFormat
                value={
                  props.deposit?.type === RenewBillTypeKeyDepositConstant.return
                    ? props.deposit?.netPay
                    : (props.deposit?.depositPrice || 0) +
                      (props.deposit?.interest || 0) +
                      (props.deposit?.chargeCard || 0)
                }
                displayType="text"
                thousandSeparator
              />
              {")"}
            </Typography>
          </Grid>
          <Grid item xs={4} textAlign={"right"}>
            <Typography variant="subtitle2" fontWeight={"regular"}>
              <NumericFormat
                value={
                  props.deposit?.type === RenewBillTypeKeyDepositConstant.return
                    ? props.deposit?.netPay
                    : (props.deposit?.depositPrice || 0) +
                      (props.deposit?.interest || 0) +
                      (props.deposit?.chargeCard || 0)
                }
                displayType="text"
                thousandSeparator
              />
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* /.Return Price */}

      {/* Payment Detail */}
      <Box marginTop={2}>
        <Typography variant="subtitle2" fontWeight={"regular"}>
          รับเงินจากลูกค้าโดย
        </Typography>
        {payments.map((item, index) => {
          return (
            <Grid key={index} container marginTop={1}>
              <Grid item xs={6}>
                <Typography variant="subtitle2" fontWeight={"regular"}>
                  {item.channelName}
                </Typography>
              </Grid>
              {payments.length > 1 ? (
                <Grid item xs={6} textAlign={"right"}>
                  <Typography variant="subtitle2" fontWeight={"regular"}>
                    <NumericFormat
                      value={item.amount}
                      displayType="text"
                      thousandSeparator
                    />
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          );
        })}
      </Box>
      {/* Payment Detail */}

      {/* Renew Bill Type */}
      {props.deposit?.type !== RenewBillTypeKeyDepositConstant.return ? (
        <Box marginTop={3} marginBottom={2} textAlign={"center"}>
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.deposit?.type !== RenewBillTypeKeyDepositConstant.purchase
              ? `ไปต่อบิลใหม่ ${props.deposit?.refInvoiceNoNext?.replace(
                  `${RefInvoiceConstant.deposit}-`,
                  ""
                )} (${nameRenewBillDeposit()})`
              : "ตัดขาย"}
          </Typography>
        </Box>
      ) : null}
      {/* /.Renew Bill Type */}
    </Box>
  );
});

export default Return;
