import Axios from "../plugins/Axios";

const getGoldPrice = async () => {
  try {
    const { data } = await Axios.get("/masters/gold-prices");
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const getCategories = async (type: string) => {
  try {
    const { data } = await Axios.get(`/masters/categories/${type}`);
    const mappingData = data.data?.map((item: any) => {
      return {
        code: item.categoryCode,
        name: item.categoryName,
      };
    });
    return {
      success: true,
      data: mappingData,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: [],
    };
  }
};

const getProductsByCategory = async (categoryCode: string, type: string) => {
  try {
    const { data } = await Axios.get(
      `/masters/categories/${categoryCode}/product/${type}`
    );
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: [],
    };
  }
};

const getProductsByCode = async (productCode: string) => {
  try {
    const { data } = await Axios.get(`/masters/product/${productCode}`);
    const dataSorted =
      data.data?.length > 1
        ? data.data.sort(
            (a: any, b: any) => Number(b.weight) - Number(a.weight)
          )
        : data.data;
    return {
      success: true,
      data: dataSorted,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: [],
    };
  }
};

const getPaymentChannels = async () => {
  try {
    const { data } = await Axios.get("/masters/payment-channels");
    const mappingData = data.data?.masBank?.map((channel: any) => {
      return {
        channelCode: channel.bankId,
        channelName: channel.bankName,
        creditCardNumber: "",
        remark: "",
        amount: 0,
        imageId: "",
      };
    });
    return {
      success: true,
      data: {
        list: [...mappingData],
        percentCharge: data.data?.max || 0,
      },
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: {
        list: [],
        percentCharge: 0,
      },
    };
  }
};

const getCancelReasons = async () => {
  try {
    const { data } = await Axios.get(`/masters/cancel-reasons`);
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: [],
    };
  }
};

export {
  getGoldPrice as GetGoldPrice,
  getCategories as GetCategories,
  getProductsByCategory as GetProductsByCategory,
  getProductsByCode as GetProductsByCode,
  getPaymentChannels as GetPaymentChannels,
  getCancelReasons as GetCancelReasons,
};
