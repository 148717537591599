import React from "react";
import { Box, ClickAwayListener, Typography } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import { Menu as MenuConstant } from "../../constants/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import { useStores } from "../../stores";

type Props = {};

const Sidebar = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { MainStore } = useStores();

  const isActive = (path: string) => {
    const currentPath = `${location.pathname}/`;
    return currentPath.startsWith(`${path}`);
  };

  const handleClickAway = () => {
    window.innerWidth <= 834 && MainStore.handleSidebar(false);
  };

  const onNavigate = (menu: any) => {
    handleClickAway();
    navigate(menu.path);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        bgcolor={StyleVariables["color-white"]}
        padding={2}
        maxWidth={130}
        minHeight={"calc(100dvh - 120px)"}
        display={"flex"}
        flexDirection={"column"}
        gap={1}
        borderRight={0.5}
        borderColor={StyleVariables["color-border"]}
        boxShadow={StyleVariables["shadow-sidebar"]}
        top={{ xs: 160, md: 88 }}
        bottom={0}
        position={"fixed"}
        sx={{ overflowY: "scroll" }}
      >
        {MenuConstant.map((menu, index) => {
          return (
            <Box
              key={index}
              bgcolor={menu.bgColor}
              borderRadius={"10px"}
              minWidth={!isActive(menu.path) ? 104 : 100}
              minHeight={!isActive(menu.path) ? 104 : 100}
              sx={{ cursor: "pointer" }}
              border={{
                xs: isActive(menu.path)
                  ? `2px solid ${StyleVariables["color-black"]}`
                  : "none",
              }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              onClick={() => onNavigate(menu)}
            >
              <Typography
                variant="subtitle1"
                fontWeight={"bold"}
                lineHeight={0}
              >
                {menu.name}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </ClickAwayListener>
  );
};

export default Sidebar;
