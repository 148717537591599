import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Router as RouterModel } from "./models/Router";
import Routers from "./Router";
import { ThemeProvider, createTheme } from "@mui/material";
import Theme from "./themes";
import "../src/assets/styles/Main.scss";
import AuthGuardMiddleware from "./middlewares/AuthGuard";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const Router = () => {
    const routerMapping = Routers.map(
      ({ path, title, element, auth }: RouterModel) => {
        return (
          <Route
            key={title}
            path={`/${path}`}
            element={
              !auth ? element : <AuthGuardMiddleware children={element} />
            }
          />
        );
      }
    );
    return <Routes>{routerMapping}</Routes>;
  };

  const theme = createTheme({
    ...Theme,
  });

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ScrollToTop />
        <Router />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
