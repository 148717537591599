import axios from "axios";
import { Paths as PathsConstant } from "../constants/Route";

const Axios = axios.create();

// Request interceptor for API calls
Axios.interceptors.request.use(
  async (config: any) => {
    const accessToken = await localStorage.accessToken;
    config.baseURL = `${process.env.REACT_APP_API_URL}`;
    config.headers = {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      "request-id": (Math.random() + 1).toString(36).substring(2, 6),
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== PathsConstant.login
    ) {
      localStorage?.removeItem("accessToken");
      window.location.href = PathsConstant.login;
    }
    return Promise.reject({
      code: error.response?.data?.error?.code,
      message: error.response?.data?.message,
    });
  }
);

export default Axios;
