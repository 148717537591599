import StyleVariables from "../assets/styles/Variable.module.scss";

const Switch: any = {
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: 48,
        height: 24,
        padding: 0,
        borderRadius: 16,
      },
      switchBase: {
        padding: 2,
        "&.Mui-checked": {
          color: StyleVariables["color-success"],
          padding: "2px 6px",
        },
        "&.Mui-checked+.MuiSwitch-track": {
          backgroundColor: StyleVariables["color-success"],
          opacity: 1,
        },
      },
      thumb: {
        backgroundColor: StyleVariables["color-white"],
      },
      track: {
        backgroundColor: StyleVariables["color-placeholder"],
      },
    },
  },
};

export default Switch;
