import { observable, makeAutoObservable, action } from "mobx";
import {
  Exchange as ExchangeModel,
  ExchangeHasItem as ExchangeHasItemModel,
  ExchangeIsInvalid as ExchangeIsInvalidModel,
  ExchangeHasItemIsInvalid as ExchangeHasItemIsInvalidModel,
} from "../models/Exchange";
import {
  Product as ProductModel,
  ProductLabourByWeight as ProductLabourByWeightModel,
} from "../models/Product";
import {
  ProductWeightType as ProductWeightTypeConstant,
  TypeItem as TypeItemGoldConstant,
} from "../constants/Gold";
import { RangeOfStatusForCalculate as RangeOfStatusForCalculateSellConstant } from "../constants/Sell";
import { KeysType as KeysTypePaymentConstant } from "../constants/Payment";
import {
  CalculateGoldPriceStandard as CalculateGoldPriceStandardUtil,
  CalculateSellPriceStandard as CalculateSellPriceStandardUtil,
  CalculateExtraDiscountSellPriceForExchange as CalculateExtraDiscountSellPriceForExchangeUtil,
  CalculateExchangePriceStandard as CalculateExchangePriceStandardUtil,
  CurrentStatusSellPrice as CurrentStatusSellPriceUtil,
  CalculateWeightForExtraDiscountSellPriceToExchange as CalculateWeightForExtraDiscountSellPriceToExchangeUtil,
  CalculateSellNegotiation as CalculateSellNegotiationUtil,
} from "../utils/Gold";
import {
  PriceRoundUp as PriceRoundUpUtil,
  FixedDecimal as FixedDecimalUtil,
} from "../utils/NumberRounding";

export default class ExchangeStore {
  private initExchangeHasItem: ExchangeHasItemModel = {
    categoryCode: "",
    categoryName: "",
    productCode: "",
    productName: "",
    productPercentage: 0,
    productType: "",
    type: TypeItemGoldConstant.old,
    weight: 0,
    quantity: 1,
    labourStandard: 0,
    labour: 0,
    goldPrice: 0,
    priceStandard: 0,
    price: 0,
    priceNegotiation: 0,
  };
  private initExchangeHasItemIsInvalid: ExchangeHasItemIsInvalidModel = {
    categoryCode: false,
    productCode: false,
    weight: false,
    price: false,
  };
  private initForm: ExchangeModel = {
    goldSellPrice: 0,
    goldPurchasePrice: 0,
    goldPriceDateTime: "",
    oldTotalQuantity: 1,
    oldTotalWeight: 0,
    oldTotalGoldPrice: 0,
    oldTotalLabourStandard: 0,
    oldTotalLabour: 0,
    oldTotalPrice: 0,
    newTotalQuantity: 1,
    newTotalWeight: 0,
    newTotalGoldPrice: 0,
    newTotalLabourStandard: 0,
    newTotalLabour: 0,
    newTotalPrice: 0,
    totalExtraDiscountStandard: 0,
    totalPriceStandard: 0,
    totalPay: 0,
    totalPriceNegotiation: 0,
    cardPay: 0,
    paymentType: KeysTypePaymentConstant.allCash,
    chargeCard: 0,
    netPay: 0,
    exchangeOldHasItems: [
      {
        ...this.initExchangeHasItem,
      },
    ],
    exchangeNewHasItems: [
      {
        ...this.initExchangeHasItem,
      },
    ],
    productImageId: "",
    currentStatus: "good",
    standardExcellent: 0, // ราคามาตรฐานเงินสด (excellent)
    standardGood: 0, // ราคามาตรฐานเงินสด (good)
    standardFair: 0, // ราคามาตรฐานเงินสด (fair)
    standardPoor: 0, // ราคามาตรฐานเงินสด (poor)
  };
  private initFormIsInvalid: ExchangeIsInvalidModel = {
    totalPay: false,
    cardPay: false,
  };
  private initFormHasItemsIsInvalid: ExchangeHasItemIsInvalidModel[] = [
    {
      ...this.initExchangeHasItemIsInvalid,
    },
  ];

  @observable isShowAll: boolean = true;
  @observable form: ExchangeModel = { ...this.initForm };
  @observable formIsInvalid: ExchangeIsInvalidModel = {
    ...this.initFormIsInvalid,
  };
  @observable formHasItemsIsInvalidOld: ExchangeHasItemIsInvalidModel[] = [
    ...this.initFormHasItemsIsInvalid,
  ];
  @observable formHasItemsIsInvalidNew: ExchangeHasItemIsInvalidModel[] = [
    ...this.initFormHasItemsIsInvalid,
  ];
  @observable products: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  onSetIsShowAll(isShowAll: boolean) {
    this.isShowAll = isShowAll;
  }

  @action
  onClearStore(isClearAll?: boolean) {
    this.form = JSON.parse(
      JSON.stringify({
        ...this.initForm,
        ...(!isClearAll && { productImageId: this.form.productImageId }),
      })
    );
    this.formIsInvalid = JSON.parse(
      JSON.stringify({ ...this.initFormIsInvalid })
    );
    this.formHasItemsIsInvalidOld = JSON.parse(
      JSON.stringify([...this.initFormHasItemsIsInvalid])
    );
    this.formHasItemsIsInvalidNew = JSON.parse(
      JSON.stringify([...this.initFormHasItemsIsInvalid])
    );
    this.products = [];
  }
  @action
  onClearPayment(newNetPay: number) {
    this.form.cardPay = 0;
    this.form.paymentType = KeysTypePaymentConstant.allCash;
    this.form.percentChargeCard = 0;
    this.form.netPay = newNetPay;
  }
  @action
  onClearExchangeHasItemsNew() {
    this.form.newTotalQuantity = 1;
    this.form.newTotalWeight = 0;
    this.form.newTotalGoldPrice = 0;
    this.form.newTotalLabourStandard = 0;
    this.form.newTotalLabour = 0;
    this.form.newTotalPrice = 0;
    this.form.exchangeNewHasItems = JSON.parse(
      JSON.stringify([
        { ...this.initExchangeHasItem, type: TypeItemGoldConstant.new },
      ])
    );
    this.formHasItemsIsInvalidNew = JSON.parse(
      JSON.stringify([...this.initFormHasItemsIsInvalid])
    );
    this.products = [];
  }
  @action
  onClearFormIsInvalid() {
    this.formIsInvalid = JSON.parse(
      JSON.stringify({ ...this.initFormIsInvalid })
    );
  }

  @action
  onSetForm(form: ExchangeModel) {
    this.form = { ...form };
  }
  @action
  onSetFormIsInvalid(formIsInvalid: ExchangeIsInvalidModel) {
    this.formIsInvalid = { ...formIsInvalid };
  }
  @action
  onSetFormHasItemIsInvalidOld(
    formHasItemsIsInvalidOld: ExchangeHasItemIsInvalidModel,
    index: number
  ) {
    this.formHasItemsIsInvalidOld[index] = { ...formHasItemsIsInvalidOld };
  }
  @action
  onSetFormHasItemIsInvalidNew(
    formHasItemsIsInvalidNew: ExchangeHasItemIsInvalidModel,
    index: number
  ) {
    this.formHasItemsIsInvalidNew[index] = { ...formHasItemsIsInvalidNew };
  }
  @action
  onSetProducts(value: any[]) {
    this.products = value;
  }

  @action
  findLabourStandardByWeight(weight: number, product?: ProductModel) {
    const findLabourProductByWeight = product?.labourByWeight?.find(
      (productLabour: ProductLabourByWeightModel) =>
        weight >= Number(productLabour.minWeight) &&
        weight <= Number(productLabour.maxWeight)
    );
    return findLabourProductByWeight;
  }

  @action
  findLabourByRatioWeight(type: string, weight: number) {
    let findLabour;
    switch (type) {
      case ProductWeightTypeConstant.equalWeight:
        findLabour = this.products.find(
          (product: any) => Number(product.weight) <= weight
        );
        return Number(findLabour?.masLabour?.labour || 0);
      case ProductWeightTypeConstant.notEqualWeight:
        findLabour = this.products[0]?.masLabour2?.find(
          (product: any) =>
            weight >= Number(product.weight1) &&
            weight <= Number(product.weight2)
        );
        return Number(findLabour?.labour || 0);
      default:
        break;
    }
  }

  @action
  async onSetExchangeProductEqualWeight(
    product: ProductModel,
    goldSellNow: number,
    goldPurchaseNow: number
  ) {
    const goldPriceStandard = FixedDecimalUtil(
      CalculateGoldPriceStandardUtil(
        goldSellNow,
        product.percentageGold,
        this.form.exchangeNewHasItems[0].categoryCode === "11",
        1,
        Number(product.weight)
      ),
      2
    );
    const weightForCalculateExtraDiscount =
      CalculateWeightForExtraDiscountSellPriceToExchangeUtil(
        this.form.oldTotalWeight
      );
    const labourByRatioWeight = this.findLabourByRatioWeight(
      ProductWeightTypeConstant.equalWeight,
      weightForCalculateExtraDiscount
    );
    let extraDiscountSellPrice = FixedDecimalUtil(
      CalculateExtraDiscountSellPriceForExchangeUtil(
        labourByRatioWeight || 0,
        this.form.oldTotalWeight
      ),
      2
    );
    const finalLabour = product.labour - extraDiscountSellPrice;
    const sellPriceStandard = CalculateSellPriceStandardUtil(
      goldPriceStandard,
      finalLabour,
      1,
      true
    );
    const exchangePriceStandard = CalculateExchangePriceStandardUtil(
      sellPriceStandard,
      this.form.oldTotalPrice
    );
    const priceNegotiation = CalculateSellNegotiationUtil(
      Number(product.labour)
    );
    const totalFinalLabour = finalLabour * this.form.newTotalQuantity;
    const labourAfterIncreasePriceNegotiation = finalLabour + priceNegotiation;
    this.form.exchangeNewHasItems[0].product = product;
    this.form.exchangeNewHasItems[0].productCode = product.code;
    this.form.exchangeNewHasItems[0].productName = product.name;
    this.form.exchangeNewHasItems[0].productPercentage = product.percentageGold;
    this.form.exchangeNewHasItems[0].productType =
      ProductWeightTypeConstant.equalWeight;
    this.form.exchangeNewHasItems[0].type = TypeItemGoldConstant.new;
    this.form.exchangeNewHasItems[0].weight = product.weight;
    this.form.exchangeNewHasItems[0].quantity = this.form.newTotalQuantity;
    this.form.exchangeNewHasItems[0].labourStandard = product.labour;
    this.form.exchangeNewHasItems[0].labour =
      labourAfterIncreasePriceNegotiation;
    this.form.exchangeNewHasItems[0].goldPrice = goldPriceStandard;
    this.form.exchangeNewHasItems[0].priceStandard = FixedDecimalUtil(
      goldPriceStandard + product.labour,
      2
    );
    this.form.exchangeNewHasItems[0].priceNegotiation = priceNegotiation;
    this.form.exchangeNewHasItems[0].price =
      sellPriceStandard + priceNegotiation;
    this.form.totalExtraDiscountStandard = extraDiscountSellPrice;
    this.form.totalPriceStandard = exchangePriceStandard;
    this.form.totalPay = exchangePriceStandard + priceNegotiation;
    this.form.netPay = exchangePriceStandard + priceNegotiation;
    this.form.newTotalWeight = product.weight * this.form.newTotalQuantity;
    this.form.newTotalGoldPrice =
      PriceRoundUpUtil(sellPriceStandard - finalLabour) *
      this.form.newTotalQuantity;
    this.form.newTotalLabourStandard =
      product.labour * this.form.newTotalQuantity;
    this.form.newTotalLabour =
      labourAfterIncreasePriceNegotiation * this.form.newTotalQuantity;
    this.form.newTotalPrice =
      this.form.newTotalGoldPrice + this.form.newTotalLabour;
    this.form.totalPriceNegotiation =
      priceNegotiation * this.form.newTotalQuantity;
    this.form.standardExcellent = FixedDecimalUtil(
      this.form.newTotalLabour *
        RangeOfStatusForCalculateSellConstant.excellent,
      2
    );
    this.form.standardGood = FixedDecimalUtil(
      totalFinalLabour * RangeOfStatusForCalculateSellConstant.good,
      2
    );
    this.form.standardFair = FixedDecimalUtil(
      totalFinalLabour * RangeOfStatusForCalculateSellConstant.fair,
      2
    );
    this.form.standardPoor = FixedDecimalUtil(
      totalFinalLabour * RangeOfStatusForCalculateSellConstant.poor,
      2
    );
    this.form.currentStatus = CurrentStatusSellPriceUtil(
      this.form.newTotalLabour,
      this.form.standardExcellent,
      this.form.standardGood,
      this.form.standardFair
    );
  }
  @action
  onSetExchangeProductNotEqualWeight(
    product: ProductModel,
    goldSellNow: number,
    goldPurchaseNow: number
  ) {
    const weightForCalculateExtraDiscount =
      CalculateWeightForExtraDiscountSellPriceToExchangeUtil(
        this.form.oldTotalWeight
      );
    const labourByRatioWeight = this.findLabourByRatioWeight(
      ProductWeightTypeConstant.notEqualWeight,
      weightForCalculateExtraDiscount
    );
    const labourStandardForExtraDiscount = Number(labourByRatioWeight || 0);
    this.form.exchangeNewHasItems[0].product = product;
    this.form.exchangeNewHasItems[0].productCode = product.code;
    this.form.exchangeNewHasItems[0].productName = product.name;
    this.form.exchangeNewHasItems[0].productPercentage = product.percentageGold;
    this.form.exchangeNewHasItems[0].productType =
      ProductWeightTypeConstant.notEqualWeight;
    this.form.exchangeNewHasItems[0].type = TypeItemGoldConstant.new;
    this.form.totalExtraDiscountStandard =
      CalculateExtraDiscountSellPriceForExchangeUtil(
        labourStandardForExtraDiscount,
        this.form.oldTotalWeight
      );
    this.form.currentStatus = "good";
    this.form.standardExcellent = 0;
    this.form.standardGood = 0;
    this.form.standardFair = 0;
    this.form.standardPoor = 0;
  }
  @action
  onSetExchangeProductManual(
    product: ProductModel,
    goldSellNow: number,
    goldPurchaseNow: number
  ) {}

  @action
  onAddExchangeNewHasItems() {
    if (
      !this.form.exchangeNewHasItems.length ||
      this.form.exchangeNewHasItems[this.form.exchangeNewHasItems.length - 1]
        .weight
    ) {
      const firstExchangeNewHasItem: ExchangeHasItemModel =
        this.form.exchangeNewHasItems[0];
      this.form.exchangeNewHasItems.push({
        ...this.initExchangeHasItem,
        category: firstExchangeNewHasItem.category,
        categoryCode: firstExchangeNewHasItem.categoryCode,
        categoryName: firstExchangeNewHasItem.categoryName,
        product: firstExchangeNewHasItem.product,
        productCode: firstExchangeNewHasItem.productCode,
        productName: firstExchangeNewHasItem.productName,
        productPercentage: firstExchangeNewHasItem.productPercentage,
        productType: firstExchangeNewHasItem.productType,
        type: TypeItemGoldConstant.new,
      });
      this.formHasItemsIsInvalidNew.push({
        ...this.initExchangeHasItemIsInvalid,
      });
      this.form.newTotalQuantity += 1;
    } else {
      this.formHasItemsIsInvalidNew[
        this.form.exchangeNewHasItems.length - 1
      ].weight = true;
    }
  }
  @action
  onDeleteFormHasItemIsInvalidNew(index: number) {
    this.formHasItemsIsInvalidNew.splice(index, 1);
  }
  @action
  onCheckConditionForExchange() {
    return (
      this.form.newTotalWeight >= this.form.oldTotalWeight &&
      this.form.exchangeOldHasItems[0].productPercentage >= 92 &&
      this.form.exchangeOldHasItems[0].productPercentage < 100 &&
      this.form.exchangeNewHasItems[0].productPercentage === 100
    );
  }
  @action
  onValidateFormOld() {
    let isValid: boolean = true;
    const formObj: any = { ...this.form };
    const formHasItemsIsInvalidOldArr: any[] = [
      ...this.formHasItemsIsInvalidOld,
    ];
    formHasItemsIsInvalidOldArr.forEach(
      (obj: ExchangeHasItemIsInvalidModel, index: number) => {
        obj.categoryCode = !formObj.exchangeOldHasItems[index].categoryCode;
        obj.productCode = !formObj.exchangeOldHasItems[index].productCode;
        obj.weight = Boolean(
          obj.weight || !formObj.exchangeOldHasItems[index].weight
        );
        obj.price = Boolean(!formObj.exchangeOldHasItems[index].price);
        if (isValid)
          isValid = Boolean(
            !obj.categoryCode && !obj.productCode && !obj.weight && !obj.price
          );
      }
    );
    this.formHasItemsIsInvalidOld = [...formHasItemsIsInvalidOldArr];
    return isValid;
  }
  @action
  onValidateFormNew() {
    let isValid: boolean = true;
    const formObj: any = { ...this.form };
    const formIsInvalidObj: any = { ...this.formIsInvalid };
    const formHasItemsIsInvalidNewArr: any[] = [
      ...this.formHasItemsIsInvalidNew,
    ];
    isValid = formObj.newTotalLabour >= 0;
    Object.keys(formIsInvalidObj).forEach((key) => {
      formIsInvalidObj[key] = Boolean(!formObj[key]);
      if (key === "cardPay")
        formIsInvalidObj[key] =
          formObj.paymentType === KeysTypePaymentConstant.mix &&
          formObj.cardPay >= formObj.totalPay;
      if (isValid) isValid = Boolean(!formIsInvalidObj[key]);
    });
    formHasItemsIsInvalidNewArr.forEach(
      (obj: ExchangeHasItemIsInvalidModel, index: number) => {
        obj.categoryCode = !formObj.exchangeNewHasItems[index].categoryCode;
        obj.productCode = !formObj.exchangeNewHasItems[index].productCode;
        obj.weight = Boolean(
          obj.weight || !formObj.exchangeNewHasItems[index].weight
        );
        obj.price = Boolean(!formObj.exchangeNewHasItems[index].price);
        if (isValid)
          isValid = Boolean(
            !obj.categoryCode && !obj.productCode && !obj.weight && !obj.price
          );
      }
    );
    this.formIsInvalid = { ...formIsInvalidObj };
    this.formHasItemsIsInvalidNew = [...formHasItemsIsInvalidNewArr];
    return isValid;
  }

  @action
  getBody() {
    const body = {
      totalPay: this.form.totalPay,
      dailyGoldTime: this.form.goldPriceDateTime,
      new: {
        productCode: this.form.exchangeNewHasItems[0].productCode,
        totalWeight: this.form.newTotalWeight,
        totalQuantity: this.form.newTotalQuantity,
        totalLabour: this.form.newTotalLabour,
        totalLabourStandard: this.form.newTotalLabourStandard,
        productImageId: this.form.productImageId,
        item: this.form.exchangeNewHasItems.map((item) => {
          return {
            quantity: item.quantity,
            price: item.price,
            weight: item.weight,
          };
        }),
      },
      old: {
        productCode: this.form.exchangeOldHasItems[0].productCode,
        totalWeight: this.form.oldTotalWeight,
        totalQuantity: this.form.oldTotalQuantity,
        item: this.form.exchangeOldHasItems.map((item) => {
          return {
            quantity: item.quantity,
            price: item.price,
            weight: item.weight,
          };
        }),
      },
      payment: {
        paymentType: this.form.paymentType,
        netPay: this.form.netPay,
        ...(this.form.cardPay &&
          this.form.chargeCard && {
            creditCard: {
              cardPay: this.form.cardPay,
              chargeCard: this.form.chargeCard,
            },
          }),
      },
    };
    return body;
  }
}
