import { observable, makeAutoObservable, action } from "mobx";
import { Login as LoginService, GetMe as GetMeService } from "../services/User";
import { Login as LoginModel, User as UserModel } from "../models/User";
import { Paths as PathsConstant } from "../constants/Route";

export default class UserStore {
  @observable isLoggedIn: boolean = false;
  @observable user: UserModel = {
    employeeCode: "",
    employeeFirstName: "",
    employeeLastName: "",
    companyName: "",
    companyCode: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  onSetIsLoggedIn(value: boolean) {
    this.isLoggedIn = value;
  }

  onSetUser(user: UserModel) {
    this.user = { ...user };
  }

  @action
  handleLogin = async (body: LoginModel) => {
    const response = await LoginService(body);
    if (!response.success) return { ...response };
    localStorage.setItem("accessToken", response.data?.accessToken);
    return this.getMe();
  };

  @action
  getMe = async () => {
    const response = await GetMeService();
    if (!response.success) {
      this.handleLogout();
      return false;
    }
    const splitEmployeeName = response.data?.employeeName?.split(" ");
    this.onSetIsLoggedIn(true);
    this.onSetUser({
      employeeCode: response.data?.employeeCode,
      employeeFirstName: (splitEmployeeName && splitEmployeeName[0]) || "",
      employeeLastName: (splitEmployeeName && splitEmployeeName[1]) || "",
      companyName: response.data?.companyName,
      companyCode: response.data?.companyCode,
    });
    return response;
  };

  @action
  handleLogout = () => {
    this.onSetIsLoggedIn(false);
    localStorage.removeItem("accessToken");
    window.location.href = PathsConstant.login;
  };
}
