import React from "react";
import { Router as RouterModel } from "./models/Router";
import Login from "./pages/Login";
import { Paths as PathsConstant } from "./constants/Route";
import NotFound from "./pages/NotFound";
import Sell from "./pages/Sell";
import Purchase from "./pages/Purchase";
import Deposit from "./pages/Deposit";
import Exchange from "./pages/Exchange";
import Return from "./pages/Return";

const Routers: RouterModel[] = [
  {
    path: PathsConstant.notFound,
    element: <NotFound />,
    title: "NotFound",
  },
  {
    path: PathsConstant.login,
    element: <Login />,
    title: "Login",
  },
  {
    path: PathsConstant.sell,
    element: <Sell />,
    title: "Sell",
    auth: true,
  },
  {
    path: PathsConstant.exchange,
    element: <Exchange />,
    title: "Exchange",
    auth: true,
  },
  {
    path: PathsConstant.purchase,
    element: <Purchase />,
    title: "Purchase",
    auth: true,
  },
  {
    path: PathsConstant.deposit,
    element: <Deposit />,
    title: "Deposit",
    auth: true,
  },
  {
    path: PathsConstant.return,
    element: <Return />,
    title: "Return",
    auth: true,
  },
];

export default Routers;
