import React, { useState } from "react";
import MainLayout from "../layouts/Main";
import { Box, Button, Typography, Grid, Switch, Drawer } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import StyleVariables from "../assets/styles/Variable.module.scss";
import SimpleAccordion from "../components/accordions/Simple";
import SellForm from "../components/forms/sells/Sell";
import CustomerForm from "../components/forms/Customer";
import PaymentForm from "../components/forms/Payment";
import BillModal from "../components/modals/Bill";
import SellBill from "../components/bills/Sell";
import SellAndBuyListTable from "../components/tables/SellAndBuyList";
import CancelBillModal from "../components/modals/CancelBill";
import { observer } from "mobx-react";
import { useStores } from "../stores";
import { ChargeRoundUp as ChargeRoundUpUtil } from "../utils/NumberRounding";
import {
  ScrollToTop as ScrollToTopUtil,
  ScrollToElementId as ScrollToElementIdUtil,
} from "../utils/ScrollToElement";
import { KeysType as KeysTypeConstant } from "../constants/Payment";
import {
  GetLatestTrades as GetLatestTradesService,
  GetTradeById as GetTradeByIdService,
  CreateTrade as CreateTradeService,
  SubmitTradeById as SubmitTradeByIdService,
  CancelTrade as CancelTradeService,
} from "../services/Trade";
import { OnPrintPdf as OnPrintPdfService } from "../services/Storage";
import {
  Trade as TradeModel,
  CreateTrade as CreateTradeModel,
  CancelTrade as CancelTradeModel,
} from "../models/Trade";
import { Payment as PaymentModel } from "../models/Payment";
import { CancelReasonForm as CancelReasonFormModel } from "../models/MasterData";
import { Create as CreateCustomerModel } from "../models/Customer";
import { Type as TypeGoldConstant } from "../constants/Gold";
import LoadProgressing from "../components/LoadProgressing";
import InformationModal from "../components/modals/Information";
import AlertSnackbar from "../components/snackbars/Alert";
import { Create as CreateCustomerService } from "../services/Customer";

type Props = {};

const Sell = observer((props: Props) => {
  const { MainStore, TradeStore, PaymentStore, CustomerStore } = useStores();
  const [isBillModalOpen, setIsBillModalOpen] = useState<boolean>(false);
  const [isOpenList, setIsOpenList] = useState<boolean>(false);
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false);
  const [trades, setTrades] = useState<any[]>([]);
  const [isCompleteBillModalOpen, setIsCompleteBillModalOpen] =
    useState<boolean>(false);
  const [trade, setTrade] = useState<TradeModel | null>(null);
  const [payments, setPayments] = useState<PaymentModel[]>([]);
  const [isCancelBillModalOen, setIsCancelBillModalOpen] =
    useState<boolean>(false);
  const [isLoadProgressing, setIsLoadProgressing] = useState<boolean>(false);
  const [isFailModalOpen, setIsFailModalOpen] = useState<boolean>(false);
  const [scrollToElementId, setScrollToElementId] = useState<string>("");
  const [messageFailModal, setMessageFailModal] = useState<string>("");
  const [isOpenSuccessSnackbar, setIsOpenSuccessSnackbar] =
    useState<boolean>(false);

  const onSubmit = () => {
    const isValidFormProduct = TradeStore.onValidateForm();
    const isValidFormPayment = PaymentStore.onValidateForm(
      TradeStore.form.paymentType,
      TradeStore.form.cardPay || 0,
      TradeStore.form.chargeCard || 0,
      TradeStore.form.netPay
    );
    if (!isValidFormProduct) {
      setScrollToElementId("sell-information-content");
      setMessageFailModal(
        TradeStore.form.totalLabour >= 0
          ? "กรุณาตรวจสอบข้อมูลสินค้าให้ถูกต้อง และลองทำรายการใหม่อีกครั้ง"
          : "ค่ากำเหน็จห้ามต่ำกว่า 0 กรุณาตรวจสอบข้อมูลสินค้าให้ถูกต้อง และลองทำรายการใหม่อีกครั้ง"
      );
      setIsFailModalOpen(true);
      return;
    }
    if (!isValidFormPayment) {
      setScrollToElementId("payment-information-content");
      setMessageFailModal(
        "กรุณาตรวจสอบข้อมูลการชำระเงินให้ถูกต้อง และลองทำรายการใหม่อีกครั้ง"
      );
      setIsFailModalOpen(true);
      return;
    }
    setIsBillModalOpen(true);
  };

  const onTradeInputChange = (key: string, value: any) => {
    TradeStore.onClearFormIsInvalid();
    const formObj: any = { ...TradeStore.form };
    const formIsInvalidObj: any = { ...TradeStore.formIsInvalid };
    formObj[key] = value;
    switch (key) {
      case "paymentType":
        if (value !== KeysTypeConstant.allCreditCard) {
          formObj.cardPay = 0;
          formObj.percentChargeCard = 0;
          formObj.chargeCard = 0;
          formObj.netPay = formObj.totalPay;
        } else {
          formObj.cardPay = formObj.totalPay;
          formObj.percentChargeCard = PaymentStore.percentCharge;
          formObj.chargeCard = ChargeRoundUpUtil(
            formObj.totalPay * (PaymentStore.percentCharge / 100)
          );
          formObj.netPay = formObj.totalPay + formObj.chargeCard;
        }
        PaymentStore.onClearStore(formObj.totalPay);
        PaymentStore.onTypeChange(value, formObj.netPay);
        break;

      case "cardPay":
        formObj.cardPay = value;
        formObj.percentChargeCard = PaymentStore.percentCharge;
        formObj.chargeCard = ChargeRoundUpUtil(
          value * (PaymentStore.percentCharge / 100)
        );
        formObj.netPay = formObj.totalPay + formObj.chargeCard;
        formIsInvalidObj.cardPay =
          formObj.paymentType === KeysTypeConstant.mix &&
          value >= formObj.totalPay;
        PaymentStore.onTypeChange(formObj.paymentType, formObj.netPay);
        break;
      default:
        break;
    }
    TradeStore.onSetForm({ ...formObj });
    TradeStore.onSetFormIsInvalid({ ...formIsInvalidObj });
  };

  const onReset = () => {
    TradeStore.onClearStore();
    PaymentStore.onClearStore(0);
    CustomerStore.onClearStore();
    ScrollToTopUtil();
  };

  const onOpenList = () => {
    getTrades();
    setIsOpenList(true);
  };

  const onCloseList = () => {
    setIsOpenList(false);
    setTrades([]);
  };

  const getTrades = async () => {
    setIsLoadingList(true);
    const response = await GetLatestTradesService(TypeGoldConstant.sell);
    response.success && setTrades(response.data);
    setIsLoadingList(false);
  };

  const onOpenCompleteBillModal = async (id: string) => {
    setIsLoadProgressing(true);
    const response = await GetTradeByIdService(id);
    setIsLoadProgressing(false);
    const { payments, ...data } = response.data;
    if (response.success) {
      setIsOpenList(false);
      setTrade({
        type: "",
        currentStatus: "good",
        standardExcellent: 0,
        standardGood: 0,
        standardFair: 0,
        standardPoor: 0,
        ...data,
      });
      setPayments([...payments]);
      setIsCompleteBillModalOpen(true);
    }
  };
  const onCloseCompleteBillModal = () => {
    setIsCompleteBillModalOpen(false);
    setTrade(null);
    setPayments([]);
  };

  const onOpenCancelBillModal = (trade: TradeModel) => {
    onCloseList();
    setTrade(trade);
    setIsCancelBillModalOpen(true);
  };

  const onCloseCancelBillModal = () => {
    setIsCancelBillModalOpen(false);
    setTrade(null);
  };

  const onCreateSell = async () => {
    setIsBillModalOpen(false);
    setIsLoadProgressing(true);
    const customerBody: CreateCustomerModel | null = CustomerStore.getBody();
    const tradeBody = TradeStore.getBody();
    const body: CreateTradeModel = {
      ...tradeBody,
      payment: { ...tradeBody.payment, channel: PaymentStore.getBodyChannel() },
    };
    let customerId: string = "";
    // Create Customer
    if (customerBody) {
      const responseCustomer = await onCreateCustomer(customerBody);
      if (!responseCustomer) return;
      customerId = responseCustomer?.id;
    }
    // /.Create Customer

    // Create Trade
    const responseTrade = await onCreateTrade({
      ...body,
      ...(customerId && { customerId }),
    });
    if (!responseTrade) return;
    // /.Create Trade

    // Submit PDF
    await onSubmitTrade(responseTrade.id);
    // /.Submit PDF

    onReset();
    TradeStore.onSetIsShowAll(true);
    setIsLoadProgressing(false);
    setIsOpenSuccessSnackbar(true);
  };

  const onCreateCustomer = async (body: CreateCustomerModel) => {
    const response = await CreateCustomerService(body);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message || "สร้างข้อมูลลูกค้าไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
    return response.data;
  };

  const onCreateTrade = async (body: CreateTradeModel) => {
    const response = await CreateTradeService(TypeGoldConstant.sell, body);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message || "สร้างข้อมูลการขายไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
    return response.data;
  };

  const onSubmitTrade = async (id: string) => {
    const response = await SubmitTradeByIdService(id);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message ||
          "เกิดข้อผิดพลาดในการสร้างบิล กรุณาพิมพ์บิลใหม่ในรายการประวัติการขาย"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
  };

  const onCloseFailModal = () => {
    setIsFailModalOpen(false);
    setMessageFailModal("");
    scrollToElementId && ScrollToElementIdUtil(scrollToElementId);
    setScrollToElementId("");
  };

  const onCancelTrade = async (form: CancelReasonFormModel) => {
    const body: CancelTradeModel = {
      cancelReasonId: form.reason.id,
      cancelMisc: form.misc,
    };
    if (trade?.id) {
      const response = await CancelTradeService(trade?.id, body);
      onCloseCancelBillModal();
      if (!response?.success) {
        setMessageFailModal(
          `${
            response.message || "ยกเลิกรายการขายไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
          } (${response.code})`
        );
        setIsFailModalOpen(true);
        return;
      }
      onOpenList();
    }
  };

  const onPrintBillPdfHistory = async (id: string) => {
    setIsCompleteBillModalOpen(false);
    setIsLoadProgressing(true);
    const response = await OnPrintPdfService(id);
    setIsLoadProgressing(false);
    if (!response.success) {
      setMessageFailModal(
        `${response.message || "เกิดข้อผิดพลาดในการพิมพ์ข้อมูลบิลการขาย"} (${
          response.code
        })`
      );
      setIsFailModalOpen(true);
      return false;
    }
  };

  return (
    <MainLayout>
      <>
        <Box
          display={{
            xs: MainStore.isOpenSidebar ? "block" : "flex",
            md: "flex",
          }}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={2}
          textAlign={"center"}
        >
          <Typography
            variant="h6"
            fontWeight={"bold"}
            color={StyleVariables["color-header"]}
          >
            การขาย
          </Typography>
          <Box>
            <Button variant="contained-sell" onClick={onOpenList}>
              ดูประวัติการขาย
            </Button>
          </Box>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={2}
          marginY={4}
        >
          <Switch
            name="showAllInformation"
            checked={TradeStore.isShowAll}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              TradeStore.onSetIsShowAll(event.target.checked)
            }
          />
          <Typography
            variant="h6"
            fontWeight={"bold"}
            color={StyleVariables["color-header"]}
          >
            แสดงข้อมูลทั้งหมด
          </Typography>
        </Box>
        <Box>
          {/* Form Product */}
          <SimpleAccordion
            id="sell-information"
            title="ข้อมูลสินค้า"
            defaultExpanded={true}
          >
            <SellForm />
          </SimpleAccordion>
          {/* Form Product */}

          {/* Form Payment */}
          <SimpleAccordion
            id="payment-information"
            title="ข้อมูลการชำระเงิน"
            defaultExpanded={true}
          >
            <PaymentForm
              type={TradeStore.form.paymentType}
              netPay={TradeStore.form.netPay}
              cardPay={Number(TradeStore.form.cardPay || 0)}
              chargeCard={TradeStore.form.chargeCard || 0}
              cardPayIsError={TradeStore.formIsInvalid.cardPay}
              onTypeChange={(value: string) => {
                onTradeInputChange("paymentType", value);
              }}
              onCardPayChange={(value: number) => {
                onTradeInputChange("cardPay", value);
              }}
            />
          </SimpleAccordion>
          {/* /.Form Payment */}

          {/* Form Customer */}
          <SimpleAccordion
            id="customer-information"
            title="ข้อมูลลูกค้า/ภาพสินค้า"
          >
            <CustomerForm type="TRADE" />
          </SimpleAccordion>
          {/* /.Form Customer */}
        </Box>

        {/* Button */}
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          marginTop={3}
          flexDirection={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} md={6} lg={4}>
            <Button variant="outlined" fullWidth onClick={onReset}>
              เริ่มใหม่
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Button variant="contained-success" fullWidth onClick={onSubmit}>
              ทำรายการ
            </Button>
          </Grid>
        </Grid>
        {/* /.Button */}

        {/* Preview Bill Modal */}
        <BillModal
          isOpen={isBillModalOpen}
          title={"ขาย"}
          onSaveAndPrint={onCreateSell}
          onClose={() => {
            setIsBillModalOpen(false);
          }}
        >
          <SellBill trade={TradeStore.form} payments={PaymentStore.list} />
        </BillModal>
        {/* Preview Bill Modal */}

        {/*  Table List */}
        <Drawer anchor={"bottom"} open={isOpenList} onClose={onCloseList}>
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={1}
              paddingY={1}
            >
              <Typography
                variant="h6"
                fontWeight={"bold"}
                color={StyleVariables["color-header"]}
              >
                ประวัติการขายล่าสุด
              </Typography>
              <CloseIcon
                sx={{
                  fontSize: 32,
                  color: StyleVariables["color-mainIcon"],
                  cursor: "pointer",
                }}
                onClick={onCloseList}
              />
            </Box>
            <Box marginTop={2}>
              <SellAndBuyListTable
                list={trades}
                isLoading={isLoadingList}
                noDataMessage="ไม่มีข้อมูลการขายวันนี้"
                onClickBill={(id: string) => onOpenCompleteBillModal(id)}
                onClickCancel={(trade: TradeModel) =>
                  onOpenCancelBillModal(trade)
                }
              />
            </Box>
          </>
        </Drawer>
        {/* /.Table List */}

        {/* Preview Complete Bill Modal */}
        <BillModal
          isOpen={isCompleteBillModalOpen}
          title={"ขาย (สำเนา)"}
          isComplete={true}
          isCancel={Boolean(trade?.cancelAt)}
          onPrint={() => {
            onPrintBillPdfHistory(trade?.receiptCopy?.id || "");
          }}
          onClose={onCloseCompleteBillModal}
        >
          <SellBill trade={trade} payments={payments} />
        </BillModal>
        {/* Preview Complete Bill Modal */}

        {/* Cancel Bill Modal */}
        <CancelBillModal
          isOpen={isCancelBillModalOen}
          onClose={onCloseCancelBillModal}
          onSubmit={(form: CancelReasonFormModel) => {
            onCancelTrade(form);
          }}
        />
        {/* /.Cancel Bill Modal */}

        {/* Error Modal */}
        <InformationModal
          isOpen={isFailModalOpen}
          title="ทำรายการไม่สำเร็จ"
          onClose={onCloseFailModal}
        >
          <>
            <Box textAlign={"center"}>
              <Typography variant="body2" fontWeight={"regular"}>
                {messageFailModal}
              </Typography>
            </Box>
            <Button
              variant="contained"
              fullWidth
              sx={{ marginTop: 5 }}
              onClick={onCloseFailModal}
            >
              ตกลง
            </Button>
          </>
        </InformationModal>
        {/* /.Error Modal */}

        <LoadProgressing isOpen={isLoadProgressing} />

        <AlertSnackbar
          type="success"
          isOpen={isOpenSuccessSnackbar}
          description="สร้างรายการขายสำเร็จ"
          onClose={() => setIsOpenSuccessSnackbar(false)}
        />
      </>
    </MainLayout>
  );
});

export default Sell;
