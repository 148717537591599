const onMappingQuantityOptions = (range: number) => {
  const options = Array.from({ length: range }, (_, i) => i + 1);
  return options;
};

const inputNameFormat = (value: string) => {
  const newString: string = value.replace(
    /[0-9\๑\๒\๓\๔\๕\๖\๗\๘\๙\๐\@\_\.\-\,\/\!\#\$\%\^\&\*\)\(\+\=\<\>\{\}\[\]\:\;\'\"\|\~\`\\]/g, //eslint-disable-line
    ""
  );
  return newString;
};

const inputCitizenIdFormat = (value: string) => {
  const newString: string = value.replace(
    /[\๑\๒\๓\๔\๕\๖\๗\๘\๙\๐\@\_\.\-\,\/\!\#\$\%\^\&\*\)\(\+\=\<\>\{\}\[\]\:\;\'\"\|\~\`\\]/g, //eslint-disable-line
    ""
  );
  return newString;
};

const inputNumberFormat = (value: string) => {
  const newString: string = value.replace(/[^0-9]/g, "");
  return newString;
};

export {
  onMappingQuantityOptions as OnMappingQuantityOptions,
  inputNameFormat as InputNameFormat,
  inputCitizenIdFormat as InputCitizenIdFormat,
  inputNumberFormat as InputNumberFormat,
};
