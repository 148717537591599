import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import { AccountCircle as AccountCircleIcon } from "@mui/icons-material";
import LogoWithText from "../assets/images/LogoWithText.png";
import StyleVariables from "../assets/styles/Variable.module.scss";
import LoginForm from "../components/forms/Login";
import { useNavigate } from "react-router-dom";
import { Paths as PathsConstant } from "../constants/Route";
import InformationModal from "../components/modals/Information";
import { Login as LoginModel, UserList as UserListModel } from "../models/User";
import { observer } from "mobx-react";
import { useStores } from "../stores";
import { GetUsers as GetUsersService } from "../services/User";

type Props = {};

const Login = observer((props: Props) => {
  const navigate = useNavigate();
  const { UserStore } = useStores();
  const [isOpenModalForgotPassword, setIsOpenModalForgotPassword] =
    useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [usersInterVal, setUsersInterVal] = useState<any>(null);
  const [users, setUsers] = useState<UserListModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenModalLogin, setIsOpenModalLogin] = useState<boolean>(false);
  const [user, setUser] = useState<UserListModel | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  useEffect(() => {
    getUsers();
    const getUsersInterval = setInterval(getUsers, 300000);
    setUsersInterVal(getUsersInterval);

    return () => {
      clearInterval(usersInterVal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleAutoLogin = async () => {
      if (localStorage.accessToken && !UserStore.isLoggedIn) {
        const response = await UserStore.getMe();
        response.success && navigate(PathsConstant.sell);
      }
    };
    handleAutoLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsers = async () => {
    setIsLoading(!users.length);
    const response = await GetUsersService();
    response.success && setUsers([...response.data]);
    setIsLoading(false);
  };

  const onOpenModalForgotPassword = () => {
    setIsOpenModalLogin(false);
    setIsOpenModalForgotPassword(true);
    setUser(null);
  };

  const onOpenModalLogin = (user: UserListModel) => {
    setUser(user);
    setIsOpenModalLogin(true);
  };

  const onCloseModalLogin = () => {
    setIsOpenModalLogin(false);
    setIsError(false);
    setErrorMessage("");
    setUser(null);
  };

  const onLogin = async (form: LoginModel) => {
    setIsError(false);
    setErrorMessage("");
    setIsProcessing(true);
    const response = await UserStore.handleLogin(form);
    setIsProcessing(false);
    if (!response.success) {
      setIsError(true);
      setErrorMessage(`${response.message || ""} (${response.code})`);
      return;
    }
    navigate(PathsConstant.sell);
  };

  return (
    <>
      <Box
        className="full-height"
        display={"flex"}
        alignItems={"center"}
        maxWidth={834}
        width={"100%"}
        marginX={"auto"}
      >
        {/* User Can Login List */}
        <Box width={"100%"} padding={2}>
          <Box textAlign={"center"}>
            <img src={LogoWithText} alt="Logo" width={256} height={256} />
          </Box>
          {!isLoading ? (
            !users.length ? (
              <Typography
                variant="body2"
                color={StyleVariables["color-placeholder"]}
                fontWeight={"medium"}
                textAlign={"center"}
                marginY={4}
              >
                วันนี้ไม่มีบัญชีผู้ใช้งานที่ลงทะเบียนเข้าทำงาน
              </Typography>
            ) : (
              <>
                <Typography
                  variant="body1"
                  fontWeight={"medium"}
                  color={StyleVariables["color-text"]}
                  textAlign={"center"}
                  marginY={5}
                >
                  เลือกบัญชีผู้ใช้งานที่ต้องการเข้าสู่ระบบ
                </Typography>
                <Grid container rowSpacing={2} gap={2} flexDirection={"column"}>
                  {users.map((user: UserListModel, index: number) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        boxShadow={StyleVariables["shadow-sm"]}
                        borderRadius={"10px"}
                        bgcolor={StyleVariables["color-white"]}
                        sx={{ cursor: "pointer" }}
                        onClick={() => onOpenModalLogin(user)}
                      >
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          gap={1}
                          paddingTop={3}
                          paddingBottom={5}
                          paddingX={2}
                        >
                          <AccountCircleIcon
                            sx={{
                              color: StyleVariables["color-secondary"],
                              fontSize: 40,
                            }}
                          />
                          <Typography
                            variant="h6"
                            fontWeight={"medium"}
                            color={StyleVariables["color-text"]}
                          >
                            {user.employeeCode} {user.employeeName}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            )
          ) : (
            <Box display={"flex"} justifyContent={"center"}>
              <CircularProgress
                size={40}
                style={{
                  color: StyleVariables["color-textfield-icon"],
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      {/* /.User Can Login List */}

      {/* Login Modal */}
      <InformationModal
        isOpen={isOpenModalLogin}
        title="เข้าสู่ระบบ"
        onClose={onCloseModalLogin}
      >
        <LoginForm
          user={user}
          isShowErrorMessage={isError}
          errorMessage={errorMessage}
          isLoading={isProcessing}
          onSubmit={(form: any) => onLogin(form)}
          onForgotPassword={onOpenModalForgotPassword}
        />
      </InformationModal>
      {/* /.Login Modal */}

      {/* Forgot Password Modal */}
      <InformationModal
        isOpen={isOpenModalForgotPassword}
        title="ลืมรหัสผ่าน"
        onClose={() => setIsOpenModalForgotPassword(false)}
      >
        <Box textAlign={"center"}>
          <Typography variant="body2" fontWeight={"regular"}>
            กรุณาติดต่อผู้ดูแลระบบสำนักงานใหญ่ <br />
            เพื่อทำการตั้งค่ารหัสผ่านใหม่
          </Typography>
          <Typography
            variant="body2"
            fontWeight={"medium"}
            color={StyleVariables["color-primary"]}
            marginTop={3}
          >
            เบอร์โทร 02-415-1951 {"(คุณจอย)"}
          </Typography>
        </Box>
      </InformationModal>
      {/* /.Forgot Password Modal */}
    </>
  );
});

export default Login;
