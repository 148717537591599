import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { Box, Grid } from "@mui/material";
import { OnMappingQuantityOptions as OnMappingQuantityOptionsUtils } from "../../../utils/Form";
import NumberInput from "../../inputs/Number";
import SelectionDropdown from "../../dropdowns/Selection";
import { TradeHasItemIsInvalid as TradeHasItemIsInvalidModel } from "../../../models/Trade";

type Props = {};

const Manual = observer((props: Props) => {
  const { TradeStore, PaymentStore } = useStores();

  const onInputWeightChange = (value: number) => {
    const form: any = JSON.parse(JSON.stringify({ ...TradeStore.form }));
    const formHasItemIsInvalid: TradeHasItemIsInvalidModel = JSON.parse(
      JSON.stringify({
        ...TradeStore.formHasItemIsInvalid[0],
      })
    );
    form.tradeHasItems[0] = {
      ...form.tradeHasItems[0],
      weight: value,
    };
    form.totalWeight = value * form.totalQuantity;
    formHasItemIsInvalid.weight = Boolean(!form.totalWeight);
    TradeStore.onSetForm({ ...form });
    TradeStore.onSetFormHasItemIsInvalid({ ...formHasItemIsInvalid }, 0);
    TradeStore.onClearPayment(form.totalPay);
    PaymentStore.onClearStore(form.totalPay);
  };
  const onQuantityChange = (quantity: number) => {
    const form: any = JSON.parse(JSON.stringify({ ...TradeStore.form }));
    form.tradeHasItems[0] = {
      ...form.tradeHasItems[0],
      quantity: quantity,
    };
    form.totalQuantity = quantity;
    TradeStore.onSetForm({ ...form });
    TradeStore.onClearPayment(form.totalPay);
  };
  const onInputTotalPayChange = (newTotalPay: number) => {
    const form: any = JSON.parse(JSON.stringify({ ...TradeStore.form }));
    const formIsInvalidObj: any = JSON.parse(
      JSON.stringify({ ...TradeStore.formIsInvalid })
    );
    form.tradeHasItems[0] = {
      ...form.tradeHasItems[0],
      price: newTotalPay / form.totalQuantity,
    };
    form.totalPay = newTotalPay;
    formIsInvalidObj.totalPay = Boolean(!newTotalPay);
    TradeStore.onSetForm({ ...form });
    TradeStore.onSetFormIsInvalid({ ...formIsInvalidObj });
    TradeStore.onClearPayment(form.totalPay);
  };

  return (
    <Box marginTop={2}>
      <Grid container spacing={{ xs: 3, md: 2 }}>
        <Grid item xs={12} md={6} marginTop={{ xs: 0, md: 1 }}>
          <Grid container spacing={2}>
            {/* Weight Per Unit  */}
            <Grid item xs={12} md={6}>
              <NumberInput
                name="weightPerUnit"
                label="น้ำหนัก/ชิ้น"
                placeholder="กรอกน้ำหนัก/ชิ้น"
                decimalScale={3}
                inputMode="decimal"
                value={`${TradeStore.form.tradeHasItems[0]?.weight || ""}`}
                isError={TradeStore.formHasItemIsInvalid[0].weight}
                handleOnChange={(value: string) => {
                  onInputWeightChange(Number(value));
                }}
              />
            </Grid>
            {/* /.Weight Per Unit  */}

            {/* Total Quantity */}
            <Grid item xs={12} md={6}>
              <SelectionDropdown
                name="totalQuantity"
                label="จำนวนรวม"
                options={OnMappingQuantityOptionsUtils(99)}
                selected={TradeStore.form.totalQuantity}
                placeholder="เลือกจำนวนรวม"
                onSelected={(selected: number) => {
                  onQuantityChange(selected);
                }}
              />
            </Grid>
          </Grid>
          {/* /.Total Quantity */}
        </Grid>
        {/* Sell Price Cash */}
        <Grid item xs={12} md={6} marginTop={{ xs: 0, md: 1 }}>
          <NumberInput
            name="sellTotalPay"
            label="รับเงินจากลูกค้า"
            placeholder="กรอกราคาขายสินค้าเงินสด"
            value={`${TradeStore.form.totalPay || ""}`}
            isError={TradeStore.formIsInvalid.totalPay}
            handleOnChange={(value: string) => {
              onInputTotalPayChange(Number(value));
            }}
          />
        </Grid>
        {/* /.Sell Price Cash */}

        {TradeStore.isShowAll && (
          <>
            {/* Total Price Per Unit */}
            <Grid
              item
              xs={12}
              md={4}
              marginTop={{ xs: 0, md: 1 }}
              sx={{ opacity: 0.6 }}
            >
              <NumberInput
                name="sellTotalPayPerUnit"
                label="ราคา/ชิ้น"
                placeholder="กรอกอัตโนมัติ"
                value={`${
                  TradeStore.form.totalPay / TradeStore.form.totalQuantity
                }`}
                handleOnChange={(value: string) => {}}
                isDisabled={true}
              />
            </Grid>
            {/* /.Total Price Per Unit */}
          </>
        )}
      </Grid>
    </Box>
  );
});

export default Manual;
