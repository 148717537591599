import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Box, Grid, Typography } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { useStores } from "../../stores";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import {
  Category as CategoryModel,
  Product as ProductModel,
} from "../../models/Product";
import {
  TradeHasItemIsInvalid as TradeHasItemIsInvalidModel,
  TradeIsInvalid as TradeIsInvalidModel,
  Trade as TradeModel,
} from "../../models/Trade";
import {
  GetCategories as GetCategoriesService,
  GetProductsByCategory as GetProductsByCategoryService,
} from "../../services/MasterData";
import { OnMappingQuantityOptions as OnMappingQuantityOptionsUtils } from "../../utils/Form";
import {
  CurrentStatusPurchasePrice as CurrentStatusPurchasePriceUtil,
  CalculatePurchaseNegotiation as CalculatePurchaseNegotiationUtil,
} from "../../utils/Gold";
import {
  Type as TypeGoldConstant,
  ProductWeightType as ProductWeightTypeConstant,
  RefInvoice as RefInvoiceConstant,
} from "../../constants/Gold";
import SelectionDropdown from "../dropdowns/Selection";
import NumberFieldWithButtonInput from "../inputs/NumberFieldWithButton";
import NumberInput from "../inputs/Number";
import { NumericFormat } from "react-number-format";

type Props = {};

const Purchase = observer((props: Props) => {
  const { TradeStore, MainStore, UserStore } = useStores();
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [products, setProducts] = useState<ProductModel[]>([]);

  useEffect(() => {
    getCategories();
    return () => {
      TradeStore.onClearStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TradeStore]);

  const getCategories = async () => {
    const response = await GetCategoriesService(TypeGoldConstant.purchase);
    if (response.success) {
      setCategories(response.data);
      response.data?.length === 1 && onCategorySelected(response.data[0]);
    }
  };
  const getProductsByCategory = async (code: string) => {
    setProducts([]);
    const response = await GetProductsByCategoryService(
      code,
      TypeGoldConstant.purchase
    );
    setProducts(response.data);
    response.data?.length === 1 && onProductSelected(response.data[0]);
  };
  const onCategorySelected = (category: CategoryModel) => {
    TradeStore.onClearStore();
    const form: TradeModel = JSON.parse(JSON.stringify(TradeStore.form));
    form.userFirstName = UserStore.user.employeeFirstName;
    form.userLastName = UserStore.user.employeeLastName;
    form.branchName = UserStore.user.companyName;
    form.branchCode = UserStore.user.companyCode;
    form.ref = RefInvoiceConstant.purchase;
    form.categoryCode = category.code;
    form.categoryName = category.name;
    form.category = category;
    form.goldSellPrice = MainStore.goldSellNow;
    form.goldPurchasePrice = MainStore.goldPurchaseNow;
    form.goldPriceDateTime = MainStore.goldDateTime;
    TradeStore.onSetForm({ ...form });
    getProductsByCategory(category.code);
  };
  const onProductSelected = (product: ProductModel) => {
    if (!product.percentageGold && !product.labour) {
      TradeStore.onSetTradeByProductManual(
        product,
        TradeStore.form.goldSellPrice,
        TradeStore.form.goldPurchasePrice
      );
    } else {
      TradeStore.onSetTradeByProductEqualWeight(
        product,
        TradeStore.form.goldSellPrice,
        TradeStore.form.goldPurchasePrice
      );
    }
  };
  const onInputWeightChange = (newWeight: number) => {
    const form: TradeModel = JSON.parse(JSON.stringify({ ...TradeStore.form }));
    const formIsInvalid: TradeIsInvalidModel = JSON.parse(
      JSON.stringify({ ...TradeStore.formIsInvalid })
    );
    const formHasItemIsInvalid: TradeHasItemIsInvalidModel = JSON.parse(
      JSON.stringify({
        ...TradeStore.formHasItemIsInvalid[0],
      })
    );
    const {
      purchasePriceStandard,
      purchasePriceStandardExcellent,
      purchasePriceStandardGood,
      purchasePriceStandardFair,
      purchasePriceStandardPoor,
    } = TradeStore.calculatePurchaseRangeForValidate(
      form.productPercentage,
      form.goldPurchasePrice,
      newWeight
    );
    const priceNegotiation = CalculatePurchaseNegotiationUtil(
      form.goldPurchasePrice,
      form.productPercentage,
      newWeight
    );

    form.tradeHasItems[0] = {
      ...form.tradeHasItems[0],
      weight: newWeight,
      priceStandard: purchasePriceStandard,
      price: purchasePriceStandard - priceNegotiation,
      priceNegotiation: priceNegotiation,
    };
    form.totalPriceNegotiation = priceNegotiation;
    form.totalWeight = newWeight;
    form.totalPay = purchasePriceStandard - priceNegotiation;
    form.netPay = purchasePriceStandard - priceNegotiation;
    form.standardExcellent = purchasePriceStandardExcellent;
    form.standardGood = purchasePriceStandardGood;
    form.standardFair =
      form.productPercentage < 100
        ? purchasePriceStandardFair
        : purchasePriceStandard;
    form.standardPoor =
      form.productPercentage < 100
        ? purchasePriceStandardPoor
        : purchasePriceStandard;
    form.currentStatus = "good";
    formIsInvalid.totalPay = Boolean(!purchasePriceStandard);
    formHasItemIsInvalid.weight = Boolean(!newWeight);
    TradeStore.onSetForm({ ...form });
    TradeStore.onSetFormHasItemIsInvalid({ ...formHasItemIsInvalid }, 0);
  };
  const onTotalPayChange = (newTotalPay: number) => {
    const form: TradeModel = JSON.parse(JSON.stringify({ ...TradeStore.form }));
    const formIsInvalid: TradeIsInvalidModel = JSON.parse(
      JSON.stringify({ ...TradeStore.formIsInvalid })
    );
    const formHasItemIsInvalid: TradeHasItemIsInvalidModel = {
      ...TradeStore.formHasItemIsInvalid[0],
    };
    form.tradeHasItems[0] = {
      ...form.tradeHasItems[0],
      price: newTotalPay,
    };
    form.totalPay = newTotalPay;
    form.netPay = newTotalPay;
    form.currentStatus =
      form.productType !== ProductWeightTypeConstant.manual
        ? CurrentStatusPurchasePriceUtil(
            newTotalPay,
            form.standardExcellent,
            form.standardGood,
            form.standardFair,
            form.standardPoor
          )
        : "good";
    formIsInvalid.totalPay =
      form.currentStatus === "poor" || Boolean(!newTotalPay);
    TradeStore.onSetForm({ ...form });
    TradeStore.onSetFormHasItemIsInvalid({ ...formHasItemIsInvalid }, 0);
    TradeStore.onSetFormIsInvalid({ ...formIsInvalid });
  };
  const onQuantityChange = (newQuantity: number) => {
    const form: TradeModel = JSON.parse(JSON.stringify({ ...TradeStore.form }));
    form.tradeHasItems[0] = {
      ...form.tradeHasItems[0],
      quantity: newQuantity,
    };
    form.totalQuantity = newQuantity;
    TradeStore.onSetForm({ ...form });
  };

  return (
    <Box>
      <Grid container spacing={{ xs: 3, md: 2 }}>
        <Grid item xs={12} md={6}>
          <SelectionDropdown
            name="category"
            label="ประเภทสินค้า"
            options={categories}
            selected={TradeStore.form.category}
            labelKey="name"
            valueKey="code"
            imageKey="img"
            placeholder="เลือกประเภทสินค้า"
            isShowImage={true}
            isError={TradeStore.formIsInvalid.categoryCode}
            onSelected={(selected: CategoryModel) => {
              onCategorySelected(selected);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectionDropdown
            name="product"
            label="ชื่อสินค้า"
            options={products}
            selected={TradeStore.form.product}
            labelKey="name"
            valueKey="code"
            imageKey="img"
            placeholder="เลือกชื่อสินค้า"
            isDisabled={!TradeStore.form.categoryCode}
            isShowImage={true}
            isError={TradeStore.formIsInvalid.productCode}
            onSelected={(selected: ProductModel) => {
              onProductSelected(selected);
            }}
          />
        </Grid>
      </Grid>
      {TradeStore.form.productCode && (
        <Box marginTop={2}>
          <Grid container spacing={{ xs: 3, md: 2 }}>
            <Grid item xs={12} md={6} marginTop={{ xs: 0, md: 1 }}>
              <Grid container spacing={2}>
                {/* Total Weight  */}
                <Grid item xs={12} md={6}>
                  <NumberInput
                    name="totalWeight"
                    label="น้ำหนักรวม"
                    placeholder="กรอกน้ำหนักรวม"
                    inputMode="decimal"
                    decimalScale={3}
                    value={`${TradeStore.form.totalWeight || ""}`}
                    isError={TradeStore.formHasItemIsInvalid[0].weight}
                    handleOnChange={(value: string) => {
                      onInputWeightChange(Number(value || 0));
                    }}
                  />
                </Grid>
                {/* /.Weight Per Unit  */}

                {/* Total Quantity */}
                <Grid item xs={12} md={6}>
                  <SelectionDropdown
                    name="totalQuantity"
                    label="จำนวนรวม"
                    options={OnMappingQuantityOptionsUtils(99)}
                    selected={TradeStore.form.totalQuantity}
                    placeholder="เลือกจำนวนรวม"
                    onSelected={(selected: number) => {
                      onQuantityChange(selected);
                    }}
                  />
                </Grid>
              </Grid>
              {/* /.Total Quantity */}
            </Grid>
            {/* Purchase Price Cash */}
            <Grid item xs={12} md={6} marginTop={{ xs: 0, md: 1 }}>
              {TradeStore.form.productType ===
              ProductWeightTypeConstant.equalWeight ? (
                <NumberFieldWithButtonInput
                  name="purchaseTotalPay"
                  label="ให้เงินลูกค้า"
                  helperLabel={
                    <Typography
                      variant="body2"
                      fontWeight={"bold"}
                      color={StyleVariables["color-header"]}
                    >
                      {TradeStore.form.totalPay > 0 ? (
                        <>
                          {"(เปิด "}
                          <NumericFormat
                            value={
                              TradeStore.form.tradeHasItems[0]?.priceStandard -
                              TradeStore.form.totalPriceNegotiation
                            }
                            displayType="text"
                            thousandSeparator
                          />
                          {")"}
                        </>
                      ) : null}
                    </Typography>
                  }
                  placeholder={"กรอกอัตโนมัติ"}
                  value={TradeStore.form.totalPay}
                  isDisabled={true}
                  isDecreaseDisabled={TradeStore.form.totalPay <= 0}
                  isIncreaseDisabled={
                    (TradeStore.form.currentStatus === "poor" &&
                      TradeStore.form.totalPay >=
                        TradeStore.form.standardPoor) ||
                    (TradeStore.form.productPercentage >= 100 &&
                      TradeStore.form.totalPay ===
                        TradeStore.form.tradeHasItems[0]?.priceStandard)
                  }
                  isError={TradeStore.form.currentStatus === "poor"}
                  errorMessage={
                    <Box
                      display={"flex"}
                      gap={1}
                      alignItems={"center"}
                      marginTop={1}
                    >
                      <ErrorIcon
                        sx={{
                          fontSize: 32,
                          color: StyleVariables["color-error"],
                        }}
                      />
                      <Typography
                        variant="subtitle1"
                        fontWeight={"medium"}
                        color={StyleVariables["color-error"]}
                      >
                        ราคารับซื้อมาตรฐาน{" "}
                        <NumericFormat
                          value={
                            TradeStore.form.tradeHasItems[0]?.priceStandard
                          }
                          displayType="text"
                          thousandSeparator
                        />
                      </Typography>
                    </Box>
                  }
                  status={TradeStore.form.currentStatus}
                  handleOnChange={(value: number | string) => {
                    onTotalPayChange(Number(value || 0));
                  }}
                  handleOnIncrease={(value: number | string) => {
                    onTotalPayChange(Number(value || 0));
                  }}
                  handleOnDecrease={(value: number | string) => {
                    onTotalPayChange(Number(value || 0));
                  }}
                />
              ) : (
                <NumberInput
                  name="purchaseTotalPay"
                  label="ให้เงินลูกค้า"
                  placeholder="กรอกราคารับซื้อสินค้าเงินสด"
                  value={`${TradeStore.form.totalPay || ""}`}
                  isError={TradeStore.formIsInvalid.totalPay}
                  handleOnChange={(value: string) => {
                    onTotalPayChange(Number(value || 0));
                  }}
                />
              )}
            </Grid>
            {/* /.Purchase Price Cash */}
          </Grid>
        </Box>
      )}
    </Box>
  );
});

export default Purchase;
