const priceRoundUp = (value: number) => {
  const newValue = Math.ceil(value);
  const numberOfFraction = newValue % 100;
  const numberOfDivide = numberOfFraction > 50 ? 100 : 50;
  const finalPrice = Math.ceil(newValue / numberOfDivide) * numberOfDivide;
  return finalPrice;
};

const chargeRoundUp = (chargeCard: number) => {
  const newChargeCard = Math.ceil(chargeCard / 10) * 10;
  return newChargeCard;
};

const priceRoundDown = (value: number) => {
  const newValue = Math.floor(value);
  const numberOfFraction = newValue % 100;
  const numberOfDivide = numberOfFraction < 50 ? 100 : 50;
  const finalPrice = Math.floor(newValue / numberOfDivide) * numberOfDivide;
  return finalPrice;
};

const fixedDecimal = (value: number, decimal: number) => {
  return parseFloat(value.toFixed(decimal));
};

export {
  priceRoundUp as PriceRoundUp,
  chargeRoundUp as ChargeRoundUp,
  priceRoundDown as PriceRoundDown,
  fixedDecimal as FixedDecimal,
};
