const type = {
  sell: "sell",
  purchase: "purchase",
  exchangeNew: "exchange-new",
  exchangeOld: "exchange-old",
};

const productWeightType: {
  equalWeight: "EQUAL_WEIGHT";
  notEqualWeight: "NOT_EQUAL_WEIGHT";
  manual: "MANUAL";
} = {
  equalWeight: "EQUAL_WEIGHT",
  notEqualWeight: "NOT_EQUAL_WEIGHT",
  manual: "MANUAL",
};

const refInvoice = {
  sell: "1",
  purchase: "2",
  exchange: "3",
  deposit: "4",
};

const typeItem: { new: "NEW"; old: "OLD" } = {
  new: "NEW",
  old: "OLD",
};

export {
  type as Type,
  productWeightType as ProductWeightType,
  refInvoice as RefInvoice,
  typeItem as TypeItem,
};
