import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import StyleVariables from "../assets/styles/Variable.module.scss";
import LogoWithText from "../assets/images/LogoWithText.png";
import { observer } from "mobx-react-lite";
import { useStores } from "../stores";
import { useNavigate } from "react-router-dom";
import { Paths as PathsConstant } from "../constants/Route";

type Props = {};

const NotFound = observer((props: Props) => {
  const navigate = useNavigate();
  const { UserStore } = useStores();

  const handleBackToHome = () => {
    UserStore.isLoggedIn
      ? navigate(PathsConstant.sell)
      : navigate(PathsConstant.login);
  };

  return (
    <Box
      className="full-height"
      display={"flex"}
      alignItems={"center"}
      maxWidth={834}
      width={"100%"}
      marginX={"auto"}
    >
      {/* User Can Login List */}
      <Box width={"100%"} padding={2}>
        <Box textAlign={"center"}>
          <img src={LogoWithText} alt="Logo" width={256} height={256} />
        </Box>
        <Typography
          variant="body2"
          fontWeight={"medium"}
          color={StyleVariables["color-text"]}
          marginY={3}
          textAlign={"center"}
        >
          ไม่พบหน้าที่คุณต้องการ
        </Typography>
        <Grid container justifyContent={"center"} paddingY={5}>
          <Grid item xs={12} md={6}>
            <Button variant="contained" fullWidth onClick={handleBackToHome}>
              กลับสู่หน้าหลัก
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

export default NotFound;
