import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import dayjs from "dayjs";
import {
  Deposit as DepositModel,
  DepositRenew as DepositRenewModel,
} from "../../models/Deposit";
import {
  DateBuddhistEra as DateBuddhistEraUtil,
  DateTimeBuddhistEra as DateTimeBuddhistEraUtil,
} from "../../utils/DateFormat";
import {
  RenewBillTypes as RenewBillTypesConstant,
  RenewBillTypeKey as RenewBillTypeKeyConstant,
} from "../../constants/Deposit";
import { NumericFormat } from "react-number-format";

type Props = {
  deposit: DepositModel | null;
  isBillRenew?: boolean;
  depositRenew: DepositRenewModel | null;
};

const Deposit = (props: Props) => {
  const getLabelDepositRenewType = (type: string) => {
    const findType = RenewBillTypesConstant.find((item) => item.value === type);
    return findType?.label || "";
  };

  return (
    <Box color={StyleVariables["color-text"]}>
      {/* Heading */}
      <Box marginBottom={3} marginTop={-3} textAlign={"center"}>
        <Typography variant="body1" fontWeight={"bold"}>
          {`(มีกำหนด ${props.deposit?.period} เดือน)`}
        </Typography>
      </Box>

      <Box borderBottom={`1px dashed ${StyleVariables["color-border"]}`}>
        {/* Branch Name && Ref Invoice Number */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          marginBottom={1}
        >
          <Typography variant="subtitle2" fontWeight={"regular"} width={"50%"}>
            {props.deposit?.branchName}
          </Typography>
          <Typography
            variant="subtitle2"
            fontWeight={"regular"}
            textAlign={"right"}
            whiteSpace={"pre-line"}
          >
            {!props.isBillRenew
              ? `${
                  props.deposit?.refInvoiceNo
                    ? `${props.deposit?.refInvoiceNo?.split("-")[1]}-${
                        props.deposit?.refInvoiceNo?.split("-")[2]
                      }`
                    : "XX-XXXX"
                } ${
                  props.deposit?.refInvoiceNoPrev
                    ? `
                    (ต่อบิล ${props.deposit?.refInvoiceNoPrev?.split("-")[1]}-${
                        props.deposit?.refInvoiceNoPrev?.split("-")[2]
                      })`
                    : ""
                }`
              : `${
                  props.depositRenew?.refInvoiceNo
                    ? `${props.depositRenew?.refInvoiceNo?.split("-")[1]}-${
                        props.depositRenew?.refInvoiceNo?.split("-")[2]
                      }`
                    : "XX-XXXX"
                }
                (ต่อบิล ${props.deposit?.refInvoiceNo?.split("-")[1]}-${
                  props.deposit?.refInvoiceNo?.split("-")[2]
                })`}
          </Typography>
        </Box>
        {/* /.Branch Name && Ref Invoice Number */}

        {/* Create Date && Employee Name */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          marginBottom={2}
        >
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.deposit?.userFirstName}{" "}
            {props.deposit?.userLastName && props.deposit?.userLastName[0]}.
          </Typography>
          <Typography variant="subtitle2" fontWeight={"regular"}>
            {props.deposit?.createdAt &&
            (!props.isBillRenew || props.depositRenew?.refInvoiceNo)
              ? DateTimeBuddhistEraUtil(props.deposit?.createdAt)
              : `${DateBuddhistEraUtil(dayjs())} --:--`}
          </Typography>
        </Box>
        {/* /.Create Date && Employee Name */}
      </Box>
      {/* /.Heading */}

      {/* Product List */}
      <Box marginY={2}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={7}>
            <Typography variant="subtitle2" fontWeight={"regular"}>
              {props.deposit?.productName} จำนวน {props.deposit?.totalQuantity}{" "}
              ชิ้น
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={"regular"}
              marginTop={1}
            >
              {`(นน.รวม${props.deposit?.totalWeight} กรัม)`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            textAlign={"right"}
            sx={{ wordBreak: "break-word" }}
          >
            <Typography variant="subtitle2" fontWeight={"regular"}>
              <NumericFormat
                value={
                  props.isBillRenew
                    ? props.depositRenew?.newDepositPrice
                    : props.deposit?.depositPrice
                }
                displayType="text"
                thousandSeparator
              />
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* /.Product List */}

      {!props.isBillRenew ? (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={3}
          paddingBottom={2}
          borderBottom={`1px dashed ${StyleVariables["color-border"]}`}
        >
          <Typography variant="subtitle2" fontWeight={"regular"}>
            ให้เงินลูกค้า
          </Typography>
        </Box>
      ) : null}

      {props.deposit?.currentStatus === "poor" && !props.isBillRenew ? (
        <Box marginTop={2}>
          <Typography
            variant="subtitle2"
            fontWeight={"bold"}
            color={StyleVariables["color-placeholder"]}
          >
            รายละเอียดต่างๆ
          </Typography>

          {/* Deposit Price Standard */}
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            gap={1}
            marginTop={1}
            color={StyleVariables["color-error"]}
          >
            <Typography variant="subtitle2" fontWeight={"regular"}>
              ราคารับฝากมาตรฐาน
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={"regular"}
              textAlign={"right"}
            >
              <NumericFormat
                value={props.deposit?.depositPriceStandard}
                displayType="text"
                thousandSeparator
              />
            </Typography>
          </Box>
          {/* /.Deposit Price Standard */}

          <Typography
            variant="subtitle2"
            fontWeight={"medium"}
            color={StyleVariables["color-error"]}
            marginTop={2}
          >
            กรุณาตรวจสอบราคาอีกครั้ง
          </Typography>
        </Box>
      ) : null}

      <Box marginY={2}>
        <Typography
          variant="subtitle2"
          fontWeight={"regular"}
          color={StyleVariables["color-text"]}
        >
          หมายเหตุ
        </Typography>
        <Typography
          variant="subtitle2"
          fontWeight={"regular"}
          display={"inline"}
        >
          ข้าพเจ้าได้ตกลงขายสินค้าของเก่าข้างต้นนี้
          และขอรับรองว่าสินค้าของเก่าที่ข้าพเจ้า
          <br />
          นำมาขายนี้เป็นของข้าพเจ้าอย่างถูกต้องตามกฎหมาย
          และข้าพเจ้าจะมาซื้อคืนภายใน
          <br />
          {props.deposit?.period} เดือน ถ้าเกินกำหนด ข้าพเจ้ายอม
          <br />
          สละสิทธิ์สินค้าข้างต้นนี้
          ถ้าเกิดมีปัญหาขึ้นข้าพเจ้าจะรับผิดชอบแต่เพียงผู้เดียว
          <br />
        </Typography>
        <Typography
          variant="subtitle2"
          fontWeight={"regular"}
          sx={{ textDecoration: "underline", display: "inline" }}
        >
          {"(บิลหายทางร้านไม่ต้องรับผิดชอบ)"}
        </Typography>
      </Box>

      {props.isBillRenew ? (
        <Box marginBottom={4} textAlign={"center"}>
          <Typography variant="subtitle1" fontWeight={"bold"}>
            เลขที่บิลเก่า{" "}
            {props.deposit?.refInvoiceNo
              ? `${props.deposit?.refInvoiceNo?.split("-")[1]}-${
                  props.deposit?.refInvoiceNo?.split("-")[2]
                }`
              : "XX-XXXX"}
            {` (${getLabelDepositRenewType(props.deposit?.type || "")}) `}
          </Typography>
          {props.deposit?.type !== RenewBillTypeKeyConstant.increase ? (
            <Typography
              variant="subtitle1"
              fontWeight={"bold"}
              color={StyleVariables["color-primary"]}
            >
              รับเงินจากลูกค้า{" "}
              <NumericFormat
                value={
                  !props.isBillRenew
                    ? props.deposit?.depositPrice
                    : props.deposit?.netPay
                }
                displayType="text"
                thousandSeparator
              />
            </Typography>
          ) : (
            <Typography
              variant="body2"
              fontWeight={"bold"}
              color={StyleVariables["color-primary"]}
              display={"inline"}
            >
              ให้เงินลูกค้า{" "}
              <NumericFormat
                value={props.deposit.netPay}
                displayType="text"
                thousandSeparator
              />
            </Typography>
          )}
        </Box>
      ) : null}
    </Box>
  );
};

export default Deposit;
