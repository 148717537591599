import { observable, makeAutoObservable, action } from "mobx";
import {
  Deposit as DepositModel,
  DepositIsInvalid as DepositIsInvalidModel,
  DepositRenew as DepositRenewModel,
  DepositRenewIsInvalid as DepositRenewIsInvalidModel,
} from "../models/Deposit";
import { Product as ProductModel } from "../models/Product";
import { GetDepositByBillNo as GetDepositByBillNoService } from "../services/Deposit";
import {
  CurrentStatusPurchasePrice as CurrentStatusPurchasePriceUtil,
  CalculateDepositPriceStandard as CalculateDepositPriceStandardUtil,
  CurrentInterestStatusDeposit as CurrentInterestStatusDepositUtil,
} from "../utils/Gold";
import {
  RangeOfStatusForCalculateTypeLess80 as RangeOfStatusForCalculateTypeLess80Constant,
  RangeOfStatusForCalculateTypeMore80 as RangeOfStatusForCalculateTypeMore80Constant,
} from "../constants/Purchase";
import { KeysType as KeysTypeConstant } from "../constants/Payment";
import {
  RenewBillTypeKey as RenewBillTypeKeyConstant,
  RangeOfInterestStatusForCalculate as RangeOfInterestStatusForCalculateDepositConstant,
} from "../constants/Deposit";
import { ProductWeightType as ProductWeightTypeConstant } from "../constants/Gold";

export default class DepositStore {
  private initForm: DepositModel = {
    categoryCode: "",
    categoryName: "",
    productCode: "",
    productName: "",
    productPercentage: 0,
    goldSellPrice: 0,
    goldPurchasePrice: 0,
    totalQuantity: 1,
    totalWeight: 0,
    period: 5,
    depositPriceStandard: 0,
    depositPrice: 0,
    status: "",
    percentInterestPerMonth: 0,
    percentInterest: 0,
    type: RenewBillTypeKeyConstant.new,
    currentStatus: "good",
    currentInterestStatus: "good",
    standardExcellent: 0,
    standardGood: 0,
    standardFair: 0,
    standardPoor: 0,
  };
  private initFormIsInvalid: DepositIsInvalidModel = {
    type: false,
    categoryCode: false,
    productCode: false,
    depositPrice: false,
    totalWeight: false,
    totalPay: false,
    cardPay: false,
  };
  private initFormRenew: DepositRenewModel = {
    newDepositPrice: 0,
    reducePrice: 0,
    increasePrice: 0,
    increasePriceStandard: 0,
    purchaseType: "",
    purchasePriceStandard: 0,
    purchasePriceNegotiation: 0,
    goldSellPrice: 0,
    goldPurchasePrice: 0,
    goldPriceDateTime: "",
    purchasePriceCurrentStatus: "good",
    purchaseStandardExcellent: 0,
    purchaseStandardGood: 0,
    purchaseStandardFair: 0,
    purchaseStandardPoor: 0,
  };
  private initFormRenewIsInvalid: DepositRenewIsInvalidModel = {
    reducePrice: false,
    increasePrice: false,
  };
  @observable form: DepositModel = { ...this.initForm };
  @observable formIsInvalid: DepositIsInvalidModel = {
    ...this.initFormIsInvalid,
  };
  @observable isBillRenew: boolean = false;
  @observable formRenew: DepositRenewModel = {
    ...this.initFormRenew,
  };
  @observable formRenewIsInvalid: DepositRenewIsInvalidModel = {
    ...this.initFormRenewIsInvalid,
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action
  onSetForm(form: DepositModel) {
    this.form = { ...form };
  }

  @action
  onSetFormIsInvalid(formIsInvalid: DepositIsInvalidModel) {
    this.formIsInvalid = { ...formIsInvalid };
  }

  @action
  onSetFormRenew(formRenew: DepositRenewModel) {
    this.formRenew = { ...formRenew };
  }

  @action
  onSetFormRenewIsInvalid(formRenewIsInvalid: DepositRenewIsInvalidModel) {
    this.formRenewIsInvalid = { ...formRenewIsInvalid };
  }

  @action
  onSetIsBillRenew(value: boolean) {
    this.isBillRenew = value;
  }

  @action
  onClearStore() {
    this.form = { ...this.initForm };
    this.formIsInvalid = { ...this.initFormIsInvalid };
    this.isBillRenew = false;
    this.formRenew = { ...this.initFormRenew };
    this.formRenewIsInvalid = { ...this.initFormRenewIsInvalid };
  }

  @action
  onClearRenewBillForm() {
    this.formRenew = { ...this.initFormRenew };
    this.formRenewIsInvalid = { ...this.initFormRenewIsInvalid };
  }

  @action
  onSetDepositByProductEqualWeight(product: ProductModel) {
    this.form.productCode = product.code;
    this.form.productName = product.name;
    this.form.productPercentage = product.percentageGold;
    this.form.totalQuantity = 1;
    this.form.totalWeight = 0;
    this.form.period = 5;
    this.form.depositPriceStandard = 0;
    this.form.depositPrice = 0;
    this.form.status = "";
    this.form.product = { ...product };
    this.formIsInvalid = { ...this.formIsInvalid };
  }

  @action
  onValidateForm() {
    let isValid: boolean = true;
    const formObj: any = { ...this.form };
    const formIsInvalidObj: any = { ...this.formIsInvalid };
    const formRenew: any = { ...this.formRenew };
    const formRenewIsInvalid: any = { ...this.formRenewIsInvalid };
    Object.keys(formIsInvalidObj).forEach((key) => {
      formIsInvalidObj[key] = Boolean(!formObj[key]);
      if (key === "cardPay")
        formIsInvalidObj[key] =
          formObj.paymentType === KeysTypeConstant.mix &&
          formObj.cardPay >= formObj.totalPay;
      if (key === "totalPay" && formObj.type === RenewBillTypeKeyConstant.new) {
        formIsInvalidObj.totalPay = false;
      }
      if (isValid) isValid = Boolean(!formIsInvalidObj[key]);
    });
    if (this.isBillRenew) {
      formIsInvalidObj.type = Boolean(
        formObj.type === RenewBillTypeKeyConstant.new
      );
      if (isValid)
        isValid = Boolean(
          !formIsInvalidObj.type ||
            !this.form.interest ||
            this.form.interest <
              (this.form.standardInterest || 0) *
                RangeOfInterestStatusForCalculateDepositConstant.fair
        );
      switch (formObj.type) {
        case RenewBillTypeKeyConstant.reduce:
          formRenewIsInvalid.reducePrice = Boolean(
            !formRenew.reducePrice ||
              formRenew.reducePrice <= 0 ||
              formRenew.reduce >= formObj.depositPrice ||
              formRenew.newDepositPrice <= 0
          );
          if (isValid) isValid = Boolean(!formRenewIsInvalid.reducePrice);
          break;
        case RenewBillTypeKeyConstant.increase:
          formRenewIsInvalid.increasePrice = Boolean(
            !formRenew.increasePrice ||
              (formObj.productType !== ProductWeightTypeConstant.manual &&
                (formRenew.increasePrice > formRenew.increasePriceStandard ||
                  formObj.netPay < 0))
          );
          if (isValid) isValid = Boolean(!formRenewIsInvalid.increasePrice);
          break;
        case RenewBillTypeKeyConstant.purchase:
          formIsInvalidObj.totalPay = formObj.netPay < 0;
          if (isValid) isValid = Boolean(!formIsInvalidObj.totalPay);
          break;
        default:
          break;
      }
    }
    this.formIsInvalid = { ...formIsInvalidObj };
    this.formRenewIsInvalid = { ...formRenewIsInvalid };
    return isValid;
  }

  @action
  async onGetDepositByBillNo(query: any, isReturn?: boolean) {
    const response = await GetDepositByBillNoService(query);
    if (!response.success) return response;
    const data = response.data?.deposit;
    const formObj: any = JSON.parse(JSON.stringify({ ...this.initForm }));
    const rangeOfStatus =
      data.productPercentage >= 80
        ? RangeOfStatusForCalculateTypeMore80Constant
        : RangeOfStatusForCalculateTypeLess80Constant;
    const depositPriceStandardExcellent = CalculateDepositPriceStandardUtil(
      data.goldPurchasePrice,
      data.productPercentage + rangeOfStatus.excellent,
      data.totalWeight,
      data.period
    );
    const depositPriceStandard = CalculateDepositPriceStandardUtil(
      data.goldPurchasePrice,
      data.productPercentage + rangeOfStatus.good,
      data.totalWeight,
      data.period
    );
    const depositPriceStandardFair = CalculateDepositPriceStandardUtil(
      data.goldPurchasePrice,
      data.productPercentage + rangeOfStatus.fair,
      data.totalWeight,
      data.period
    );
    const depositPriceStandardPoor = CalculateDepositPriceStandardUtil(
      data.goldPurchasePrice,
      data.productPercentage + rangeOfStatus.poor,
      data.totalWeight,
      data.period
    );
    this.onSetFormIsInvalid({ ...this.initFormIsInvalid });
    this.onSetForm({
      ...formObj,
      ...data,
      standardInterest: data.interest || 0,
      paymentType: "1",
      defaultPeriod: data.period,
      netPay: 0,
      productImageId: data.productImage?.id || "",
      currentStatus: CurrentStatusPurchasePriceUtil(
        data.depositPrice,
        depositPriceStandardExcellent,
        depositPriceStandard,
        data.productPercentage < 100
          ? depositPriceStandardFair
          : depositPriceStandard,
        0
      ),
      currentInterestStatus: CurrentInterestStatusDepositUtil(
        data.interest,
        data.interest,
        data.depositPrice,
        data.percentInterest || 0
      ),
      standardExcellent: depositPriceStandardExcellent,
      standardGood: depositPriceStandard,
      standardFair:
        data.productPercentage < 100
          ? depositPriceStandardFair
          : depositPriceStandard,
      standardPoor:
        data.productPercentage < 100
          ? depositPriceStandardPoor
          : depositPriceStandard,
      ...(isReturn && {
        totalPay: data.depositPrice + data.interest,
        netPay: data.depositPrice + data.interest,
        type: RenewBillTypeKeyConstant.return,
      }),
    });
    this.onSetIsBillRenew(true);
    return response;
  }

  @action
  getBody() {
    const body = {
      productCode: this.form.productCode,
      totalWeight: this.form.totalWeight,
      totalQuantity: this.form.totalQuantity,
      depositPrice: this.form.depositPrice,
      period: this.form.period,
      dailyGoldTime: this.form.goldPriceDateTime || "",
      productImageId: this.form.productImageId,
    };
    return body;
  }

  @action
  getBodyRenewToInterest() {
    const body = {
      oldBill: this.form.id || "",
      period: this.form.period,
      interest: this.form.interest || 0,
      totalPay: this.form.totalPay || 0,
      dailyGoldTime: this.formRenew.goldPriceDateTime || "",
      productImageId: this.form.productImageId,
      payment: {
        paymentType: this.form.paymentType || "1",
        netPay: this.form.netPay || 0,
        ...(this.form.cardPay &&
          this.form.chargeCard && {
            creditCard: {
              cardPay: this.form.cardPay,
              chargeCard: this.form.chargeCard,
            },
          }),
      },
    };
    return body;
  }

  @action
  getBodyRenewIncrease() {
    const body = {
      oldBill: this.form.id || "",
      period: this.form.period,
      totalPay: this.form.totalPay || 0,
      interest: this.form.interest || 0,
      dailyGoldTime: this.formRenew.goldPriceDateTime || "",
      productImageId: this.form.productImageId,
    };
    return body;
  }
}
