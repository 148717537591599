import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMoreRounded as ExpandMoreRoundedIcon } from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";

type Props = {
  id: string;
  title: string;
  defaultExpanded?: boolean;
  children: JSX.Element;
};

const Simple = (props: Props) => {
  return (
    <Accordion defaultExpanded={props.defaultExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreRoundedIcon />}
        aria-controls={`${props.id}-content`}
        id={`${props.id}-header`}
      >
        <Typography
          variant="h6"
          fontWeight={"bold"}
          color={StyleVariables["color-header"]}
        >
          {props.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
};

export default Simple;
