import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import StyleVariables from "../../../assets/styles/Variable.module.scss";
import TextFieldInput from "../../inputs/TextField";
import SelectionDropdown from "../../dropdowns/Selection";
import NumberFieldWithButtonInput from "../../inputs/NumberFieldWithButton";
import NumberInput from "../../inputs/Number";
import { OnMappingQuantityOptions as OnMappingQuantityOptionsUtils } from "../../../utils/Form";
import {
  CalculateSellPrice as CalculateSellPriceUtil,
  CalculateSellDiscount as CalculateSellDiscountUtil,
  CurrentStatusSellPrice as CurrentStatusSellPriceUtil,
} from "../../../utils/Gold";
import { Trade as TradeModel } from "../../../models/Trade";
import { RangeOfStatusForCalculate as RangeOfStatusForCalculateSellConstant } from "../../../constants/Sell";
import { NumericFormat } from "react-number-format";

type Props = {};

const EqualWeight = observer((props: Props) => {
  const { TradeStore } = useStores();

  const onQuantityChange = (quantity: number) => {
    const form: TradeModel = JSON.parse(JSON.stringify(TradeStore.form));
    form.tradeHasItems[0].quantity = quantity;
    form.tradeHasItems[0].labour =
      form.tradeHasItems[0].labourStandard +
      form.tradeHasItems[0].priceNegotiation;
    form.tradeHasItems[0].price =
      form.tradeHasItems[0].priceStandard +
      form.tradeHasItems[0].priceNegotiation;
    form.totalQuantity = quantity;
    form.totalWeight = form.tradeHasItems[0]?.weight * quantity;
    form.totalPriceNegotiation =
      form.tradeHasItems[0].priceNegotiation * quantity;
    form.totalGoldPrice =
      (form.tradeHasItems[0].priceStandard -
        form.tradeHasItems[0].labourStandard) *
      form.totalQuantity;
    form.totalLabourStandard = form.tradeHasItems[0].labourStandard * quantity;
    form.totalLabour = form.totalLabourStandard + form.totalPriceNegotiation;
    form.totalPay = form.tradeHasItems[0].price * quantity;
    form.currentStatus = "good";
    form.standardExcellent =
      form.totalLabourStandard *
      RangeOfStatusForCalculateSellConstant.excellent;
    form.standardGood =
      form.totalLabourStandard * RangeOfStatusForCalculateSellConstant.good;
    form.standardFair =
      form.totalLabourStandard * RangeOfStatusForCalculateSellConstant.fair;
    TradeStore.onSetForm({ ...form });
    TradeStore.onClearPayment(form.totalPay);
  };
  const onTotalPayChange = (newTotalPay: number) => {
    const form: TradeModel = JSON.parse(JSON.stringify(TradeStore.form));
    const discountPerUnit = CalculateSellDiscountUtil(
      form.totalGoldPrice + form.totalLabourStandard,
      newTotalPay,
      form.totalQuantity
    );
    const totalDiscount = CalculateSellDiscountUtil(
      form.totalGoldPrice + form.totalLabourStandard,
      newTotalPay,
      1
    );
    form.tradeHasItems[0].labour =
      form.tradeHasItems[0].labourStandard - discountPerUnit;
    form.tradeHasItems[0].price =
      form.tradeHasItems[0].goldPrice + form.tradeHasItems[0].labour;
    form.totalLabour = form.totalLabourStandard - totalDiscount;
    form.totalPay = newTotalPay;
    form.currentStatus = CurrentStatusSellPriceUtil(
      form.totalLabour,
      form.standardExcellent,
      form.standardGood,
      form.standardFair
    );
    TradeStore.onSetForm({ ...form });
    TradeStore.onClearPayment(newTotalPay);
  };

  return (
    <Box marginTop={2}>
      <Grid container spacing={{ xs: 3, md: 2 }}>
        <Grid
          item
          xs={12}
          md={6}
          marginTop={{ xs: 0, md: 1 }}
          alignSelf={"flex-end"}
        >
          <Grid container spacing={2}>
            {/* Weight Per Unit  */}
            <Grid item xs={12} md={6}>
              <TextFieldInput
                name="weightPerUnit"
                type="number"
                label="น้ำหนัก/ชิ้น"
                placeholder="กรอกน้ำหนัก/ชิ้น"
                value={`${TradeStore.form.tradeHasItems[0]?.weight}` || ""}
                isDisabled={true}
                handleOnChange={(value: string) => {}}
              />
            </Grid>
            {/* /.Weight Per Unit  */}

            {/* Total Quantity */}
            <Grid item xs={12} md={6}>
              <SelectionDropdown
                name="totalQuantity"
                label="จำนวนรวม"
                options={OnMappingQuantityOptionsUtils(99)}
                selected={TradeStore.form.totalQuantity}
                placeholder="เลือกจำนวนรวม"
                onSelected={(selected: number) => onQuantityChange(selected)}
              />
            </Grid>
          </Grid>
          {/* /.Total Quantity */}
        </Grid>
        {/* Sell Price Cash */}
        <Grid item xs={12} md={6} marginTop={{ xs: 0, md: 1 }}>
          <NumberFieldWithButtonInput
            name="sellTotalPay"
            label="รับเงินจากลูกค้า"
            helperLabel={
              <Typography
                variant="body2"
                fontWeight={"bold"}
                color={StyleVariables["color-header"]}
              >
                {TradeStore.form.totalPay > 0 ? (
                  <>
                    {"(เปิด "}
                    <NumericFormat
                      value={
                        TradeStore.form.totalGoldPrice +
                        TradeStore.form.totalLabourStandard +
                        TradeStore.form.totalPriceNegotiation
                      }
                      displayType="text"
                      thousandSeparator
                    />
                    {")"}
                  </>
                ) : null}
              </Typography>
            }
            placeholder={"กรอกอัตโนมัติ"}
            value={TradeStore.form.totalPay}
            isDisabled={true}
            isDecreaseDisabled={TradeStore.form.totalLabour <= 0}
            isIncreaseDisabled={!TradeStore.form.productCode}
            isError={TradeStore.form.currentStatus === "poor"}
            status={TradeStore.form.currentStatus}
            handleOnChange={(value: number | string) => {}}
            handleOnIncrease={(value: number | string) => {
              onTotalPayChange(Number(value));
            }}
            handleOnDecrease={(value: number | string) => {
              onTotalPayChange(Number(value));
            }}
          />
        </Grid>
        {/* /.Sell Price Cash */}

        {TradeStore.isShowAll && (
          <>
            {/* Gold Price Per Unit */}
            <Grid
              item
              xs={12}
              md={4}
              marginTop={{ xs: 0, md: 1 }}
              sx={{ opacity: 0.6 }}
            >
              <NumberInput
                name="goldPricePerUnit"
                label="ราคาเนื้อทอง/ชิ้น"
                placeholder="กรอกอัตโนมัติ"
                value={`${TradeStore.form.tradeHasItems[0]?.goldPrice || ""}`}
                handleOnChange={(value: string) => {}}
                isDisabled={true}
              />
            </Grid>
            {/* /.Gold Price Per Unit */}

            {/* Labour Per Unit */}
            <Grid
              item
              xs={12}
              md={4}
              marginTop={{ xs: 0, md: 1 }}
              sx={{ opacity: 0.6 }}
            >
              <NumberInput
                name="labourPerUnit"
                label="ค่ากำเหน็จ/ชิ้น"
                placeholder="กรอกอัตโนมัติ"
                value={`${
                  TradeStore.form.totalPay
                    ? TradeStore.form.tradeHasItems[0]?.labour
                    : ""
                }`}
                handleOnChange={(value: string) => {}}
                isDisabled={true}
                isError={TradeStore.form.currentStatus === "poor"}
                isShowErrorMessage={true}
                errorMessage={`กำเหน็จมาตรฐาน ${TradeStore.form.tradeHasItems[0].labourStandard}`}
              />
            </Grid>
            {/* /.Labour Per Unit */}

            {/* Total Price Per Unit */}
            <Grid
              item
              xs={12}
              md={4}
              marginTop={{ xs: 0, md: 1 }}
              sx={{ opacity: 0.6 }}
            >
              <NumberInput
                name="sellTotalPayPerUnit"
                label="ราคารวม/ชิ้น"
                placeholder="กรอกอัตโนมัติ"
                value={`${
                  (TradeStore.form.tradeHasItems[0] &&
                    CalculateSellPriceUtil(
                      TradeStore.form.tradeHasItems[0]?.goldPrice,
                      TradeStore.form.tradeHasItems[0]?.labour
                    )) ||
                  "0"
                }`}
                handleOnChange={(value: string) => {}}
                isDisabled={true}
              />
            </Grid>
            {/* /.Total Price Per Unit */}
          </>
        )}
      </Grid>
    </Box>
  );
});

export default EqualWeight;
