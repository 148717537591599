import Axios from "../plugins/Axios";
import { Create as CreateCustomerModel } from "../models/Customer";

const create = async (body: CreateCustomerModel) => {
  try {
    const { data } = await Axios.post("/customers", body);
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

export { create as Create };
