import Axios from "../plugins/Axios";
import {
  Create as CreateExchangeModel,
  CancelExchange as CancelExchangeModel,
} from "../models/Exchange";
import { TypeItem as TypeItemGoldConstant } from "../constants/Gold";

const createExchange = async (body: CreateExchangeModel) => {
  try {
    const { data } = await Axios.post("/exchanges", body);
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const submitExchangeById = async (id: string) => {
  try {
    const { data } = await Axios.post(
      `exchanges/${id}/submit`,
      {},
      {
        responseType: "blob",
      }
    );
    return {
      success: true,
      data: data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const getLatestExchanges = async () => {
  try {
    const { data } = await Axios.get("exchanges/latest");
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: [],
    };
  }
};
const getExchangeById = async (id: string) => {
  try {
    const { data } = await Axios.get(`exchanges/${id}`);
    const { exchangeHasItems, ...exchange } = data.data;
    return {
      success: true,
      data: {
        ...exchange,
        exchangeOldHasItems: exchangeHasItems.filter(
          (item: any) => item.type === TypeItemGoldConstant.old
        ),
        exchangeNewHasItems: exchangeHasItems.filter(
          (item: any) => item.type === TypeItemGoldConstant.new
        ),
      },
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const cancelExchange = async (id: string, body: CancelExchangeModel) => {
  try {
    await Axios.post(`/exchanges/${id}/cancel`, body);
    return {
      success: true,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
    };
  }
};

export {
  createExchange as CreateExchange,
  submitExchangeById as SubmitExchangeById,
  getLatestExchanges as GetLatestExchanges,
  getExchangeById as GetExchangeById,
  cancelExchange as CancelExchange,
};
