import React from "react";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import {
  RemoveCircle as RemoveCircleIcon,
  AddCircle as AddCircleIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import {
  Box,
  Input,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { NumericFormat } from "react-number-format";

type Props = {
  name: string;
  label: string;
  subLabel?: JSX.Element;
  helperLabel?: JSX.Element;
  placeholder: string;
  value: number | string;
  isDisabled?: boolean;
  isIncreaseDisabled?: boolean;
  isDecreaseDisabled?: boolean;
  isError?: boolean;
  errorMessage?: JSX.Element;
  rangeOfIncrease?: number;
  rangeOfDecrease?: number;
  inputPropsClassName?: string;
  status: "excellent" | "good" | "fair" | "poor" | "";
  handleOnChange: (value: number | string) => void;
  handleOnIncrease: (value: number | string) => void;
  handleOnDecrease: (value: number | string) => void;
  handleOnClear?: () => void;
};

const NumberFieldWithButton = (props: Props) => {
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleOnChange(event.target?.value.replaceAll(",", ""));
  };

  const onIncrease = () => {
    const increase: number =
      props.rangeOfIncrease !== undefined ? Number(props.rangeOfDecrease) : 50;
    !props.isIncreaseDisabled &&
      props.handleOnIncrease(Number(props.value) + increase);
  };
  const onDecrease = () => {
    const decrease: number =
      props.rangeOfDecrease !== undefined ? Number(props.rangeOfDecrease) : 50;
    !props.isDecreaseDisabled &&
      props.handleOnDecrease(Number(props.value) - decrease);
  };
  const onClearTextInput = () => {
    props.handleOnChange("");
    props.handleOnClear && props.handleOnClear();
  };

  return (
    <>
      {props.label && (
        <>
          <Box
            display={"flex"}
            alignItems={"center"}
            flexWrap={"wrap"}
            marginBottom={1}
            marginX={7}
            gap={1}
          >
            <Typography
              variant="body1"
              fontWeight={"bold"}
              color={StyleVariables["color-header"]}
            >
              {props.label}
            </Typography>
            {props.subLabel}
            {props.status && (
              <Box display={"flex"} gap={1}>
                <Box
                  minWidth={16}
                  minHeight={16}
                  borderRadius={"100%"}
                  bgcolor={
                    props.status === "good" || props.status === "excellent"
                      ? StyleVariables["color-success"]
                      : (props.status === "fair" &&
                          StyleVariables["color-warning"]) ||
                        StyleVariables["color-error"]
                  }
                />
                {props.status === "excellent" && (
                  <Box
                    minWidth={16}
                    minHeight={16}
                    borderRadius={"100%"}
                    bgcolor={StyleVariables["color-success"]}
                  />
                )}
              </Box>
            )}
            <Box>{props.helperLabel}</Box>
          </Box>
        </>
      )}
      <Box display={"flex"} gap={1}>
        <RemoveCircleIcon
          sx={{
            marginTop: 2,
            fontSize: 48,
            color: !props.isDecreaseDisabled
              ? StyleVariables["color-focused"]
              : StyleVariables["color-disable"],
            cursor: !props.isDecreaseDisabled ? "pointer" : "default",
          }}
          onClick={onDecrease}
        />
        <Box width={"100%"}>
          <NumericFormat
            thousandSeparator
            name={props.name}
            placeholder={props.placeholder}
            value={props.value}
            disabled={props.isDisabled}
            error={props.isError}
            autoComplete="one-time-code"
            onChange={onInputChange}
            customInput={Input}
            decimalScale={2}
            inputProps={{
              className: `input-highlight ${props.inputPropsClassName}`,
              inputMode: "numeric",
            }}
            endAdornment={
              (props.value && !props.isDisabled && (
                <InputAdornment position="start">
                  <IconButton
                    aria-label="clear value"
                    edge="end"
                    sx={{
                      fontSize: 32,
                      color: StyleVariables["color-textfield-icon"],
                    }}
                    onClick={onClearTextInput}
                  >
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              )) ||
              null
            }
          />
          {props.isError && props.errorMessage}
        </Box>
        <AddCircleIcon
          sx={{
            marginTop: 2,
            fontSize: 48,
            color: !props.isIncreaseDisabled
              ? StyleVariables["color-focused"]
              : StyleVariables["color-disable"],
            cursor: !props.isIncreaseDisabled ? "pointer" : "default",
          }}
          onClick={onIncrease}
        />
      </Box>
    </>
  );
};

export default NumberFieldWithButton;
