import React from "react";
import { Snackbar, Box, Typography } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import {
  HighlightOff as HighlightOffIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material";

type Props = {
  isOpen: boolean;
  type: "error" | "success" | "warning";
  description: string;
  onClose: () => void;
};

const Alert = (props: Props) => {
  const renderIcon = () => {
    switch (props.type) {
      case "success":
        return (
          <CheckCircleOutlineIcon
            sx={{ color: StyleVariables["color-white"], fontSize: 32 }}
          />
        );
      case "warning":
        return (
          <ErrorOutlineIcon
            sx={{ color: StyleVariables["color-white"], fontSize: 32 }}
          />
        );
      case "error":
        return (
          <HighlightOffIcon
            sx={{ color: StyleVariables["color-white"], fontSize: 32 }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={props.isOpen}
      autoHideDuration={3000}
      onClose={props.onClose}
    >
      <Box
        bgcolor={StyleVariables[`color-${props.type}`]}
        paddingY={2}
        paddingX={3}
        display={"flex"}
        alignItems={"center"}
        borderRadius={1}
        boxShadow={StyleVariables["shadow-sm"]}
        gap={1}
      >
        {renderIcon()}
        <Typography
          variant="body2"
          fontWeight={"regular"}
          color={StyleVariables["color-white"]}
        >
          {props.description}
        </Typography>
      </Box>
    </Snackbar>
  );
};

export default Alert;
