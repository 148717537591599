import StyleVariables from "../assets/styles/Variable.module.scss";
import { Paths as PathsConstant } from "./Route";

export const Menu = [
  {
    name: "ขาย",
    bgColor: StyleVariables["color-beige-light"],
    path: PathsConstant.sell,
  },
  {
    name: "เปลี่ยน",
    bgColor: StyleVariables["color-green-light"],
    path: "/exchange",
  },
  {
    name: "รับซื้อ",
    bgColor: StyleVariables["color-pink"],
    path: PathsConstant.purchase,
  },
  {
    name: "ฝาก",
    bgColor: StyleVariables["color-blue-light"],
    path: PathsConstant.deposit,
  },
  {
    name: "คืน",
    bgColor: StyleVariables["color-yellow-light"],
    path: PathsConstant.return,
  },
];
