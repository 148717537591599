import React from "react";
import { Navigate } from "react-router-dom";
import { Paths as PathsConstant } from "../constants/Route";

type Props = {
  children: JSX.Element;
};

const AuthGuard = (props: Props) => {
  return localStorage && localStorage.accessToken ? (
    props.children
  ) : (
    <Navigate to={PathsConstant.login} />
  );
};

export default AuthGuard;
