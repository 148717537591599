import StyleVariables from "../assets/styles/Variable.module.scss";

const typography = {
  fontFamily: StyleVariables["font-family-primary"],
  letterSpacing: 1,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontSize: "56px",
    lineHeight: "68px",
  },
  h2: {
    fontSize: "50px",
    lineHeight: "60px",
  },
  h3: {
    fontSize: "45px",
    lineHeight: "54px",
  },
  h4: {
    fontSize: "35px",
    lineHeight: "42px",
  },
  h5: {
    fontSize: "31px",
    lineHeight: "40px",
  },
  h6: {
    fontSize: "28px",
    lineHeight: "40px",
  },
  subtitle1: {
    fontSize: "20px",
    lineHeight: "20px",
  },
  subtitle2: {
    fontSize: "17px",
    lineHeight: "20px",
  },
  body1: {
    fontSize: "25px",
    lineHeight: "30px",
  },
  body2: {
    fontSize: "22px",
    lineHeight: "28px",
  },
  button: {
    fontSize: "22px",
    lineHeight: "28px",
  },
  caption: {
    fontSize: "15px",
    lineHeight: "18px",
  },
  overline: {
    fontSize: "15px",
    lineHeight: "18px",
  },
};

export { typography as Typography };
