import StyleVariables from "../assets/styles/Variable.module.scss";

const Input: any = {
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: "22px !important",
        lineHeight: "28px !important",
        height: "80px !important",
        fontWeight: "400 !important",
        color: `${StyleVariables["color-black"]} !important`,
        borderRadius: "10px !important",
        border: `1px solid ${StyleVariables["color-border"]} !important`,
        padding: `${Number(StyleVariables.spacing) * 2}px ${
          Number(StyleVariables.spacing) * 3
        }px !important`,
        width: "100%",
        background: StyleVariables["color-white"],
        "&.Mui-focused": {
          border: `1px solid ${StyleVariables["color-focused"]} !important`,
        },
        "&:before,&:after": {
          borderBottom: "0 !important",
        },
        "&.Mui-error": {
          border: `1px solid ${StyleVariables["color-error"]} !important`,
        },
        "&.Mui-disabled": {
          background: StyleVariables["color-disable"],
        },
      },
      input: {
        padding: "0 !important",
        "&.input-highlight": {
          fontSize: "45px",
          lineHeight: "54px",
          fontWeight: "bold",
        },
        "&.Mui-disabled": {
          WebkitTextFillColor: StyleVariables["color-text"],
        },
        "&.input-highlight-secondary": {
          fontSize: "28px",
          lineHeight: "40px",
          fontWeight: "bold",
        },
        "&.input-highlight-regular": {
          fontSize: "22px",
          lineHeight: "28px",
          fontWeight: "400",
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      input: {
        "&::placeholder": {
          color: `${StyleVariables["color-placeholder"]} !important`,
          WebkitTextFillColor: `${StyleVariables["color-placeholder"]} !important`,
          opacity: 1,
        },
        "&:-ms-input-placeholder": {
          color: `${StyleVariables["color-placeholder"]} !important`,
          WebkitTextFillColor: `${StyleVariables["color-placeholder"]} !important`,
        },
        "&::-ms-input-placeholder": {
          color: `${StyleVariables["color-placeholder"]} !important`,
          WebkitTextFillColor: `${StyleVariables["color-placeholder"]} !important`,
        },
        "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active":
          {
            WebkitBoxShadow: `0 0 0 30px ${StyleVariables["color-white"]} inset !important;`,
          },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderStyle: "none !important",
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        ".MuiIconButton-root": {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        "&.Mui-disabled": {
          backgroundColor: StyleVariables["color-disable"],
        },
      },
      select: {
        display: "flex",
        alignItems: "center",
        height: "80px !important",
        "& .MuiTypography-root": {
          width: `calc(100% - ${Number(StyleVariables.spacing) * 3}px)`,
        },
      },
      icon: {
        position: "absolute",
        right: Number(StyleVariables.spacing) * 2,
      },
    },
  },
  MuiMenuPaper: {
    styleOverrides: {
      root: {
        borderRadius: 2,
        boxShadow: StyleVariables["shadow-sm"],
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: Number(StyleVariables.spacing),
        paddingBottom: Number(StyleVariables.spacing),
        paddingRight: Number(StyleVariables.spacing) * 2,
        paddingLeft: Number(StyleVariables.spacing) * 2,
        backgroundColor: `${StyleVariables["color-white"]} !important`,
        "&:hover": {
          backgroundColor: `${StyleVariables["color-hovered"]} !important`,
        },
        "&.Mui-selected": {
          backgroundColor: `${StyleVariables["color-hovered"]} !important`,
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
  },
};

export default Input;
