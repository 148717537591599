import React from "react";
import { Box, Typography, Modal, Fade } from "@mui/material";
import { CloseRounded as CloseRoundedIcon } from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";

interface Props {
  isOpen: boolean;
  title: string;
  children: JSX.Element;
  onClose: () => void;
}

const Information = (props: Props) => {
  return (
    <Modal
      className="modal modal-md"
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={props.isOpen}>
        <Box className="modal-body">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom={`1px solid ${StyleVariables["color-border"]}`}
            paddingBottom={2}
            onClick={props.onClose}
          >
            <Typography
              variant="body1"
              fontWeight={"bold"}
              color={StyleVariables["color-header"]}
            >
              {props.title}
            </Typography>
            <CloseRoundedIcon
              sx={{
                color: StyleVariables["color-main-icon"],
                fontSize: 32,
                cursor: "pointer",
              }}
            />
          </Box>
          <Box marginTop={4}>{props.children}</Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default Information;
