import React from "react";
import { FormControl, Select, MenuItem, Typography, Box } from "@mui/material";
import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import DefaultImage from "../../assets/images/icons/DefaultImage.png";

interface Props {
  options: any[];
  selected: any;
  name?: string;
  label?: string;
  labelKey?: string;
  valueKey?: string;
  imageKey?: string;
  placeholder?: string;
  isShowImage?: boolean;
  isError?: boolean;
  isDisabled?: boolean;
  onSelected: (value: any) => void;
}

const Selection = (props: Props) => {
  const handleChange = (event: any) => {
    const value = event.target?.value;
    props.onSelected(props.valueKey ? JSON.parse(value) : value);
  };

  return (
    <>
      {props.label && (
        <Typography
          variant="body1"
          fontWeight={"bold"}
          color={StyleVariables["color-header"]}
          marginBottom={1}
        >
          {props.label}
        </Typography>
      )}
      <FormControl fullWidth>
        <Select
          name={props.name}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          error={props.isError}
          value={
            !props.selected ||
            !props.options.length ||
            (props.valueKey && !props.selected[props.valueKey])
              ? ""
              : (props.valueKey && JSON.stringify(props.selected)) ||
                props.selected
          }
          onChange={handleChange}
          IconComponent={(props) => (
            <KeyboardArrowDownRoundedIcon
              sx={{
                fontSize: 32,
                color: `${StyleVariables["color-main-icon"]} !important`,
              }}
              {...props}
            />
          )}
          renderValue={(selected: any) => {
            if (
              !selected ||
              (props.valueKey && !JSON.parse(selected)[props.valueKey])
            ) {
              return (
                <Typography
                  variant="body2"
                  color={StyleVariables["color-placeholder"]}
                  display="block"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  style={{
                    WebkitTextFillColor: StyleVariables["color-placeholder"],
                  }}
                >
                  {props.placeholder || "---- เลือก ----"}
                </Typography>
              );
            } else {
              return (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                  overflow={"hidden"}
                  width={"100%"}
                >
                  {props.isShowImage && (
                    <Box
                      bgcolor={StyleVariables["color-background"]}
                      minWidth={48}
                      minHeight={48}
                      borderRadius={1}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <img
                        src={
                          props.imageKey && JSON.parse(selected)[props.imageKey]
                            ? JSON.parse(selected)[props.imageKey]
                            : DefaultImage
                        }
                        alt="default-img"
                        width={24}
                        height={24}
                      />
                    </Box>
                  )}
                  <Typography
                    variant="body2"
                    color={StyleVariables["color-black"]}
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {props.labelKey
                      ? JSON.parse(selected)[props.labelKey]
                      : selected}
                  </Typography>
                </Box>
              );
            }
          }}
          disabled={props.isDisabled}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 300,
                maxWidth: "calc(40% - 96px)",
                borderRadius: 2,
                boxShadow: StyleVariables["shadow-sm"],
                overflowY: "scroll",
                marginTop: 1,
              },
            },
          }}
        >
          {props.options?.map((option, index) => (
            <MenuItem
              key={index}
              value={props.valueKey ? JSON.stringify(option) : option}
              sx={{
                display: "block",
                color: StyleVariables["color-black"],
              }}
            >
              <Box display={"flex"} alignItems={"center"} gap={1}>
                {props.isShowImage && (
                  <Box
                    bgcolor={StyleVariables["color-background"]}
                    minWidth={48}
                    minHeight={48}
                    borderRadius={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <img
                      src={
                        props.imageKey && option[props.imageKey]
                          ? option[props.imageKey]
                          : DefaultImage
                      }
                      alt="default-img"
                      width={24}
                      height={24}
                    />
                  </Box>
                )}
                <Typography
                  variant="body2"
                  fontWeight={"regular"}
                  color={StyleVariables["color-text"]}
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    paddingY: 1,
                  }}
                >
                  {props.labelKey ? option[props.labelKey] : option}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default Selection;
