import {
  CreateTrade as CreateTradeModel,
  CancelTrade as CancelTradeModel,
} from "../models/Trade";
import Axios from "../plugins/Axios";

const getLatestTrades = async (type: string) => {
  try {
    const { data } = await Axios.get(`/trades/${type}/latest`);
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: [],
    };
  }
};

const getTradeById = async (id: string) => {
  try {
    const { data } = await Axios.get(`/trades/${id}`);
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const createTrade = async (type: string, body: CreateTradeModel) => {
  try {
    const { data } = await Axios.post(`trades/${type}`, body);
    return {
      success: true,
      data: data.data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const submitTradeById = async (id: string) => {
  try {
    const { data } = await Axios.post(
      `trades/${id}/submit`,
      {},
      {
        responseType: "blob",
      }
    );
    return {
      success: true,
      data: data,
    };
  } catch (error: any) {
    return {
      ...error,
      success: false,
      data: null,
    };
  }
};

const cancelTrade = async (id: string, body: CancelTradeModel) => {
  try {
    await Axios.post(`/trades/${id}/cancel`, body);
    return {
      success: true,
    };
  } catch (error: any) {
    return {
      success: false,
      ...error,
    };
  }
};

export {
  getLatestTrades as GetLatestTrades,
  getTradeById as GetTradeById,
  createTrade as CreateTrade,
  submitTradeById as SubmitTradeById,
  cancelTrade as CancelTrade,
};
