import React, { useState } from "react";
import { Box, Typography, Button, Grid, Drawer } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import MainLayout from "../layouts/Main";
import { observer } from "mobx-react";
import { useStores } from "../stores";
import StyleVariables from "../assets/styles/Variable.module.scss";
import SimpleAccordion from "../components/accordions/Simple";
import DepositForm from "../components/forms/deposits/Deposit";
import CustomerForm from "../components/forms/Customer";
import BillModal from "../components/modals/Bill";
import DepositBill from "../components/bills/Deposit";
import { RenewBillTypeKey as RenewBillTypeKeyConstant } from "../constants/Deposit";
import {
  ScrollToTop as ScrollToTopUtil,
  ScrollToElementId as ScrollToElementIdUtil,
} from "../utils/ScrollToElement";
import { Create as CreateCustomerModel } from "../models/Customer";
import {
  CreateNew as CreateNewDepositModel,
  Deposit as DepositModel,
  DepositRenew as DepositRenewModel,
  CancelDeposit as CancelDepositModel,
} from "../models/Deposit";
import { CancelReasonForm as CancelReasonFormModel } from "../models/MasterData";
import LoadProgressing from "../components/LoadProgressing";
import InformationModal from "../components/modals/Information";
import AlertSnackbar from "../components/snackbars/Alert";
import DepositListTable from "../components/tables/DepositList";
import CancelBillModal from "../components/modals/CancelBill";
import { Create as CreateCustomerService } from "../services/Customer";
import {
  CreateDepositNew as CreateDepositNewService,
  SubmitDepositById as SubmitDepositByIdService,
  GetLatestDeposits as GetLatestDepositsService,
  GetDepositById as GetDepositByIdService,
  CancelDeposit as CancelDepositService,
} from "../services/Deposit";
import { OnPrintPdf as OnPrintPdfService } from "../services/Storage";

type Props = {};

const Deposit = observer((props: Props) => {
  const { MainStore, DepositStore, CustomerStore, PaymentStore } = useStores();
  const [isBillModalOpen, setIsBillModalOpen] = useState<boolean>(false);
  const [isLoadProgressing, setIsLoadProgressing] = useState<boolean>(false);
  const [isFailModalOpen, setIsFailModalOpen] = useState<boolean>(false);
  const [messageFailModal, setMessageFailModal] = useState<string>("");
  const [scrollToElementId, setScrollToElementId] = useState<string>("");
  const [isOpenSuccessSnackbar, setIsOpenSuccessSnackbar] =
    useState<boolean>(false);
  const [isOpenList, setIsOpenList] = useState<boolean>(false);
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false);
  const [deposits, setDeposits] = useState<any[]>([]);
  const [isCompleteBillModalOpen, setIsCompleteBillModalOpen] =
    useState<boolean>(false);
  const [deposit, setDeposit] = useState<DepositModel | null>(null);
  const [isBillRenew, setIsBillRenew] = useState<boolean>(false);
  const [depositRenew, setDepositRenew] = useState<DepositRenewModel | null>(
    null
  );
  const [isCancelBillModalOpen, setIsCancelBillModalOpen] =
    useState<boolean>(false);

  const onReset = () => {
    DepositStore.onClearStore();
    CustomerStore.onClearStore();
    ScrollToTopUtil();
  };

  const onSubmit = () => {
    const isValidFormProduct = DepositStore.onValidateForm();
    const isValidFormPayment = PaymentStore.onValidateForm(
      DepositStore.form.paymentType || "",
      DepositStore.form.cardPay || 0,
      DepositStore.form.chargeCard || 0,
      DepositStore.form.netPay || 0
    );
    if (!isValidFormProduct) {
      setScrollToElementId("deposit-information-content");
      setMessageFailModal(
        "กรุณาตรวจสอบข้อมูลการฝากให้ถูกต้อง และลองทำรายการใหม่อีกครั้ง"
      );
      setIsFailModalOpen(true);
      return;
    }
    if (!isValidFormPayment) {
      setScrollToElementId("payment-information-content");
      setMessageFailModal(
        "กรุณาตรวจสอบข้อมูลการชำระเงินให้ถูกต้อง และลองทำรายการใหม่อีกครั้ง"
      );
      setIsFailModalOpen(true);
      return;
    }
    setIsBillModalOpen(true);
  };

  const onCloseFailModal = () => {
    setIsFailModalOpen(false);
    setMessageFailModal("");
    scrollToElementId && ScrollToElementIdUtil(scrollToElementId);
    setScrollToElementId("");
  };

  const onCreateDeposit = async () => {
    setIsBillModalOpen(false);
    setIsLoadProgressing(true);
    const customerBody: CreateCustomerModel | null = CustomerStore.getBody();
    const depositBody = DepositStore.getBody();
    const bodyNewDeposit: CreateNewDepositModel = {
      ...depositBody,
    };
    let customerId: string = "";
    let responseCreateDeposit: any = null;
    // Create Customer
    if (customerBody) {
      const responseCustomer = await onCreateCustomer(customerBody);
      if (!responseCustomer) return;
      customerId = responseCustomer?.id;
    }
    // /.Create Customer

    // Create New Deposit
    responseCreateDeposit = await onCreateNewDeposit({
      ...bodyNewDeposit,
      ...(customerId && { customerId }),
    });
    // /.Create New Deposit

    if (!responseCreateDeposit) {
      setIsLoadProgressing(false);
      return;
    }
    // Submit PDF
    await onSubmitDeposit(responseCreateDeposit.id);
    // /.Submit PDF
    setIsLoadProgressing(false);
    setIsOpenSuccessSnackbar(true);
    onReset();
  };

  const onCreateCustomer = async (body: CreateCustomerModel) => {
    const response = await CreateCustomerService(body);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message || "สร้างข้อมูลลูกค้าไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
    return response.data;
  };

  const onCreateNewDeposit = async (body: CreateNewDepositModel) => {
    const response = await CreateDepositNewService(body);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message || "สร้างข้อมูลการฝากไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
    return response.data;
  };

  const onSubmitDeposit = async (id: string) => {
    const response = await SubmitDepositByIdService(id);
    if (!response.success) {
      setMessageFailModal(
        `${
          response.message ||
          "เกิดข้อผิดพลาดในการสร้างบิล กรุณาพิมพ์บิลใหม่ในรายการประวัติการฝาก"
        } (${response.code})`
      );
      setIsFailModalOpen(true);
      setIsLoadProgressing(false);
      return false;
    }
  };

  const onOpenList = () => {
    getDeposits();
    setIsOpenList(true);
  };

  const onCloseList = () => {
    setIsOpenList(false);
    setDeposits([]);
  };

  const getDeposits = async () => {
    setIsLoadingList(true);
    const response = await GetLatestDepositsService();
    response.success && setDeposits(response.data);
    setIsLoadingList(false);
  };

  const onOpenCompleteBillModal = async (id: string) => {
    setIsLoadProgressing(true);
    const response = await GetDepositByIdService(id);
    setIsLoadProgressing(false);
    const { deposit, depositPrev } = response.data;
    if (response.success) {
      setIsOpenList(false);
      if (!deposit.refInvoiceNoPrev) {
        setDeposit({
          currentStatus: "good",
          standardExcellent: 0,
          standardGood: 0,
          standardFair: 0,
          standardPoor: 0,
          ...deposit,
        });
      } else {
        setIsBillRenew(true);
        setDeposit({
          ...depositPrev,
          period: deposit.period,
          createdAt: deposit.createdAt,
        });
        const renewBillForm: DepositRenewModel = {
          refInvoiceNo: deposit.refInvoiceNo,
          newDepositPrice: deposit.depositPrice,
          reducePrice: 0,
          increasePrice: 0,
          increasePriceStandard: 0,
          purchaseType: "",
          purchasePriceStandard: 0,
          purchasePriceNegotiation: 0,
          purchasePriceCurrentStatus: "good",
          purchaseStandardExcellent: 0,
          purchaseStandardGood: 0,
          purchaseStandardFair: 0,
          purchaseStandardPoor: 0,
          goldSellPrice: deposit.goldSellPrice,
          goldPurchasePrice: deposit.goldPurchasePrice,
          goldPriceDateTime: "",
          receipt: deposit.receipt,
        };
        switch (depositPrev.type) {
          case RenewBillTypeKeyConstant.reduce:
            break;
          case RenewBillTypeKeyConstant.increase:
            break;
          case RenewBillTypeKeyConstant.purchase:
            break;
          default:
            break;
        }
        setDepositRenew({
          ...renewBillForm,
        });
      }
      setIsCompleteBillModalOpen(true);
    }
  };
  const onCloseCompleteBillModal = () => {
    setIsCompleteBillModalOpen(false);
    setDeposit(null);
    setIsBillRenew(false);
    setDepositRenew(null);
  };

  const onPrintBillPdfHistory = async (id: string) => {
    setIsCompleteBillModalOpen(false);
    setIsLoadProgressing(true);
    const response = await OnPrintPdfService(id);
    setIsLoadProgressing(false);
    if (!response.success) {
      setMessageFailModal(
        `${response.message || "เกิดข้อผิดพลาดในการพิมพ์ข้อมูลบิลการฝาก"} (${
          response.code
        })`
      );
      setIsFailModalOpen(true);
      return false;
    }
  };

  const onOpenCancelBillModal = (deposit: DepositModel) => {
    onCloseList();
    setDeposit(deposit);
    setIsCancelBillModalOpen(true);
  };

  const onCloseCancelBillModal = () => {
    setIsCancelBillModalOpen(false);
    setDeposit(null);
  };

  const onCancelDeposit = async (form: CancelReasonFormModel) => {
    const body: CancelDepositModel = {
      cancelReasonId: form.reason.id,
      cancelMisc: form.misc,
    };
    if (deposit?.id) {
      const response = await CancelDepositService(deposit?.id, body);
      onCloseCancelBillModal();
      if (!response?.success) {
        setMessageFailModal(
          `${
            response.message || "ยกเลิกรายการฝากไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
          } (${response.code})`
        );
        setIsFailModalOpen(true);
        return;
      }
      onOpenList();
    }
  };

  return (
    <MainLayout>
      <>
        <Box
          display={{
            xs: MainStore.isOpenSidebar ? "block" : "flex",
            md: "flex",
          }}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={2}
          textAlign={"center"}
        >
          <Typography
            variant="h6"
            fontWeight={"bold"}
            color={StyleVariables["color-header"]}
          >
            การฝาก
          </Typography>
          <Box>
            <Button variant="contained-deposit" onClick={onOpenList}>
              ดูประวัติการฝาก
            </Button>
          </Box>
        </Box>

        <Box marginTop={4}>
          {/* Form Deposit */}
          <SimpleAccordion
            id="deposit-information"
            title="ข้อมูลการฝาก"
            defaultExpanded={true}
          >
            <DepositForm />
          </SimpleAccordion>
          {/* Form Deposit */}

          {/* Form Customer */}
          <SimpleAccordion
            id="customer-information"
            title="ข้อมูลลูกค้า/ภาพสินค้า"
          >
            <CustomerForm type="DEPOSIT" />
          </SimpleAccordion>
          {/* /.Form Customer */}
        </Box>

        {/* Button */}
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          marginTop={3}
          flexDirection={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} md={6} lg={4}>
            <Button variant="outlined" fullWidth onClick={() => onReset()}>
              เริ่มใหม่
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Button variant="contained-success" fullWidth onClick={onSubmit}>
              ทำรายการ
            </Button>
          </Grid>
        </Grid>
        {/* /.Button */}

        {/* Preview Bill Modal */}
        <BillModal
          isOpen={isBillModalOpen}
          title={"ฝาก"}
          onSaveAndPrint={onCreateDeposit}
          onClose={() => {
            setIsBillModalOpen(false);
          }}
        >
          <DepositBill
            deposit={DepositStore.form}
            depositRenew={DepositStore.formRenew}
            isBillRenew={DepositStore.isBillRenew}
          />
        </BillModal>
        {/* /.Preview Bill Modal */}

        {/*  Table List */}
        <Drawer anchor={"bottom"} open={isOpenList} onClose={onCloseList}>
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={1}
              paddingY={1}
            >
              <Typography
                variant="h6"
                fontWeight={"bold"}
                color={StyleVariables["color-header"]}
              >
                ประวัติการฝากล่าสุด
              </Typography>
              <CloseIcon
                sx={{
                  fontSize: 32,
                  color: StyleVariables["color-mainIcon"],
                  cursor: "pointer",
                }}
                onClick={onCloseList}
              />
            </Box>
            <Box marginTop={2}>
              <DepositListTable
                list={deposits}
                isLoading={isLoadingList}
                noDataMessage="ไม่มีข้อมูลการฝากวันนี้"
                onClickBill={(id: string) => onOpenCompleteBillModal(id)}
                onClickCancel={(deposit: DepositModel) => {
                  onOpenCancelBillModal(deposit);
                }}
              />
            </Box>
          </>
        </Drawer>
        {/* /.Table List */}

        {/* Preview Complete Bill Modal */}
        <BillModal
          isOpen={isCompleteBillModalOpen}
          title={"ฝาก (สำเนา)"}
          isComplete={true}
          isCancel={Boolean(deposit?.cancelAt)}
          onPrint={() => {
            onPrintBillPdfHistory(
              depositRenew?.receiptCopy?.id || deposit?.receiptCopy?.id || ""
            );
          }}
          onClose={onCloseCompleteBillModal}
        >
          <DepositBill
            deposit={deposit}
            depositRenew={depositRenew}
            isBillRenew={isBillRenew}
          />
        </BillModal>
        {/* Preview Complete Bill Modal */}

        {/* Error Modal */}
        <InformationModal
          isOpen={isFailModalOpen}
          title="ทำรายการไม่สำเร็จ"
          onClose={onCloseFailModal}
        >
          <>
            <Box textAlign={"center"}>
              <Typography variant="body2" fontWeight={"regular"}>
                {messageFailModal}
              </Typography>
            </Box>
            <Button
              variant="contained"
              fullWidth
              sx={{ marginTop: 5 }}
              onClick={onCloseFailModal}
            >
              ตกลง
            </Button>
          </>
        </InformationModal>
        {/* /.Error Modal */}

        {/* Cancel Bill Modal */}
        <CancelBillModal
          isOpen={isCancelBillModalOpen}
          onClose={onCloseCancelBillModal}
          onSubmit={(form: CancelReasonFormModel) => {
            onCancelDeposit(form);
          }}
        />
        {/* /.Cancel Bill Modal */}

        <LoadProgressing isOpen={isLoadProgressing} />

        <AlertSnackbar
          type="success"
          isOpen={isOpenSuccessSnackbar}
          description="สร้างรายการฝากสำเร็จ"
          onClose={() => setIsOpenSuccessSnackbar(false)}
        />
      </>
    </MainLayout>
  );
});

export default Deposit;
