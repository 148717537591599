const keysType: {
  allCash: "1";
  allQrCode: "2";
  allCreditCard: "3";
  mix: "4";
} = {
  allCash: "1",
  allQrCode: "2",
  allCreditCard: "3",
  mix: "4",
};

const types = [
  {
    label: "เงินสดทั้งหมด",
    value: keysType.allCash,
  },
  {
    label: "QR Code ทั้งหมด",
    value: keysType.allQrCode,
  },
  {
    label: "บัตรเครดิตทั้งหมด",
    value: keysType.allCreditCard,
  },
  {
    label: "จ่ายผสม",
    value: keysType.mix,
  },
];

export { types as Types, keysType as KeysType };
