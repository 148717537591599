import React from "react";
import { Box, Button, Grid, Hidden, Divider, Typography } from "@mui/material";
import { DeleteOutline as DeleteOutlineIcon } from "@mui/icons-material";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import StyleVariables from "../../../assets/styles/Variable.module.scss";
import {
  Exchange as ExchangeModel,
  ExchangeHasItem as ExchangeHasItemModel,
  ExchangeHasItemIsInvalid as ExchangeHasItemIsInvalidModel,
} from "../../../models/Exchange";
import {
  CalculateGoldPriceStandard as CalculateGoldPriceStandardUtil,
  CalculateSellPriceStandard as CalculateSellPriceStandardUtil,
  CurrentStatusSellPrice as CurrentStatusSellPriceUtil,
  CalculateLabourByAverageDiscount as CalculateLabourByAverageDiscountUtil,
  CalculateExchangePriceStandard as CalculateExchangePriceStandardUtil,
  CalculateSellPrice as CalculateSellPriceUtil,
  CalculateSellNegotiation as CalculateSellNegotiationUtil,
} from "../../../utils/Gold";
import NumberInput from "../../inputs/Number";
import {
  PriceRoundUp as PriceRoundUpUtil,
  FixedDecimal as FixedDecimalUtil,
} from "../../../utils/NumberRounding";
import NumberFieldWithButtonInput from "../../inputs/NumberFieldWithButton";
import { NumericFormat } from "react-number-format";
import { RangeOfStatusForCalculate as RangeOfStatusForCalculateSellConstant } from "../../../constants/Sell";

type Props = {};

const NewNotEqualWeight = observer((props: Props) => {
  const { ExchangeStore, PaymentStore } = useStores();

  const onWeightInputChange = (index: number, newWeight: number) => {
    const form: ExchangeModel = JSON.parse(JSON.stringify(ExchangeStore.form));
    const formIsInvalid: any = JSON.parse(
      JSON.stringify(ExchangeStore.formIsInvalid)
    );
    const formHasItemsIsInvalidNew: ExchangeHasItemIsInvalidModel = JSON.parse(
      JSON.stringify({
        ...ExchangeStore.formHasItemsIsInvalidNew[index],
      })
    );
    const findLabourProductByWeight = ExchangeStore.findLabourStandardByWeight(
      newWeight,
      form.exchangeNewHasItems[0].product
    );
    const labourStandard = Number(
      findLabourProductByWeight?.labour ||
        ExchangeStore.form.exchangeNewHasItems[index].product?.labour ||
        0
    );
    const priceNegotiation = CalculateSellNegotiationUtil(
      Number(labourStandard)
    );
    let goldPriceStandard = CalculateGoldPriceStandardUtil(
      form.goldSellPrice,
      form.exchangeNewHasItems[index].productPercentage,
      form.exchangeNewHasItems[index].categoryCode === "11",
      1,
      Number(newWeight)
    );
    let labourAfterDiscount: number = 0;
    let sumLabourAfterDiscount: number = 0;
    let sellPriceStandard: number = 0;
    let sellPriceAfterDiscount: number = 0;
    let sumWeight: number = 0;
    let sumLabourStandard: number = 0;
    let sumGoldPrice: number = 0;
    let sumPrice: number = 0;
    let sumPriceNegotiation: number = 0;
    let exchangePriceStandard: number = 0;
    let oldTotalWeightLessThanOrEqualNewTotalWeight: boolean = true;
    form.exchangeNewHasItems[index].weight = newWeight;
    form.exchangeNewHasItems[index].labourStandard = labourStandard;
    sumWeight = form.exchangeNewHasItems.reduce(
      (n, { weight }) => n + weight,
      0
    );
    sumWeight = FixedDecimalUtil(sumWeight, 3);
    sumLabourStandard = form.exchangeNewHasItems.reduce(
      (n, { labourStandard }) => n + labourStandard,
      0
    );
    form.exchangeNewHasItems = form.exchangeNewHasItems.map(
      (item: ExchangeHasItemModel, ind: number) => {
        labourAfterDiscount = CalculateLabourByAverageDiscountUtil(
          form.totalExtraDiscountStandard,
          item.labourStandard,
          sumLabourStandard,
          sumWeight,
          item.weight
        );
        sellPriceStandard = CalculateSellPriceStandardUtil(
          ind !== index ? item.goldPrice : goldPriceStandard,
          item.labourStandard,
          1
        );
        sellPriceAfterDiscount =
          (ind !== index
            ? item.goldPrice + item.priceNegotiation
            : goldPriceStandard + priceNegotiation) + labourAfterDiscount;
        sellPriceAfterDiscount = FixedDecimalUtil(sellPriceAfterDiscount, 2);
        return ind !== index
          ? {
              ...item,
              labour:
                FixedDecimalUtil(labourAfterDiscount, 2) +
                item.priceNegotiation,
              priceStandard: sellPriceStandard,
              price: sellPriceAfterDiscount,
            }
          : {
              ...item,
              weight: newWeight,
              labourStandard: newWeight ? labourStandard : 0,
              labour: newWeight
                ? FixedDecimalUtil(labourAfterDiscount, 2) + priceNegotiation
                : 0,
              goldPrice: goldPriceStandard,
              priceStandard: newWeight ? sellPriceStandard : 0,
              price: newWeight ? sellPriceAfterDiscount : 0,
              priceNegotiation,
            };
      }
    );
    sumGoldPrice = form.exchangeNewHasItems.reduce(
      (n, { goldPrice }) => n + goldPrice,
      0
    );
    sumGoldPrice = FixedDecimalUtil(sumGoldPrice, 2);
    sumPrice = form.exchangeNewHasItems.reduce((n, { price }) => n + price, 0);
    sumPriceNegotiation = form.exchangeNewHasItems.reduce(
      (n, { priceNegotiation }) => n + priceNegotiation,
      0
    );
    sumLabourAfterDiscount =
      sumLabourStandard - form.totalExtraDiscountStandard;
    exchangePriceStandard =
      sumPrice - sumPriceNegotiation > 0
        ? CalculateExchangePriceStandardUtil(
            sumPrice - sumPriceNegotiation,
            form.oldTotalPrice
          )
        : 0;
    form.totalPriceStandard = exchangePriceStandard;
    form.totalPay = exchangePriceStandard + sumPriceNegotiation;
    form.netPay = exchangePriceStandard + sumPriceNegotiation;
    form.newTotalWeight = sumWeight;
    form.newTotalGoldPrice = PriceRoundUpUtil(sumGoldPrice);
    form.newTotalLabourStandard = sumLabourStandard;
    form.newTotalLabour = sumLabourAfterDiscount + sumPriceNegotiation;
    form.newTotalPrice = sumGoldPrice + form.newTotalLabour;
    form.totalPriceNegotiation = sumPriceNegotiation;
    form.standardExcellent = FixedDecimalUtil(
      sumLabourAfterDiscount * RangeOfStatusForCalculateSellConstant.excellent,
      2
    );
    form.standardGood = FixedDecimalUtil(
      sumLabourAfterDiscount * RangeOfStatusForCalculateSellConstant.good,
      2
    );
    form.standardFair = FixedDecimalUtil(
      sumLabourAfterDiscount * RangeOfStatusForCalculateSellConstant.fair,
      2
    );
    form.standardPoor = FixedDecimalUtil(
      sumLabourAfterDiscount * RangeOfStatusForCalculateSellConstant.poor,
      2
    );
    form.currentStatus =
      form.oldTotalWeight > form.newTotalWeight
        ? "poor"
        : CurrentStatusSellPriceUtil(
            form.newTotalLabour,
            form.standardExcellent,
            form.standardGood,
            form.standardFair
          );
    oldTotalWeightLessThanOrEqualNewTotalWeight =
      form.oldTotalWeight <= form.newTotalWeight || !form.newTotalWeight;
    formHasItemsIsInvalidNew.weight = Boolean(
      !newWeight ||
        (findLabourProductByWeight &&
          newWeight > Number(findLabourProductByWeight?.maxWeight))
    );
    formHasItemsIsInvalidNew.price = Boolean(
      !form.exchangeNewHasItems[index].price
    );
    Object.keys(formIsInvalid).forEach((key) => {
      formIsInvalid[key] = false;
    });
    ExchangeStore.onSetForm({ ...form });
    ExchangeStore.onSetFormIsInvalid({ ...formIsInvalid });
    ExchangeStore.onSetFormHasItemIsInvalidNew(
      { ...formHasItemsIsInvalidNew },
      index
    );
    ExchangeStore.onClearPayment(
      oldTotalWeightLessThanOrEqualNewTotalWeight ? form.netPay : -1
    );
    PaymentStore.onClearStore(
      oldTotalWeightLessThanOrEqualNewTotalWeight ? form.netPay : -1
    );
  };

  const onTotalPayChange = (newTotalPay: number) => {
    const form: ExchangeModel = JSON.parse(JSON.stringify(ExchangeStore.form));
    const totalDiscount: number =
      form.totalPriceStandard - newTotalPay + form.totalExtraDiscountStandard;
    const oldTotalWeightLessThanOrEqualNewTotalWeight =
      form.oldTotalWeight <= form.newTotalWeight || !form.newTotalWeight;
    let labourAfterDiscount: number = 0;
    form.exchangeNewHasItems = form.exchangeNewHasItems.map(
      (item: ExchangeHasItemModel) => {
        labourAfterDiscount = CalculateLabourByAverageDiscountUtil(
          totalDiscount,
          item.labourStandard,
          form.newTotalLabourStandard,
          form.newTotalWeight,
          item.weight
        );
        return {
          ...item,
          labour: labourAfterDiscount,
          goldPrice: item.goldPrice,
          price: item.goldPrice + labourAfterDiscount,
        };
      }
    );
    form.totalPay = newTotalPay;
    form.newTotalLabour = form.newTotalLabourStandard - totalDiscount;
    form.currentStatus = CurrentStatusSellPriceUtil(
      form.newTotalLabour,
      form.standardExcellent,
      form.standardGood,
      form.standardFair
    );
    ExchangeStore.onSetForm({ ...form });
    ExchangeStore.onClearPayment(
      oldTotalWeightLessThanOrEqualNewTotalWeight ? newTotalPay : -1
    );
    PaymentStore.onClearStore(
      oldTotalWeightLessThanOrEqualNewTotalWeight ? newTotalPay : -1
    );
  };

  const onDeleteTradeItem = (index: number) => {
    const form: ExchangeModel = JSON.parse(JSON.stringify(ExchangeStore.form));
    const itemDeleted = form.exchangeNewHasItems[index];
    form.exchangeNewHasItems.splice(index, 1);
    form.newTotalQuantity -= 1;
    ExchangeStore.onSetForm({ ...form });
    ExchangeStore.onDeleteFormHasItemIsInvalidNew(index);
    itemDeleted.price &&
      onWeightInputChange(0, form.exchangeNewHasItems[0].weight);
  };

  const onAddItem = () => {
    ExchangeStore.onAddExchangeNewHasItems();
  };

  const checkCanCreateExchange = () => {
    return Boolean(
      (ExchangeStore.form.oldTotalWeight <= ExchangeStore.form.newTotalWeight ||
        !ExchangeStore.form.newTotalWeight) &&
        ExchangeStore.form.newTotalLabourStandard -
          ExchangeStore.form.totalExtraDiscountStandard >=
          0
    );
  };

  return (
    <>
      <Box marginY={3} display={"flex"} justifyContent={"flex-end"}>
        <Button variant="outlined" onClick={onAddItem}>
          เพิ่มจำนวน
        </Button>
        {/* {`ราคาบนล่าง ${ExchangeStore.form.goldSellPrice}-${ExchangeStore.form.goldPurchasePrice}`} */}
      </Box>
      {ExchangeStore.form.exchangeNewHasItems.map(
        (item: ExchangeHasItemModel, index: number) => {
          return (
            <Box key={index}>
              <Hidden mdUp>
                {index > 0 ? (
                  <Divider
                    sx={{
                      backgroundColor: StyleVariables["color-border"],
                      marginBottom: 3,
                    }}
                  />
                ) : null}
              </Hidden>
              <Grid
                container
                spacing={{ xs: 3, md: 2 }}
                rowSpacing={1}
                alignItems={"center"}
              >
                <Grid item xs={12} md={3} marginBottom={3}>
                  <NumberInput
                    name="productNumber"
                    label={index > 0 ? "" : "สินค้าชิ้นที่"}
                    value={`${index + 1}`}
                    placeholder="ชิ้นที่"
                    isDisabled={true}
                    handleOnChange={(value: string) => {}}
                  />
                </Grid>
                <Grid item xs={12} md={3} marginBottom={3}>
                  <NumberInput
                    name="weightPerUnit"
                    label={index > 0 ? "" : "น้ำหนัก/ชิ้น"}
                    value={`${item.weight || ""}`}
                    placeholder="กรอกน้ำหนัก/ชิ้น"
                    inputMode="decimal"
                    decimalScale={3}
                    isError={
                      ExchangeStore.formHasItemsIsInvalidNew[index].weight
                    }
                    handleOnChange={(value: string) => {
                      onWeightInputChange(index, Number(value));
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} marginBottom={3}>
                  <Box display={"flex"} alignItems={"center"}>
                    <Box width={"100%"}>
                      <NumberInput
                        name="sellPricePerUnit"
                        label={index > 0 ? "" : "ราคาขายออกเงินสด/ชิ้น"}
                        value={`${item.price || ""}`}
                        placeholder="กรอกอัตโนมัติ"
                        isDisabled={true}
                        handleOnChange={(value: string) => {}}
                      />
                      {/* {`เนื้อทอง: ${item.goldPrice} / กำเหน็จมาตรฐาน: ${item.labourStandard} / กำเหน็จ: ${item.labour} / ขายมาตรฐาน ${item.priceStandard}`} */}
                    </Box>
                    {ExchangeStore.form.newTotalQuantity > 1 && (
                      <DeleteOutlineIcon
                        sx={{
                          fontSize: 24,
                          color: StyleVariables["color-menu-icon"],
                          cursor: "pointer",
                          marginTop: index > 0 ? 0 : "38px",
                          marginLeft: 1,
                        }}
                        onClick={() => {
                          onDeleteTradeItem(index);
                        }}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          );
        }
      )}
      <Grid container spacing={{ xs: 3, md: 2 }} alignSelf={"flex-end"}>
        {/* Total Quantity */}
        <Grid item xs={6} md={3}>
          <NumberInput
            name="sellTotalPay"
            label="จำนวนรวม"
            placeholder="กรอกอัตโนมัติ"
            value={`${ExchangeStore.form.newTotalQuantity}`}
            isDisabled={true}
            handleOnChange={(value: string) => {}}
          />
        </Grid>
        {/* /.Total Quantity */}

        {/* Total Weight */}
        <Grid item xs={6} md={3}>
          <NumberInput
            name="totalWeight"
            label="น้ำหนักรวม"
            placeholder="กรอกอัตโนมัติ"
            value={`${ExchangeStore.form.newTotalWeight || ""}`}
            decimalScale={3}
            isError={Boolean(
              ExchangeStore.form.newTotalWeight &&
                ExchangeStore.form.oldTotalWeight >
                  ExchangeStore.form.newTotalWeight
            )}
            isDisabled={true}
            handleOnChange={(value: string) => {}}
          />
        </Grid>
        {/* /.Total Weight */}

        {/* Total Sell Price */}
        <Grid item xs={12} md={6}>
          <NumberFieldWithButtonInput
            name="sellTotalPay"
            label="รับเงินจากลูกค้า"
            helperLabel={
              <Typography
                variant="body2"
                fontWeight={"bold"}
                color={StyleVariables["color-header"]}
              >
                {checkCanCreateExchange() ? (
                  <>
                    {"(เปิด "}
                    <NumericFormat
                      value={
                        ExchangeStore.form.totalPriceStandard +
                        ExchangeStore.form.totalPriceNegotiation
                      }
                      displayType="text"
                      thousandSeparator
                    />
                    {")"}
                  </>
                ) : null}
              </Typography>
            }
            placeholder={
              checkCanCreateExchange()
                ? "กรอกอัตโนมัติ"
                : "ไม่สามารถทำรายการได้"
            }
            value={checkCanCreateExchange() ? ExchangeStore.form.totalPay : ""}
            isDisabled={true}
            isDecreaseDisabled={
              ExchangeStore.form.newTotalLabour <= 0 ||
              !ExchangeStore.form.newTotalWeight ||
              ExchangeStore.form.oldTotalWeight >
                ExchangeStore.form.newTotalWeight ||
              ExchangeStore.form.newTotalLabourStandard -
                ExchangeStore.form.totalExtraDiscountStandard <
                0
            }
            isIncreaseDisabled={
              !ExchangeStore.form.newTotalWeight ||
              ExchangeStore.form.oldTotalWeight >
                ExchangeStore.form.newTotalWeight ||
              ExchangeStore.form.newTotalLabourStandard -
                ExchangeStore.form.totalExtraDiscountStandard <
                0
            }
            isError={ExchangeStore.form.currentStatus === "poor"}
            status={ExchangeStore.form.currentStatus}
            handleOnChange={(value: number | string) => {}}
            handleOnIncrease={(value: number | string) => {
              onTotalPayChange(Number(value));
            }}
            handleOnDecrease={(value: number | string) => {
              onTotalPayChange(Number(value));
            }}
          />
          {/* {`ราคาเปลี่ยนมาตรฐาน ${ExchangeStore.form.totalPriceStandard} / `}
          {`เขียวx2 : ${ExchangeStore.form.standardExcellent} / เขียว : ${ExchangeStore.form.standardGood} / ส้ม : ${ExchangeStore.form.standardFair} / แดง: ${ExchangeStore.form.standardPoor}`} */}
        </Grid>
        {/* /.Total Sell Price */}

        {ExchangeStore.isShowAll && (
          <>
            {/* Total Gold Price */}
            <Grid
              item
              xs={12}
              md={4}
              marginTop={{ xs: 0, md: 1 }}
              sx={{ opacity: 0.6 }}
            >
              <NumberInput
                name="totalGoldPrice"
                label="ราคาเนื้อทองรวม"
                placeholder="กรอกอัตโนมัติ"
                value={`${
                  ExchangeStore.form.newTotalWeight
                    ? ExchangeStore.form.newTotalGoldPrice
                    : ""
                }`}
                handleOnChange={(value: string) => {}}
                isDisabled={true}
              />
            </Grid>
            {/* /.Total Gold Price */}

            {/* Total Labour */}
            <Grid
              item
              xs={12}
              md={4}
              marginTop={{ xs: 0, md: 1 }}
              sx={{ opacity: 0.6 }}
            >
              <NumberInput
                name="totalLabour"
                label="ค่ากำเหน็จรวม"
                placeholder="กรอกอัตโนมัติ"
                value={`${
                  ExchangeStore.form.newTotalWeight
                    ? ExchangeStore.form.newTotalLabour
                    : ""
                }`}
                handleOnChange={(value: string) => {}}
                isDisabled={true}
                isError={ExchangeStore.form.currentStatus === "poor"}
                isShowErrorMessage={checkCanCreateExchange()}
                errorMessage={`กำเหน็จมาตรฐานรวม ${FixedDecimalUtil(
                  ExchangeStore.form.newTotalLabourStandard -
                    ExchangeStore.form.totalExtraDiscountStandard,
                  2
                )}`}
              />
              {/* {`ค่ากำเหน็จมาตรฐาน: ${ExchangeStore.form.newTotalLabourStandard} / ส่วนลด 20%: ${ExchangeStore.form.totalExtraDiscountStandard} / สุทธิ: ${ExchangeStore.form.newTotalLabour}`} */}
            </Grid>
            {/* /.Total Labour */}

            {/* Total Price */}
            <Grid
              item
              xs={12}
              md={4}
              marginTop={{ xs: 0, md: 1 }}
              sx={{ opacity: 0.6 }}
            >
              <NumberInput
                name="sellTotalPay"
                label="ราคารวม"
                placeholder="กรอกอัตโนมัติ"
                value={`${
                  (ExchangeStore.form.totalPay &&
                    CalculateSellPriceUtil(
                      ExchangeStore.form.newTotalGoldPrice,
                      ExchangeStore.form.newTotalLabour
                    )) ||
                  ""
                }`}
                handleOnChange={(value: string) => {}}
                isDisabled={true}
              />
            </Grid>
            {/* /.Total Price */}
          </>
        )}
      </Grid>
    </>
  );
});

export default NewNotEqualWeight;
