import { observable, makeAutoObservable, action } from "mobx";
import {
  Customer as CustomerModel,
  Create as CreateCustomerModel,
} from "../models/Customer";

export default class CustomerStore {
  private initForm: CustomerModel = {
    citizenId: "",
    firstName: "",
    lastName: "",
    address: "",
    phoneNumber: "",
  };
  @observable form: CustomerModel = { ...this.initForm };

  constructor() {
    makeAutoObservable(this);
  }

  @action
  onSetForm(form: CustomerModel) {
    this.form = { ...form };
  }

  @action
  onClearStore() {
    this.form = { ...this.initForm };
  }

  @action
  getBody() {
    const body: CreateCustomerModel = {
      ...(this.form.citizenId && { citizenId: this.form.citizenId }),
      ...(this.form.firstName && { firstName: this.form.firstName }),
      ...(this.form.lastName && { lastName: this.form.lastName }),
      ...(this.form.address && { address: this.form.address }),
      ...(this.form.citizenCardImageId && {
        citizenCardImageId: this.form.citizenCardImageId,
      }),
      ...(this.form.customerImageId && {
        customerImageId: this.form.customerImageId,
      }),
      ...(this.form.phoneNumber && { phoneNumber: this.form.phoneNumber }),
    };
    const isHasValue = Object.values(body).some((v) => Boolean(v));
    return isHasValue ? body : null;
  }
}
