const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const scrollToElementId = (id: string) => {
  try {
    const container: any = document.getElementById(id);
    container.scrollIntoView({
      block: "center",
      inline: "center",
      behavior: "smooth",
    });
  } catch (error) {}
};

export { scrollToTop as ScrollToTop, scrollToElementId as ScrollToElementId };
