import { createContext, useContext } from "react";
import MainStore from "./Main";
import UserStore from "./User";
import TradeStore from "./Trade";
import CustomerStore from "./Customer";
import PaymentStore from "./Payment";
import DepositStore from "./Deposit";
import ExchangeStore from "./Exchange";

const stores = {
  MainStore: new MainStore(),
  UserStore: new UserStore(),
  TradeStore: new TradeStore(),
  CustomerStore: new CustomerStore(),
  PaymentStore: new PaymentStore(),
  DepositStore: new DepositStore(),
  ExchangeStore: new ExchangeStore(),
};

const storeContext = createContext(stores);
export const useStores = () => useContext(storeContext);

export default stores;
