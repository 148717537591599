import React, { useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import {
  CameraAlt as CameraAltIcon,
  CancelRounded as CancelRoundedIcon,
} from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import {
  OnUpload as OnUploadStorageService,
  OnDelete as OnDeleteStorageService,
} from "../../services/Storage";
import { MaximumFileImage as MaximumFileImageStorageConstant } from "../../constants/Storage";
import InformationModal from "../modals/Information";

type Props = {
  label?: string;
  storageId?: string;
  isDisabled?: boolean;
  isSoftDeleteImage?: boolean;
  onFileChange: (storageId: string) => void;
  onDelete: () => void;
};

const Image = (props: Props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isInformationModalOpen, setIsInformationModalOpen] =
    useState<boolean>(false);
  const [informationMessage, setInformationMessage] = useState<string>("");

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: any = e.target.files;
    if (files[0]) {
      files[0].size <= MaximumFileImageStorageConstant
        ? onUploadImage(files[0])
        : onOpenInformationModal(
            "ไม่สามารถอัปโหลดรูปภาพได้ เนื่องจากขนาดไฟล์รูปที่อัปโหลดมีขนาดเกินขีดจำกัด กรุณาเลือกไฟล์ที่มีขนาดไม่เกิน 5MB"
          );
    }
  };
  const onUploadImage = async (file: File) => {
    const response = await OnUploadStorageService(file);
    if (!response.success) {
      onOpenInformationModal(`${response.message} (${response.code})`);
      return;
    }
    props.onFileChange(response.data);
  };
  const onDelete = async () => {
    if (props.storageId && !props.isSoftDeleteImage)
      await OnDeleteStorageService(props.storageId);
    props.onDelete();
  };
  const onOpenInformationModal = (message: string) => {
    setInformationMessage(message);
    setIsInformationModalOpen(true);
  };
  const onCloseInformationModal = () => {
    setIsInformationModalOpen(false);
    setInformationMessage("");
  };

  return (
    <>
      {props.label && (
        <Typography
          variant="body1"
          fontWeight={"bold"}
          color={StyleVariables["color-header"]}
          marginBottom={1}
        >
          {props.label}
        </Typography>
      )}
      {!props.storageId ? (
        <Button
          variant="outlined-upload"
          component="label"
          fullWidth
          disabled={props.isDisabled}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CameraAltIcon
              sx={{
                color: StyleVariables["color-textfield-icon"],
                fontSize: 24,
                marginBottom: 1,
              }}
            />
            <Typography variant="caption" fontWeight={"bold"}>
              อัปโหลด
            </Typography>
            <input
              type="file"
              hidden
              accept="image/*"
              capture
              onChange={onFileChange}
            />
          </Box>
        </Button>
      ) : (
        <Box position={"relative"}>
          <Box
            className="box-square"
            overflow={"hidden"}
            borderRadius={"10px"}
            maxHeight={300}
            height={"auto"}
          >
            <img
              src={`${apiUrl}/storages/${props.storageId}`}
              alt="preview-img"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          {!props.isDisabled ? (
            <Box
              position={"absolute"}
              top={-15}
              right={-15}
              bgcolor={StyleVariables["color-white"]}
              borderRadius={"100%"}
              width={32}
              height={32}
              minWidth={32}
              minHeight={32}
              sx={{ cursor: "pointer" }}
              onClick={onDelete}
            >
              <CancelRoundedIcon
                sx={{ fontSize: 32, color: StyleVariables["color-menu-icon"] }}
              />
            </Box>
          ) : null}
        </Box>
      )}
      <InformationModal
        isOpen={isInformationModalOpen}
        title="อัปโหลดรูปภาพไม่สำเร็จ"
        onClose={onCloseInformationModal}
      >
        <>
          <Box textAlign={"center"}>
            <Typography variant="body2" fontWeight={"regular"}>
              {informationMessage}
            </Typography>
          </Box>
          <Button variant="contained" fullWidth sx={{ marginTop: 5 }}>
            ตกลง
          </Button>
        </>
      </InformationModal>
    </>
  );
};

export default Image;
